import { Empty } from "antd";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import UnitItem from "./UnitItem";

const Units = () => {
    const { units } = useTypedSelector(state => state.laximo);
    return (
        <>
            {units?.length > 0 ? (
                <div className="units-wrapper">
                    {units.map((item) => (
                        <UnitItem item={item} key={item.ssd} />
                    ))}
                </div>
            ) : <Empty description={<span>Список агрегатов пуст</span>} />}
        </>
    );
}

export default Units;