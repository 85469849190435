import React, {FC, useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Form, Input, message, Row, Select} from "antd";
import {Address} from "../../api/address";
import {apiUrl} from "../../variables/appVariables";
import axios from "axios";
import * as _ from "lodash";
import PhoneInput from "react-phone-input-2";
import ru from 'react-phone-input-2/lang/ru.json';
import {getOptionTitle} from "../../utils/getOptionTitle";
import {Companies} from "../../api/companies";
import {fixAutocomplete} from "../../utils/fixAutocomlete";

const {Option} = Select;

export const renderSelectOptions = (options: any) => {
    return options.map((option: any) => {
        return (
            <Option
                key={option.id}
                data-id={option.id}
                data-value={JSON.stringify(option)}
                value={getOptionTitle(option)}
            >
                {getOptionTitle(option)}
            </Option>
        )
    })
}

interface IComponentProps {
    data?: any,
    handleCreateCompany?: (data: any) => void
}

const MainCompanyInfoForm: FC<IComponentProps> = ({data, handleCreateCompany}) => {
    const [form] = Form.useForm();

    //address
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [address, setAddress] = useState<any>(null);

    //phone number
    const [countryCode, setCountryCode] = useState('ru')
    const phoneNumberRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            fixAutocomplete();
        }, 500)

        Address.getCountries().then(res => {
            setCountries(res.results)
        })
    }, [])

    useEffect(() => {
        if (data) {
            form.setFieldsValue({...data});
            data?.address && setAddressFromParsedJSON(data.address);
        }else {
            form.resetFields();
        }
    }, [data])

    const setAddressFromParsedJSON = (json: any) => {
        const address = json.includes('{') ? JSON.parse(json) : json;

        if (address.country) {
            form.setFieldsValue({country: getOptionTitle(address.country)});
            address?.country?.id && handleChangeCountry(address.country.id, address.country);
            setAddress({...address, country: address.country});
        }

        if (address.region) {
            form.setFieldsValue({region: getOptionTitle(address.region)});
            address?.region?.id && handleChangeRegion(address.region.id, address.region);
            setAddress({...address, region: address.region});
        }

        if (address.city) {
            form.setFieldsValue({city: getOptionTitle(address.city)});
            address?.city && handleChangeCity(address.city);
            setAddress({...address, city: address.city});
        }

    }

    const handleChangeCountry = (countryId: any, country: any) => {
        Address.getRegions(countryId).then(res => {
            setRegions(res.results)
        })
        form.setFieldsValue({region: null, city: null})
        setAddress({country})
    }

    const handleChangeRegion = (regionId: any, region: any) => {
        Address.getCities(regionId).then(res => {
            setCities(res.results)
        })
        form.setFieldsValue({city: null})
        setAddress({...address, region, city: null})
    }

    const handleChangeCity = (city: any) => {
        setAddress({...address, city})
    }

    const handleChangeInnInput = async (tin: any) => {
        if (tin.value) {
            try {
                const url = `${apiUrl}/accounting_rest/INN/?tin=${tin.value}`
                const res = await axios.get(url, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`
                    }
                })
                const json = await res.data

                form.setFieldsValue({
                    'contact_person': json.contact_person,
                    'title': json.value,
                    'full_name': json.full_with_opf,
                    'PSRN': json.psrn
                })
            } catch (e) {
                console.log('Что-то пошло не так...', e)
            }
        }
    }

    const debouncedInnSearch = _.debounce(handleChangeInnInput, 500)

    const handleChangePhone = (value: any, data: any, event: any) => {
        if (event.type === 'click') {
            setCountryCode(data.countryCode)
            // @ts-ignore
            phoneNumberRef?.current?.numberInputRef?.focus()
        }
    }

    const submit = (values: any) => {
        if (data?.id) {
            Companies.editCompany(data.id, {...values, address: address ? JSON.stringify(address) : null})
                .then(res => message.success('Изменения успешно сохранены'))
                .catch(e => message.error(e));
        } else {
            Companies.addCompany({...values, address: address ? JSON.stringify(address) : null})
                .then(res => {
                    handleCreateCompany && handleCreateCompany(res)
                    message.success('Компания успешно добавлена')
                })
                .catch(e => message.error(e))
        }

    }

    return (
        <Form
            form={form}
            layout={'vertical'}
            onFinish={submit}
            className={'reset-margin-form'}
        >
            <Row gutter={5}>
                <Col lg={12} xs={24}>
                    <Card title={'Основная информация'}>
                        <Row gutter={5}>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name={'new_parts'}
                                    label={'Продажа запчастей'}
                                    initialValue={false}
                                >
                                    <Select
                                        placeholder="Выберите тип запчастей"
                                    >
                                        <Option value={true}>
                                            Новые
                                        </Option>
                                        <Option value={false}>
                                            Б\У
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="TIN"
                                    label="ИНН"
                                    rules={[{required: true, message: 'Обязательное поле!'}]}
                                >
                                    <Input
                                        onInput={(value) => debouncedInnSearch(value.currentTarget)}
                                        placeholder="ИНН компании"
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="PSRN"
                                    label="ОГРН"
                                    rules={[{required: true, message: 'Обязательное поле!'}]}
                                >
                                    <Input placeholder="Введите ОГРН"/>
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="title"
                                    label="Название магазина"
                                    rules={[{required: true, message: 'Обязательное поле!'}]}
                                >
                                    <Input placeholder="Введите название магазина"/>
                                </Form.Item>
                            </Col>
                            <Col lg={24} xs={24}>
                                <Form.Item
                                    name="full_name"
                                    label="Юридическое название компании"
                                    rules={[{required: true, message: 'Обязательное поле!'}]}
                                >
                                    <Input placeholder="Введите юридическое название компании"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                </Col>
                <Col lg={12} xs={24}>
                    <Card title={'Адрес'}>
                        <Row gutter={5}>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="country"
                                    label="Страна"
                                >
                                    <Select
                                        showSearch
                                        onChange={(_, data: any) => {
                                            const id = data['data-id'];
                                            const country = JSON.parse(data['data-value']);
                                            handleChangeCountry(id, country);
                                        }}
                                    >
                                        {renderSelectOptions(countries)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="region"
                                    label="Регион"
                                >
                                    <Select
                                        showSearch
                                        onChange={(_, data: any) => {
                                            const id = data['data-id'];
                                            const region = JSON.parse(data['data-value']);
                                            handleChangeRegion(id, region)
                                        }}
                                    >
                                        {renderSelectOptions(regions)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="city"
                                    label="Город"
                                >
                                    <Select
                                        showSearch
                                        onChange={(_, data: any) => {
                                            const city = JSON.parse(data['data-value']);
                                            handleChangeCity(city)
                                        }}
                                    >
                                        {renderSelectOptions(cities)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="street"
                                    label="Улица"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col lg={24} xs={24}>
                                <Form.Item
                                    name="house"
                                    label="Дом"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col lg={12} xs={24} style={{marginTop: 5}}>
                    <Card title={'Контактная информация'}>
                        <Row gutter={5}>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="contact_person"
                                    label="Контактное лицо"
                                    rules={[{required: true, message: 'Обязательное поле!'}]}
                                >
                                    <Input placeholder="Контактное лицо"/>
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="telephone_number"
                                    label="Контактный телефон"
                                    rules={[{required: true, message: 'Обязательное поле!'}]}
                                >
                                    <PhoneInput
                                        // @ts-ignore
                                        ref={phoneNumberRef}
                                        localization={ru}
                                        value={countryCode}
                                        country={'ru'}
                                        onlyCountries={['ru', 'by', 'az', 'ge', 'ua', 'kz', 'am', 'kg', 'md']}
                                        onChange={handleChangePhone}

                                    />
                                </Form.Item>

                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="e_mail"
                                    label="Почта"
                                    rules={[{required: true, message: 'Введите валидный email!', type: 'email'}]}
                                >
                                    <Input placeholder="Введите email" type={'email'}/>
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="site_company"
                                    label="Сайт компании"
                                >
                                    <Input placeholder={'Ссылка на сайт компании'}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row justify={'end'}>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        {data ? 'Сохранить изменения' : 'Создать компанию'}
                    </Button>
                </Form.Item>
            </Row>
        </Form>
    );
};

export default MainCompanyInfoForm;