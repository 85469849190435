import React, {FC, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, message, Row, Select, Table} from "antd";
import {useTypedSelector} from "../../hooks/useTypedSelector";
// import {apiUrl} from "../../variables/appVariables";
import {Companies} from "../../api/companies";

const {Option} = Select;

interface ComponentProps {
    company: any,
    updateCompanyInfo?: () => void,
}

const UploadingTab: FC<ComponentProps> = ({company, updateCompanyInfo}) => {
    const [form] = Form.useForm();
    const {options} = useTypedSelector(state => state.companies);
    const [fileList, setFileList] = useState([]);


    useEffect(() => {
        if (company) {
            form.setFieldsValue({
                uploading_type: company.uploading_type,
                short_eng_name: company.short_eng_name
            })
            // setTypeUploading(company.uploading_type);
            setFileList(company.price_files);
            // company.id && getCompanyFiles(company.id)
        } else {
            form.resetFields();
        }
    }, [company])

    const submit = (values: any) => {
        company.id && Companies.editCompany(company.id, values)
            .then(res => {
                updateCompanyInfo && updateCompanyInfo();
                message.success('Изменения успешно сохранены');
            })
            .catch(e => message.error(e));
    }

    const columns: any = [
        {
            title: 'Файл',
            key: 'file',
            width: 200,
            ellipsis: {showTitle: false},
            render: (data: any) => {
                const date = new Date(data.date);
                const options: any = {
                    year: 'numeric', month: 'numeric', day: 'numeric',
                    hour: 'numeric', minute: 'numeric', second: 'numeric',
                    hour12: false
                };
                const dateFormat = new Intl.DateTimeFormat('ru-RU', options).format(date);
                const title = `Файл выгрузки от ${dateFormat}`
                return (
                    <a href={data.upload_file} target={'_blank'}>{title}</a>
                )
            }
        },
    ]

    return (
        <>
            <Row gutter={5}>
                <Col lg={24} xs={24}>
                    <Card title={'Настройка выгрузки для компании'}>
                        <Form
                            form={form}
                            layout={'vertical'}
                            onFinish={submit}
                        >
                            <Row gutter={5}>
                                <Col lg={12} xs={24}>
                                    <Form.Item
                                        label={'Тип выгрузки'}
                                        name={'uploading_type'}
                                        initialValue={'base'}
                                    >
                                        <Select placeholder={'Выберите тип выгрузки'}>
                                            {
                                                options.map(item => {
                                                    return (
                                                        <Option
                                                            key={item.value}
                                                            value={item.value}
                                                        >
                                                            {item.display_name}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={12} xs={24}>
                                    <Form.Item label={'Рабочее название'} name={'short_eng_name'}>
                                        <Input placeholder={'Введите рабочее название'}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify={'end'}>
                                {/*<Form.Item style={{marginRight: 20}}>*/}
                                {/*    <Button disabled={disabledCreateFileBtn} type={'primary'}*/}
                                {/*            onClick={() => company?.id && createFile(company.id)}>*/}
                                {/*        Создать файл выгрузки*/}
                                {/*    </Button>*/}
                                {/*</Form.Item>*/}
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={!company?.id}
                                    >
                                        Сохранить изменения
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Card>
                </Col>
                <Col lg={24} xs={24}>
                    <Table
                        columns={columns}
                        dataSource={fileList}
                        pagination={{showSizeChanger: false,}}
                    />
                </Col>
            </Row>
        </>
    );
};

export default UploadingTab;