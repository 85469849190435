import React, { FC, useState } from 'react';
import { Button, Dropdown, Menu, message, Popconfirm, Space, Table } from "antd";
import { getTableDimensions } from "../../utils/getTableWidth";
import StockModal from "./StockModal";
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from "@ant-design/icons/lib";
import { Companies } from "../../api/companies";

interface IComponentProps {
    stocks: any[],
    company: any,
    handleChangeStocks: (stocks: any) => void
}

const StocksTable: FC<IComponentProps> = ({ stocks, company, handleChangeStocks }) => {
    const [selectedStock, setSelectedStock] = useState<any>(null);
    const [visibleAddModal, setVisibleAddModal] = useState(false);
    const [visibleEditModal, setVisibleEditModal] = useState(false);

    const menu = (stock: any) => {
        return (
            <Menu style={{ width: 'auto' }}>
                <Menu.Item
                    key={'edit'}
                    icon={<EditOutlined />}
                    onClick={() => {
                        setSelectedStock(stock)
                        setVisibleEditModal(true)
                    }}
                >
                    Редактировать
                </Menu.Item>
                <Menu.Item
                    key={'delete'}
                    icon={<DeleteOutlined />}
                >
                    <Popconfirm
                        title="Вы действительно хотите удалить склад?"
                        onConfirm={() => {
                            Companies.deleteStock(stock.id)
                                .then(res => {
                                    const newStocks = stocks.filter(item => item.id !== stock.id);
                                    handleChangeStocks(newStocks);
                                    message.success('Склад успешно удален!');
                                })
                                .catch(e => message.error(e))
                        }}
                        okText="Да"
                        cancelText="Нет"
                    >
                        Удалить
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );
    };

    const columns: any = [
        {
            title: "",
            key: "action",
            width: 50,
            fixed: "left",
            render: (stock: any) => (
                <Space size="middle">
                    <Dropdown overlay={menu(stock)} trigger={["click"]}>
                        <EllipsisOutlined style={{ cursor: "pointer", fontSize: "24px" }} />
                    </Dropdown>
                </Space>
            ),
        },
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            width: 50,
        },
        {
            title: 'Название',
            key: 'title',
            dataIndex: 'title',
            width: 200,
        },
        // {
        //     title: 'Менеджер',
        //     key: 'manager',
        //     dataIndex: 'manager',
        //     width: 200,
        // },
        {
            title: 'Страна',
            key: 'country',
            dataIndex: 'country',
            width: 200,
        },
        {
            title: 'Регион',
            key: 'region',
            dataIndex: 'region',
            width: 200,
        },
        {
            title: 'Город',
            key: 'city',
            dataIndex: 'city',
            width: 200,
        },
        {
            title: 'Улица',
            key: 'street',
            dataIndex: 'street',
            width: 200,
        },
        {
            title: 'Дом',
            key: 'house',
            dataIndex: 'house',
            width: 200,
        },
    ]

    const handleVisibleAddModal = () => {
        setSelectedStock(null);
        setVisibleAddModal(true);
    }

    const handleCancel = () => {
        setVisibleAddModal(false);
        setVisibleEditModal(false);
        setSelectedStock(null);

    }

    return (
        <>
            <Button
                type="primary"
                onClick={handleVisibleAddModal}
                style={{ marginBottom: 5 }}
            >
                Добавить склад
            </Button>

            <Table
                onRow={(record, rowIndex) => {
                    return {
                        onDoubleClick: (event) => {
                            setSelectedStock(record)
                            setVisibleEditModal(true)
                        }
                    }
                }}
                scroll={{ x: getTableDimensions(columns), y: 500 }}
                columns={columns}
                dataSource={stocks}
                // @ts-ignore
                pagination={{ position: ['none'], pageSize: 100 }}
            />

            <StockModal
                visible={visibleAddModal || visibleEditModal}
                handleCancel={handleCancel}
                company={company}
                stock={selectedStock}
                stocks={stocks}
                handleChangeStocks={handleChangeStocks}
            />
        </>
    );
};

export default StocksTable;