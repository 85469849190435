import React, {Suspense, lazy} from 'react';
import {Card, Layout, Row, Spin} from "antd";
// import LoginForm from "../components/LoginForm";

const LoginForm = lazy(() => import("../components/LoginForm"))

const Login = () => {
    return (
        <Layout>
            <Row justify={'center'} align={'middle'} className={'h100'}>
                <Suspense fallback={<Spin/>}>
                    <Card>
                        <LoginForm/>
                    </Card>
                </Suspense>
            </Row>
        </Layout>
    );
};

export default Login;