import React, {FC, useEffect, useState} from 'react';
import {Divider, Pagination, Select, Space} from "antd";

const {Option} = Select;

interface IComponentProps {
    array: any[],
    total: number,
    handleChange: (value: any, data: any) => void,
    handleChangePage: (page: number) => void,
    onSearch?: (page: number, value: string) => void,
    defaultValue?: any,
    pageSize?: number,
    style?: any,
    pageC?: number,
    loading?: boolean,
    disabled?: boolean,
}

const SelectWithPagination: FC<IComponentProps> = ({
                                                       onSearch,
                                                       pageC,
                                                       style,
                                                       loading,
                                                       array,
                                                       total,
                                                       handleChange,
                                                       handleChangePage,
                                                       defaultValue,
                                                       pageSize,
                                                       disabled
                                                   }) => {
    const [page, setPage] = useState(1);
    const [value, setValue] = useState(null);
    const [searchedValue, setSearchedValue] = useState('');

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue])

    return (
        <Select
            showSearch
            disabled={false || disabled}
            loading={loading || false}
            value={value}
            style={{...style}}
            defaultValue={defaultValue}
            placeholder={'Выберите из списка'}
            optionFilterProp="children"
            onChange={(value, data) => {
                setValue(value);
                handleChange(value, data)
            }}
            onSearch={value => {
                onSearch && onSearch(1, value);
                setSearchedValue(value);
            }}
            dropdownRender={menu => (
                <>
                    {menu}
                    <Divider style={{margin: '8px 0'}}/>
                    <Space align="center" style={{padding: '0 8px 4px'}}>
                        <Pagination
                            simple
                            current={pageC || page}
                            total={total}
                            pageSize={pageSize || 150}
                            onChange={(page) => {
                                handleChangePage(page)
                                setPage(page)
                            }}
                        />
                    </Space>
                </>
            )}
        >
            {array?.map(item => {
                return (
                    <Option
                        key={item.id}
                        value={item.id}
                        data-value={JSON.stringify(item)}
                    >
                        {item.title}
                    </Option>
                )
            })}
        </Select>
    );
};

export default SelectWithPagination;
