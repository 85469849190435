import {IProfileInfo} from "../../../interfaces/user";

export interface IAuthState {
    isAuth: boolean,
    user: IProfileInfo,
    is_loading: boolean,
    error: string,
    token: string,
    loadingForgotPassword: boolean,
    okForgotMessage: string,
}

export enum AuthActionEnum {
    SET_AUTH = 'SET_AUTH',
    SET_USER = 'SET_USER',
    SET_IS_LOADING = 'SET_IS_LOADING',
    SET_ERROR = 'SET_ERROR',
    SET_TOKEN = 'SET_TOKEN',
    SET_LOADING_FORGOT = 'SET_LOADING_FORGOT',
    SET_OK_FORGOT_MESSAGE = "SET_OK_FORGOT_MESSAGE",
}

export interface IAuthAction {
    type: AuthActionEnum.SET_AUTH,
    payload: boolean
}

export interface IUserAction {
    type: AuthActionEnum.SET_USER,
    payload: IProfileInfo
}

export interface IErrorAction {
    type: AuthActionEnum.SET_ERROR,
    payload: string
}

export interface IIsLoadingAction {
    type: AuthActionEnum.SET_IS_LOADING,
    payload: boolean
}

export interface ISetTokenAction {
    type: AuthActionEnum.SET_TOKEN,
    payload: string
}

export interface ISetLoadingForgotAction {
    type: AuthActionEnum.SET_LOADING_FORGOT,
    payload: boolean
}

export interface ISetOkForgotMessageAction {
    type: AuthActionEnum.SET_OK_FORGOT_MESSAGE,
    payload: string
}

export type AuthAction =
    IAuthAction |
    IUserAction |
    IErrorAction |
    IIsLoadingAction |
    ISetTokenAction |
    ISetLoadingForgotAction |
    ISetOkForgotMessageAction