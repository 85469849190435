import { Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import Details from "./Details";

const resizeIndex = 1.3

const DetailsPage = ({handleCloseModal}) => {
    const canvas = useRef<HTMLCanvasElement | null>(null);
    const { selectedUnitInfo, selectedDetail } = useTypedSelector(state => state.laximo);
    const [imageDimensions, setImageDimensions] = useState({ width: 500, height: 500 })


    useEffect(() => {
        if (selectedUnitInfo?.image_url) {
            const img = new Image();
            img.src = selectedUnitInfo.image_url;
            img.onload = function () {
                setImageDimensions({
                    width: Math.floor(img.width / resizeIndex),
                    height: Math.floor(img.height / resizeIndex),
                })
            }
        }
    }, [selectedUnitInfo]);

    useEffect(() => {
        if (!selectedDetail) {
            handleClearCanvas();
        }
    }, [selectedDetail])

    // useEffect(() => {
    //     if (canvas.current) {
    //         drawSquare();
    //     }
    // }, [canvas])

    // const drawSquare = () => {
    //     if (canvas.current) {
    //         const ctx = canvas.current.getContext("2d");
    //         ctx.beginPath(); // Start a new path
    //         ctx.moveTo(61, 119); // Move the pen to (30, 50)
    //         ctx.lineTo(111, 169); // Draw a line to (150, 100)
    //         ctx.stroke(); // Render the path
    //     }
    // }

    const handleDrawSquare = (item) => {
        if (canvas.current && item?.image_map) {
            const { x1, x2, y1, y2 } = item.image_map;
            const ctx = canvas.current.getContext("2d");
            const newCoords = {
                x1: Math.floor(x1 / resizeIndex),
                y1: Math.floor(y1 / resizeIndex),
                x2: Math.floor(x2 / resizeIndex),
                y2: Math.floor(y2 / resizeIndex),
            }
            let width = newCoords.x2 - newCoords.x1;
            let height = newCoords.y2 - newCoords.y1;
            ctx.beginPath();
            ctx.strokeStyle = 'red';
            ctx.strokeRect(newCoords.x1, newCoords.y1, width, height);
        }
    }

    const handleClearCanvas = () => {
        if (canvas.current) {
            const ctx = canvas.current.getContext("2d");
            ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
        }
    }

    return (
        <Row>
            <Col lg={15} md={15} xs={24}>
                <canvas
                    ref={canvas}
                    width={imageDimensions.width}
                    height={imageDimensions.height}
                    style={{
                        backgroundImage: `url(${selectedUnitInfo?.image_url})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: `contain`
                    }}>
                    Your browser does not support the canvas element.
                </canvas>
            </Col>
            <Col lg={9} md={9} xs={24}>
                <Details onHover={handleDrawSquare} onMouseLeave={handleClearCanvas} handleCloseModal={handleCloseModal} />
            </Col>
        </Row>
    );
}

export default DetailsPage;