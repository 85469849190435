import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, Layout, message, Row, Typography} from "antd";
import {apiUrl} from "../variables/appVariables";
import axios from "axios";

const {Paragraph} = Typography;

const TelegramBot = () => {
    const [telegramToken, setTelegramToken] = useState('')

    useEffect(() => {
        const getTelegramToken = async () => {
            try {
                const url = `${apiUrl}/bot/user_token/`
                const res = await axios.get(url, {
                    method: "GET",
                    headers: {'Authorization': 'Token ' + localStorage.getItem('token')}
                })
                const json = await res.data
                setTelegramToken(json.token)
            } catch (e) {
                message.error('Ошибка получения токена')
                setTelegramToken('Ошибка')
                console.log('Что-то пошло не так...')
            }
        }

        getTelegramToken()
    }, [])


    return (
        <Layout>
            <Row>
                <Col lg={24} xs={24}>
                    <Divider>Краткая инструкция</Divider>
                    <p>
                        Скопируйте токен сопряжения с телеграм ботом, перейдите по
                        <a href="tg://resolve?domain=partobo_support_bot"> ссылке </a>
                        и вставьте его в переписку с роботом.
                    </p>
                </Col>
                <Col lg={24} xs={24}>
                    <Card title="Токен сопряжения">
                        <Paragraph
                            copyable={{tooltips: ['Скопировать', 'Успешно скопировано!!']}}>{telegramToken}</Paragraph>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default TelegramBot;