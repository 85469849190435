
export interface IDetailsState {
    details: any[],
    loading: boolean,
    total: number,
    page: number,
    detailsCatalog: any[],
    loadingCatalog: boolean,
    totalCatalog: number,
    pageCatalog: number,
}

export enum DetailsActionTypeEnum {
    SET_DETAILS = "SET_DETAILS",
    SET_LOADING = "SET_LOADING_DETAILS",
    SET_TOTAL = "SET_TOTAL_DETAILS",
    SET_PAGE = "SET_PAGE_DETAILS",
    SET_DETAILS_CATALOG = "SET_DETAILS_CATALOG",
    SET_LOADING_CATALOG = "SET_LOADING_CATALOG",
    SET_TOTAL_CATALOG = "SET_TOTAL_CATALOG",
    SET_PAGE_CATALOG = "SET_PAGE_CATALOG",
}

export interface ISetDetailsAction {
    type: DetailsActionTypeEnum.SET_DETAILS,
    payload: any[]
}

export interface ISetDetailsLoadingAction {
    type: DetailsActionTypeEnum.SET_LOADING,
    payload: boolean
}

export interface ISetDetailsTotalAction {
    type: DetailsActionTypeEnum.SET_TOTAL,
    payload: number
}

export interface ISetDetailsPageAction {
    type: DetailsActionTypeEnum.SET_PAGE,
    payload: number
}

export interface ISetDetailsCatalogAction {
    type: DetailsActionTypeEnum.SET_DETAILS_CATALOG,
    payload: any[]
}

export interface ISetDetailsLoadingCatalogAction {
    type: DetailsActionTypeEnum.SET_LOADING_CATALOG,
    payload: boolean
}

export interface ISetDetailsTotalCatalogAction {
    type: DetailsActionTypeEnum.SET_TOTAL_CATALOG,
    payload: number
}

export interface ISetDetailsPageCatalogAction {
    type: DetailsActionTypeEnum.SET_PAGE_CATALOG,
    payload: number
}

export type DetailsActionType =
    ISetDetailsAction |
    ISetDetailsLoadingAction |
    ISetDetailsPageAction |
    ISetDetailsTotalAction |
    ISetDetailsCatalogAction |
    ISetDetailsLoadingCatalogAction |
    ISetDetailsPageCatalogAction |
    ISetDetailsTotalCatalogAction