import React, { FC } from "react"
import { Companies } from "../api/companies"
import UploadingTab from "./Company/UploadingTab"

interface ComponentProps {
    company: any,
    updateCompanyInfo?: (data: any) => void,
}

const Unloading: FC<ComponentProps> = ({company, updateCompanyInfo}) => {
    return (
        <UploadingTab
            company={company}
            updateCompanyInfo={() => {
                Companies.getCompanyById(company?.id).then(res => {
                    updateCompanyInfo(res)
                })
            }}
        />
    )
}

export default Unloading;