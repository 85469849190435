import {InvoicesActionType, InvoicesActionTypeEnum, IInvoicesState} from "./types";

const initialState: IInvoicesState = {
    invoices: [],
    loading: false,
    page: 1,
    total: 0
}

export default function invoicesReducer(state = initialState, action: InvoicesActionType) {
    switch (action.type) {
        case InvoicesActionTypeEnum.SET_INVOICES:
            return {...state, invoices: action.payload, loading: false};
        case InvoicesActionTypeEnum.SET_LOADING:
            return {...state, loading: action.payload};
        case InvoicesActionTypeEnum.SET_PAGE:
            return {...state, page: action.payload};
        case InvoicesActionTypeEnum.SET_TOTAL:
            return {...state, total: action.payload};
        default:
            return state
    }
}