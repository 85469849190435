import React, {FC, useEffect, useState} from 'react';
import {Layout, message} from "antd";
import NoAuthLayout from "./components/NoAuthLayout";
import AuthLayout from "./components/AuthLayout";
import {useTypedSelector} from "./hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {AuthActionCreators} from "./store/reducers/auth/action-creators";
import {getProfileInfo} from "./api/user";
import AppLoading from "./components/AppLoading";

const App: FC = () => {
    const dispatch = useDispatch()
    const {isAuth} = useTypedSelector(state => state.auth)
    const [loadingApp, setLoadingApp] = useState(true)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getProfileInfo().then(res => {
                dispatch(AuthActionCreators.setUser(res))
                dispatch(AuthActionCreators.setAuth(true))
                setLoadingApp(false)
            }).catch(e => {
                if (e.detail) {
                    dispatch(AuthActionCreators.logout())
                }
                message.error('Ошибка соединения с сервером!')
                setLoadingApp(false)
            })
        } else {
            dispatch(AuthActionCreators.logout())
            setLoadingApp(false)
        }
    }, [])

    return (
        <Layout>
            {loadingApp ? <AppLoading/> : isAuth ? <AuthLayout/> : <NoAuthLayout/>}
        </Layout>
    );
}

export default App;
