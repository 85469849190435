import { Button, Col, Form, message, Row, Select, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Laximo } from "../../../api/laximo";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { LaximoActionCreators } from "../../../store/reducers/laximo/action-creators";

const { Option } = Select;

const CarsParams = ({ params, handleClose }) => {
    const dispatch = useDispatch();
    const { selectedCarsCatalog, loadingAutoParams } = useTypedSelector(state => state.laximo);
    const [lastSsd, setLastSsd] = useState('');
    const [form] = Form.useForm();

    const disabledNextBtn = useMemo(() => {
        if (params?.length > 0) {
            const disabled = params.filter(item => item.determined || item.allowlistvehicles)
            console.log(disabled);
            if (disabled?.length > 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }, [params]);

    useEffect(() => {
        if(params.length > 0) {
            params.map(item => form.setFieldsValue({[item.name]: item?.value}))
        }
    }, [params]);

    const handleBack = (e) => {
        e.preventDefault();
        dispatch(LaximoActionCreators.setSelectedCarsCatalog(null));
    }

    const handleChangeSelect = (value, data) => {
        if (value) {
            const ssd = data['data-value'];
            dispatch(LaximoActionCreators.setLoadingAutoParams(true));
            Laximo.getAutoParams({ code: selectedCarsCatalog.code, ssd })
                .then(res => {
                    dispatch(LaximoActionCreators.setAutoParams(res));
                    setLastSsd(ssd);
                })
                .catch(e => message.error(e))
                .finally(() => dispatch(LaximoActionCreators.setLoadingAutoParams(false)));
        }
    }

    const handleClearSelect = (param) => {
        if (param?.ssd) {
            dispatch(LaximoActionCreators.setLoadingAutoParams(true));
            Laximo.getAutoParams({ code: selectedCarsCatalog.code, ssd: param.ssd })
                .then(res => {
                    dispatch(LaximoActionCreators.setAutoParams(res));
                    setLastSsd(param.ssd);
                })
                .catch(e => message.error(e))
                .finally(() => dispatch(LaximoActionCreators.setLoadingAutoParams(false)));
        } else {
            dispatch(LaximoActionCreators.setLoadingAutoParams(true));
            Laximo.getAutoParams({ code: selectedCarsCatalog.code, ssd: '' })
                .then(res => {
                    dispatch(LaximoActionCreators.setAutoParams(res));
                    setLastSsd(param.ssd);
                })
                .catch(e => message.error(e))
                .finally(() => dispatch(LaximoActionCreators.setLoadingAutoParams(false)));
        }
    }

    const handleNext = () => {
        if (lastSsd && selectedCarsCatalog) {
            Laximo.getAuto({catalog: selectedCarsCatalog.code, ssd: lastSsd})
                .then(res => {
                    dispatch(LaximoActionCreators.setFoundedCars(res));
                    handleClose();
                })
        } else {
            message.error(`Отсутствует код каталога или ключ(SSD)`);
        }
    }

    return (
        <>
            {selectedCarsCatalog && (
                <Tag style={{ marginBottom: 10 }} closable onClose={handleBack}>
                    {selectedCarsCatalog.brand}
                </Tag>
            )}
            <Form form={form} layout="vertical">
                <Row gutter={15}>
                    {params.map(item => {
                        return (
                            <Col lg={12} md={12} xs={24}>
                                <Form.Item label={item.name} name={item.name}>
                                    <Select
                                        onChange={handleChangeSelect}
                                        placeholder="Выберите из списка"
                                        showSearch
                                        allowClear
                                        onClear={() => handleClearSelect(item)}
                                        loading={loadingAutoParams}
                                        filterOption={(input, option) => (String(option?.value).toLowerCase() ?? '').includes(input.toLowerCase())}
                                    >
                                        {item?.options?.length > 0 && (
                                            <>
                                                {
                                                    item.options.map(option => {
                                                        return (
                                                            <Option
                                                                key={option.key}
                                                                value={option.value}
                                                                data-value={option.key}
                                                            >
                                                                {option.value}
                                                            </Option>
                                                        )
                                                    })
                                                }
                                            </>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )
                    })}
                </Row>
                <Row justify="end">
                    <Button type="primary" disabled={disabledNextBtn} onClick={handleNext}>Далее</Button>
                </Row>
            </Form>
        </>
    );
}

export default CarsParams;