import { Empty } from "antd";
import TreeNode from "./TreeNode";

const Tree = ({ treeData }) => {
    return (
        <>
            {treeData?.length > 0 ? (
                <ul>
                    {treeData.map((node) => (
                        <TreeNode item={node} key={node.category_id} />
                    ))}
                </ul>
            ) : <Empty description={<span>Категорий не найдено</span>} />}
        </>
    );
}

export default Tree;