import {TabActionEnum, TabActionState, TabActionType} from "./types";

const initialState: TabActionState = {
    tabs: [],
    activeKey: '',
}

export default function tabReducer(state = initialState, action: TabActionType) {
    switch (action.type) {
        case TabActionEnum.SET_ACTIVE_KEY:
            return {...state, activeKey: action.payload}
        case TabActionEnum.SET_TABS:
            return {...state, tabs: action.payload}
        default:
            return state
    }
}