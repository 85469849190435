import { SearchOutlined } from "@ant-design/icons";
import { message, Table, Tag, Tooltip } from "antd"
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Detail } from "../../api/detail";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { currDateLocalString } from "../../pages/Disputes";
import { DetailsActionCreators } from "../../store/reducers/details/action-creators";
import { getFiltersTable } from "../../utils/getFiltersTabe";
import { getTableDimensions } from "../../utils/getTableWidth";
import { apiUrl } from "../../variables/appVariables";
import { getRealizationType, getSellsType } from "../DetailsTable";
import DetailBrandFilter from "../TableFilters/DetailBrandFilter";
import DetailFilterInput from "../TableFilters/DetailFilterInput";
import DetailFilterSelect from "../TableFilters/DetailFilterSelect";
import DetailModelFilter from "../TableFilters/DetailModelFilter";
import PriceFilter from "../TableFilters/PriceFilter";
import SimpleTextFilter from "../TableFilters/SimpleTextFilter";

const CatalogDetailsTable = ({ handleUpdateDocCatalog, document }) => {
    const dispatch = useDispatch();
    const { loadingCatalog: loading, detailsCatalog: data, totalCatalog: total } = useTypedSelector(state => state.details);


    // columns filters

    const [details, setDetails] = useState([]);
    const [totalDetails, setTotalDetails] = useState(0);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [currentFilters, setCurrentFilters] = useState<any>({ page: 1 });
    const [isGte, setIsGte] = useState(true);

    useEffect(() => {
        Detail.getDetailsCategories()
            .then((res) => res?.results && setCategories(res.results))
            .catch((e) => {
                message.error(`${e}, не удалось получить список категорий запчастей`);
            });
        Detail.getBrandsModelsList()
            .then((res) => {
                setBrands(res);
            })
            .catch((e) => {
                message.error(`${e}, не удалось получить список категорий запчастей`);
            });

        dispatch(DetailsActionCreators.getDetailsCatalog(currentFilters));
    }, []);

    const addDetailInDoc = async (item) => {
        try {
            const url = `${apiUrl}/sp/item_in_document/`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ item, document: document.id }),
            })
            if (response.ok) {
                handleUpdateDocCatalog?.();
                message.success('Деталь успешно добавлена в документ');
            } else {
                const json = await response.json();
                throw new Error(json.item.join(', '));
            }
        } catch (e) {
            message.error(e.message);
        }
    }

    const handleChangeCategory = (id: number) => {
        setDetails([]);
        setTotalDetails(0);
        Detail.getDetailByCategory(id).then((res) => {
            res.results && setDetails(res.results);
            res.count && setTotalDetails(res.count);
        });
    };

    const handleChangeBrand = (value: string, data: any) => {
        const dataValue = JSON.parse(data["data-value"]);
        setModels([]);
        setModels(dataValue.models);
    };

    const columns = [
        {
            title: "Статус",
            dataIndex: "sells",
            key: "sells",
            width: 100,
            render: (tags: boolean) => (
                <>
                    {tags ? (
                        <Tag color={"volcano"}>Продано</Tag>
                    ) : (
                        <Tag color={"green"}>В наличии</Tag>
                    )}
                </>
            ),
        },
        {
            title: "Артикул",
            dataIndex: "shop_code_number",
            key: "shop_code_number",
            width: 150,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                visible,
            }) => {
                return (
                    <DetailFilterInput
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        visible={visible}
                    />
                );
            },
            filteredValue: currentFilters?.shop_code_number
                ? [currentFilters.shop_code_number]
                : null,
            filtered: currentFilters?.shop_code_number ? true : false,
            filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
            ),
        },
        {
            title: "OEM",
            dataIndex: "oem",
            key: "oem",
            ellipsis: {
                showTitle: false,
            },
            width: 150,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                visible,
            }) => {
                return (
                    <DetailFilterInput
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        visible={visible}
                    />
                );
            },
            filteredValue: currentFilters?.oem ? [currentFilters.oem] : null,
            filtered: currentFilters?.oem ? true : false,
            filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
            ),
            render: (text: any) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: "Категория",
            dataIndex: "part",
            key: "category",
            ellipsis: {
                showTitle: false,
            },
            width: 150,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                visible,
            }) => {
                return (
                    <DetailFilterSelect
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        data={categories}
                        reset={() => {
                            setDetails([]);
                            setTotalDetails(0);
                        }}
                        handleChange={handleChangeCategory}
                    />
                );
            },
            filteredValue: currentFilters?.category
                ? [currentFilters.category]
                : null,
            filtered: currentFilters?.category ? true : false,
            render: (part: any) =>
                part?.category ? (
                    <Tooltip placement="topLeft" title={part.category}>
                        {part.category}
                    </Tooltip>
                ) : null,
        },
        {
            title: "Наименование",
            dataIndex: "part",
            key: "title",
            ellipsis: {
                showTitle: false,
            },
            width: 200,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                visible,
            }) => {
                return (
                    <DetailFilterSelect
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        pagination={true}
                        data={details}
                        total={totalDetails}
                        handleChange={() => console.log("123")}
                    // handleChangePage={handleChangePageDetails}
                    />
                );
            },
            filteredValue: currentFilters?.title ? [currentFilters.title] : null,
            filtered: currentFilters?.title ? true : false,
            render: (part: any) =>
                part?.title ? (
                    <Tooltip placement="topLeft" title={part.title}>
                        {part.title}
                    </Tooltip>
                ) : null,
        },
        {
            title: "Марка",
            dataIndex: "brand",
            key: "brand",
            ellipsis: {
                showTitle: false,
            },
            width: 150,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                visible,
            }) => {
                return (
                    <DetailBrandFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        data={brands}
                        reset={() => {
                            setModels([]);
                        }}
                        handleChange={handleChangeBrand}
                    />
                );
                // <DetailFilterSelect
                //     clearFilters={clearFilters}
                //     confirm={confirm}
                //     selectedKeys={selectedKeys}
                //     setSelectedKeys={setSelectedKeys}
                //     pagination={true}
                //     data={brands}
                //     total={totalBrands}
                //     reset={() => {
                //         setModels([]);
                //     }
                //     }
                //     handleChange={handleChangeBrand}
                // // handleChangePage={handleChangePageBrands}
                // />
            },
            filteredValue: currentFilters?.brand ? [currentFilters.brand] : null,
            filtered: currentFilters?.brand ? true : false,
            render: (text: any) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: "Модель",
            dataIndex: "model",
            key: "model",
            ellipsis: {
                showTitle: false,
            },
            width: 200,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                visible,
            }) => {
                return (
                    <DetailModelFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        data={models}
                        handleChange={() => console.log("123")}
                        reset={() => console.log("reset")}
                    />
                );
            },
            filteredValue: currentFilters?.model ? [currentFilters.model] : null,
            filtered: currentFilters?.model ? true : false,
            render: (text: any) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "price",
            width: 150,
            filterDropdown: ({
                setSelectedKeys,
                // selectedKeys,
                confirm,
                clearFilters,
                // visible
            }) => {
                return (
                    <PriceFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        // selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={(sort) => setIsGte(sort)}
                        reset={() => console.log("reset")}
                    />
                );
            },
            filteredValue: currentFilters?.price ? [currentFilters.price] : null,
            filtered: currentFilters?.price ? true : false,
        },
        {
            title: "Цена закуп",
            dataIndex: "price_in",
            key: "price_in",
            width: 150,
            filterDropdown: ({
                setSelectedKeys,
                // selectedKeys,
                confirm,
                clearFilters,
                // visible
            }) => {
                return (
                    <PriceFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        // selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={(sort) => setIsGte(sort)}
                        reset={() => console.log("reset")}
                    />
                );
            },
            filteredValue: currentFilters?.price_in
                ? [currentFilters.price_in]
                : null,
            filtered: currentFilters?.price_in ? true : false,
        },
        {
            title: "Цена продажи",
            dataIndex: "price_out",
            key: "price_out",
            width: 150,
            filterDropdown: ({
                setSelectedKeys,
                // selectedKeys,
                confirm,
                clearFilters,
                // visible
            }) => {
                return (
                    <PriceFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        // selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={(sort) => setIsGte(sort)}
                        reset={() => console.log("reset")}
                    />
                );
            },
            filteredValue: currentFilters?.price_out
                ? [currentFilters.price_out]
                : null,
            filtered: currentFilters?.price_out ? true : false,
        },
        {
            title: "Реализация",
            dataIndex: "realization_type",
            key: "realization_type",
            width: 200,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <SimpleTextFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={() => console.log("123")}
                        reset={() => console.log("reset")}
                        data={[
                            { value: "sell", title: "Продажа" },
                            { value: "cancellation", title: "Списание" },
                            { value: "disposal", title: "Утиль" },
                        ]}
                    />
                );
            },
            filteredValue: currentFilters?.realization_type
                ? [currentFilters.realization_type]
                : null,
            filtered: currentFilters?.realization_type ? true : false,
            render: (realization_type) => getRealizationType(realization_type),
        },
        {
            title: "Продажа",
            dataIndex: "sells_type",
            key: "sells_type",
            width: 200,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <SimpleTextFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={() => console.log("123")}
                        reset={() => console.log("reset")}
                        data={[
                            { value: "trade-12", title: "ТОРГ-12" },
                            { value: "upd", title: "УПД" },
                            { value: "pr_check", title: "товарный чек" },
                        ]}
                    />
                );
            },
            filteredValue: currentFilters?.sells_type
                ? [currentFilters.sells_type]
                : null,
            filtered: currentFilters?.sells_type ? true : false,
            render: (sells_type) => getSellsType(sells_type),
        },
        {
            title: "Изменение реализации",
            dataIndex: "date_change_rt",
            key: "date_change_rt",
            width: 200,
            render: (date: string) => currDateLocalString(date),
        },
    ];

    const handleTableChange = async (
        pagination: any,
        filtersTable: any,
    ) => {
        let customFilters = { ...filtersTable };
        if (customFilters?.price) {
            customFilters = {
                ...customFilters,
                [isGte ? "price__gte" : "price__lte"]: customFilters.price,
            };
            delete customFilters.price;
        }
        let filters = getFiltersTable(pagination, customFilters);

        setCurrentFilters(filters);
        dispatch(DetailsActionCreators.getDetailsCatalog(filters));
    };

    return (
        <Table
            onChange={handleTableChange}
            onRow={(record, rowIndex) => {
                return {
                    onDoubleClick: (event) => {
                        addDetailInDoc(record.id);
                    },
                };
            }}
            scroll={{ x: getTableDimensions(columns), y: 500 }}
            loading={loading}
            dataSource={data}
            columns={columns}
            pagination={{
                total,
                pageSize: 5,
                showSizeChanger: false,
            }}
        />
    )
}

export default CatalogDetailsTable;