import React, {lazy, Suspense} from 'react';
import {Card, Layout, Row, Spin} from "antd";
// import RegisterForm from "../components/RegisterForm";

const RegisterForm = lazy(() => import("../components/RegisterForm"));

const Registration = () => {
    return (
        <Layout>
            <Row justify={'center'} align={'middle'} className={'h100'}>
                <Suspense fallback={<Spin/>}>
                    <Card>
                        <RegisterForm/>
                    </Card>
                </Suspense>
            </Row>
        </Layout>
    );
};

export default Registration;