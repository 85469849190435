export const getFiltersTable = (pagination, filtersTable) => {
    let filters: any = {
        page: pagination.current,
    }

    if (Object.entries(filtersTable).length > 0) {
        Object.entries(filtersTable)
            .filter((item) => item[1])
            .map((item: any) => {
                filters = {
                    ...filters,
                    [item[0]]: Array.isArray(item[1]) ? item[1].join(',') : item[1],
                };
            });
    }

    if (!filters?.category && filters?.title) {
        delete filters.title;
    }

    if (!filters?.brand && filters?.model) {
        delete filters.model
    }

    return filters
}

export const convertObjFilterToUrl = (object: any) => {
    let url = ''

    if (object) {
        const filter = Object.entries({...object.filter}).filter(item => item[1])

        if (filter.length > 0) {
            url += `${filter.map((item: any) => `${item[0]}=${item[1].replace('+', '%2B')}`).join('&')}`
        }

        return url
    } else {
        return ''
    }

}