import {AuthAction, AuthActionEnum, IAuthState} from "./types";
import {IProfileInfo} from "../../../interfaces/user";

const initialState: IAuthState = {
    isAuth: false,
    error: '',
    is_loading: false,
    user: {} as IProfileInfo,
    token: '',
    loadingForgotPassword: false,
    okForgotMessage: ''
}

export default function authReducer(state = initialState, action: AuthAction): IAuthState {
    switch (action.type) {
        case AuthActionEnum.SET_AUTH:
            return {...state, isAuth: action.payload, is_loading: false}
        case AuthActionEnum.SET_ERROR:
            return {...state, error: action.payload, is_loading: false}
        case AuthActionEnum.SET_IS_LOADING:
            return {...state, is_loading: action.payload}
        case AuthActionEnum.SET_TOKEN:
            return {...state, token: action.payload, is_loading: false}
        case AuthActionEnum.SET_USER:
            return {...state, user: action.payload}
        case AuthActionEnum.SET_LOADING_FORGOT:
            return {...state, loadingForgotPassword: action.payload}
        case AuthActionEnum.SET_OK_FORGOT_MESSAGE:
            return {...state, okForgotMessage: action.payload}
        default:
            return state
    }
}
