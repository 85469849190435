import React, { ReactElement } from "react";
import Login from "../pages/Login";
import Registration from "../pages/Registration";
import Profile from "../pages/Profile";
import Company from "../pages/Company";
import Verify from "../pages/Verify";
import ResetPassword from "../pages/ResetPassword";

export interface IRoute {
  path: string;
  element: ReactElement;
  exact?: boolean;
}

export enum RouteNames {
  LOGIN = "/login",
  REGISTRATION = "/registration",
  PROFILE = "/profile",
  COMPANY = "/company",
  VERIFY = "/verify/:key",
  RESET_PASSWORD = "/reset_password/:uid/:token"
}

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.LOGIN,
    element: <Login />,
  },
  {
    path: RouteNames.REGISTRATION,
    element: <Registration />,
  },
  {
    path: RouteNames.VERIFY,
    element: <Verify />,
  },
  {
    path: RouteNames.RESET_PASSWORD,
    element: <ResetPassword />,
  },
];

export const privateRoutes: IRoute[] = [
  { path: RouteNames.PROFILE, element: <Profile /> },
  { path: RouteNames.COMPANY, element: <Company /> },
];
