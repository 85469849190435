import React, {FC} from 'react';
import {Modal, Form, Row, Col, Input, Select, message, Button} from "antd";
import {rules} from "../../utils/formRequired";
import {Companies} from "../../api/companies";

const {Option} = Select;

interface IComponentProps {
    visible: boolean,
    company: any
    handleCancel: () => void,
    handleChangeStaff?: (staff: any) => void,
    updateCompanyInfo: () => void,
    roles: any[],
}

const StaffModal: FC<IComponentProps> = ({visible, roles, updateCompanyInfo, handleChangeStaff, handleCancel, company}) => {
    const [form] = Form.useForm();

    const submit = (values: any) => {
        const body = {
            ...values,
            company
        };
        Companies.addStaffUser(body).then(res => {
            updateCompanyInfo();
            message.success('Сотрудник успешно добавлен');
            handleCancel();
        }).catch(e => {
            message.error(e)
        })
    }

    return (
        <Modal
            title={'Добавить сотрудника'}
            visible={visible}
            onCancel={handleCancel}
            footer={[]}

        >
            <Form onFinish={submit} form={form} layout={'vertical'}>
                <Row gutter={5}>
                    <Col lg={12} xs={24}>
                        <Form.Item
                            label={'Email'}
                            name={'email'}
                            rules={[{type: 'email', required: true, message: 'Обязательное поле'}]}
                        >
                            <Input placeholder={'Введите почту сотрудника'}/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Form.Item
                            label={'Роли'}
                            name={'role'}
                            initialValue={['manager']}
                            rules={[rules.required('Обязательное поле')]}
                        >
                            <Select  mode="multiple"
                                     allowClear
                                     defaultValue={['manager']}
                            >
                                {roles.map(role => {
                                    return (
                                        <Option key={role} value={role}>
                                            {role}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Form.Item rules={[rules.required('Обязательное поле')]} name={'password1'} label={'Пароль'}>
                            <Input.Password placeholder={'Введите пароль'}/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Form.Item rules={[rules.required('Обязательное поле')]} name={'password2'} label={'Подтверждение пароля'}>
                            <Input.Password placeholder={'Введите пароль'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'end'}>
                    <Button htmlType={'submit'} type={'primary'}>Добавить</Button>
                </Row>
            </Form>
        </Modal>
    );
};

export default StaffModal;