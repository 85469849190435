import { Layout, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import StocksTable from "../components/Company/StocksTable";
import { ErrorBoundary } from "../components/ErrorBoundary";
import SelectWithPagination from "../components/SelectWithPagination";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { CompaniesActionCreators } from "../store/reducers/companies/action-creators";

const Stocks = () => {
  const dispatch = useDispatch();
  const { companies, total, page } = useTypedSelector(
    (state) => state.companies
  );
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    if (companies.length === 0) {
      dispatch(CompaniesActionCreators.getCompanies(1));
    }
  }, []);

  return (
    <Layout>
      <Row>
        <SelectWithPagination
          array={companies}
          total={total}
          handleChange={(value, data) => {
            const parsedCompany = JSON.parse(data["data-value"]);
            parsedCompany && setSelectedCompany(parsedCompany);
            parsedCompany?.stocks && setStocks(parsedCompany.stocks);
          }}
          pageSize={5}
          style={{ width: 250 }}
          pageC={page}
          handleChangePage={(page) =>
            dispatch(CompaniesActionCreators.getCompanies(page))
          }
        />
        <ErrorBoundary>
          <StocksTable
            stocks={stocks}
            company={selectedCompany}
            handleChangeStocks={(stocks: any) => setStocks(stocks)}
          />
        </ErrorBoundary>
      </Row>
    </Layout>
  );
};

export default Stocks;
