import {apiUrl, token} from "../variables/appVariables";
import {IProfileInfo} from "../interfaces/user";

const getProfileInfo = async (tokenVar?: string): Promise<IProfileInfo> => {
    try {
        const url = `${apiUrl}/sp/rest-auth/user/`;
        // const url = `${apiUrl}/accounting_rest/rest-auth/user/`
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${tokenVar || token}`,
            }
        })
        const json = await res.json()

        if (!res.ok) {
            throw new Error(json);
        }

        return json
    }catch (e) {
        return Promise.reject(e)
    }
}

export {
    getProfileInfo
}