export const getOptionTitle = (option: any) => {
    let title

    if (option.alternate_names) {
        const namesArr = option.alternate_names.split(';')

        title = namesArr.length > 0 ? namesArr[namesArr.length - 1] : namesArr

        return title
    } else {
        title = option?.name || option?.display_name
        return title
    }
}