import { Empty } from "antd";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import DetailsItem from "./DetailsItem";

const Details = ({onHover, onMouseLeave, handleCloseModal}) => {
    const { details } = useTypedSelector(state => state.laximo);
    return (
        <>
            {details?.length > 0 ? (
                <div className="details-list">
                    {details.map((item) => (
                        <DetailsItem handleCloseModal={handleCloseModal} onHover={onHover} onMouseLeave={onMouseLeave} item={item} key={item.unit_id} />
                    ))}
                </div>
            ) : <Empty description={<span>Список деталей пуст</span>} />}
        </>
    );
}

export default Details;