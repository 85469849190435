import { CompaniesActionType, CompaniesActionTypeEnum, ICompaniesState } from "./types";

const initialState: ICompaniesState = {
    companies: [],
    selectedCompany: null,
    loading: false,
    page: 1,
    total: 0,
    options: [],
    transport_companies: [],
}

export default function companiesReducer(state = initialState, action: CompaniesActionType) {
    switch (action.type) {
        case CompaniesActionTypeEnum.SET_COMPANIES:
            return { ...state, companies: action.payload, loading: false };
        case CompaniesActionTypeEnum.SET_SELECTED_COMPANY:
            return { ...state, selectedCompany: action.payload };
        case CompaniesActionTypeEnum.SET_LOADING:
            return { ...state, loading: action.payload };
        case CompaniesActionTypeEnum.SET_PAGE:
            return { ...state, page: action.payload };
        case CompaniesActionTypeEnum.SET_TOTAL:
            return { ...state, total: action.payload };
        case CompaniesActionTypeEnum.SET_OPTIONS:
            return { ...state, options: action.payload }
        case CompaniesActionTypeEnum.SET_TRANSPORT_COMPANIES:
            return { ...state, transport_companies: action.payload };
        default:
            return state
    }
}