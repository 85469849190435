import { message } from "antd";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { LaximoActionCreators } from "../../../store/reducers/laximo/action-creators";
import { Laximo } from "../../../api/laximo";

const FoundedCarsListItem = ({ item }) => {
    const dispatch = useDispatch();
    const {selectedFoundedCar} = useTypedSelector(state => state.laximo);

    const handleSelectCar = car => {
        if (selectedFoundedCar?.ssd === car?.ssd) {
            dispatch(LaximoActionCreators.setActiveTab('2'));
            return;
        }
        dispatch(LaximoActionCreators.setLoadingTabs(true));
        dispatch(LaximoActionCreators.setLoadingCategories(true));
        Laximo.getCategories({ code: car.ssd, catalog: car.catalog, vehicle_id: car.vehicleid })
            .then(res => {
                dispatch(LaximoActionCreators.setCategories(res));
                dispatch(LaximoActionCreators.setSelectedFoundedCar(car));
                dispatch(LaximoActionCreators.setActiveTab('2'));
                dispatch(LaximoActionCreators.setSelectedCategory(null));
                dispatch(LaximoActionCreators.setSelectedDetail(null));
                dispatch(LaximoActionCreators.setSelectedUnitInfo(null));
            })
            .catch(e => message.error(e))
            .finally(() => {
                dispatch(LaximoActionCreators.setLoadingCategories(false));
                dispatch(LaximoActionCreators.setLoadingTabs(false));
            });
    };

    return (
        <div className={`founded-cars-list__item${item?.ssd === selectedFoundedCar?.ssd ? ' active' : ''}`} onClick={() => handleSelectCar(item)}>
            {item?.brand && <p><b>Марка:</b> {item.brand}</p>}
            {item?.name && <p><b>Модель:</b> {item.name}</p>}
            {item?.model_code && <p><b>Код модели:</b> {item.model_code}</p>}
            {item?.model_year && <p><b>Год модели:</b> {item.model_year}</p>}
            {item?.modification && <p><b>Модификация:</b> {item.modification}</p>}
            {item?.bodystyle && <p><b>Тип кузова:</b> {item.bodystyle}</p>}
            {item?.drive && <p><b>Руль:</b> {item.drive}</p>}
            {item?.country && <p><b>Страна:</b> {item.country}</p>}
            {item?.production_date && <p><b>Дата производства:</b> {item.production_date}</p>}
            {item?.date && <p><b>Дата производства:</b> {item.date}</p>}
            {item?.manufactured && <p><b>Год выпуска:</b> {item.manufactured}</p>}
            {(item?.modelyearfrom || item?.modelyearto) && <p><b>Год выпуска:</b> от {item?.modelyearfrom || '-'} - до {item?.modelyearto || '-'}</p>}
            {item?.market && <p><b>Рынок:</b> {item.market}</p>}
            {item?.engine && <p><b>Двигатель:</b> {item.engine}</p>}
            {item?.engine_serial && <p><b>Двигатель (серия):</b> {item.engine_serial}</p>}
            {item?.engine_type && <p><b>Двигатель (тип):</b> {item.engine_type}</p>}
            {item?.transmission && <p><b>Трансмиссия:</b> {item.transmission}</p>}
        </div>
    );
}

export default FoundedCarsListItem;