import { Alert, Col, message, Modal, Row } from "antd"
import { FC, useEffect, useState } from "react";
import { apiUrl } from "../../variables/appVariables";
import CatalogDetailsTable from "./CatalogDetailsTable";
import DetailsInDocTable from "./DetailsInDocTable";

interface ComponentProps {
    open: boolean,
    onClose: () => void,
    document: any,
}

const DetailsInDocModal: FC<ComponentProps> = ({ open, onClose, document }) => {
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);

    useEffect(() => {
        if (document) {
            getDetailsInDoc();
        }
    }, [document]);

    const getDetailsInDoc = async () => {
        try {
            setLoading(true);
            const url = `${apiUrl}/sp/document/${document.id}/`
            const response = await fetch(url, {
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                }
            })
            const json = await response.json();
            if (response.ok) {
                const formatArr = json.items.map(item => {
                    return {
                        detail_doc_id: item.id,
                        ...item.item
                    }
                })                
                setDetails(formatArr);
            } else {
                throw new Error(JSON.stringify(json));
            }
        } catch (e) {
            message.error(e.message);
        } finally {
            setLoading(false);
        }
    }
    return (
        <Modal
            title={document ? `Документ #${document.document_number} от ${Intl.DateTimeFormat('ru').format(new Date(document.document_date))}` : '...'}
            width={1000}
            visible={open}
            onCancel={onClose}
            footer={[]}
        >
            <Row gutter={16}>
                {/* details catalog */}
                <Col lg={12} md={24} xs={24}>
                    <Alert
                        style={{ marginBottom: 10 }}
                        banner
                        message={'Кликните два раза по детали, чтобы переместить её в документ'}
                    />
                    <h3>Каталог запчастей</h3>
                    <CatalogDetailsTable handleUpdateDocCatalog={() => getDetailsInDoc()} document={document}/>
                </Col>

                {/* details in document */}
                <Col lg={12} md={24} xs={24}>
                    <Alert
                        style={{ marginBottom: 10 }}
                        banner
                        message={'Кликните два раза по детали, чтобы удалить её из документа'}
                    />
                    <h3>Список запчастей в документе</h3>
                    <DetailsInDocTable
                        details={details}
                        loading={loading}
                        handleUpdateDocCatalog={() => getDetailsInDoc()}
                    />
                </Col>
            </Row>
        </Modal>
    )
}

export default DetailsInDocModal;