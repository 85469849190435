import {ChatAction, ChatActionEnum, IChatState} from "./types";

const initialState: IChatState = {
    selectedDialog: null,
}

export default function chatReducer(state = initialState, action: ChatAction) {
    switch (action.type) {
        case ChatActionEnum.SET_DIALOG:
            return {...state, selectedDialog: action.payload};
        default:
            return state;
    }
}