import { apiUrl } from "../variables/appVariables"

// laximo/categories/
export interface ICategoryQueryParams {
    code: string,
    catalog: string,
    vehicle_id: string,
}

// laximo/units/
export interface IUnitsQueryParams {
    code: string,
    catalog: string,
    vehicle_id: string,
    category_id: string,
    img_size: string,
}

// laximo/unit_info/
export interface IUnitInfoQueryParams {
    code: string,
    catalog: string,
    unit_id: string,
}

// laximo/details_by_unit/
export interface IDetailsUnitQueryParams {
    code: string,
    catalog: string,
    unit_id: string,
}

// laximo/get_auto_params/
export interface IAutoQueryParams {
    code: string,
    ssd: string,
}

// laximo/get_auto/
export interface IGetAutoQueryParams {
    catalog: string,
    ssd: string,
}

export class Laximo {
    // get car by vin code
    // laximo/find_by_vin
    public static async getCarByVin(vin_code = '') {
        try {
            const url = `${apiUrl}/sp/laximo/find_by_vin/?vin=${vin_code}`
            const res = await fetch(url, {
                headers: { Authorization: `Token ${localStorage.getItem('token')}` }
            })
            const json = await res.json();

            if (!res.ok) {
                return Promise.reject('Ошибка запроса поиска по вин коду');
            }

            if (json.status === 'error') {
                return Promise.reject(json.message);
            }

            if (json.status === 'success') {
                return json.result;
            }
        } catch (e) {
            return Promise.reject(`Ошибка запроса поиска по вин коду: ${e}`)
        }
    }

    // get categories from laximo
    // laximo/categories/
    public static async getCategories(params: ICategoryQueryParams) {
        try {
            const paramsString = new URLSearchParams({ ...params });
            const url = `${apiUrl}/sp/laximo/categories/?${paramsString}`;
            const res = await fetch(url, {
                headers: { Authorization: `Token ${localStorage.getItem('token')}` }
            })
            const json = await res.json();

            if (!res.ok) {
                return Promise.reject('Ошибка запроса получения каталога запчастей');
            }

            if (json.status === 'error') {
                return Promise.reject(json.message);
            }

            if (json.status === 'success') {
                return json.result;
            }
        } catch (e) {
            return Promise.reject(`Ошибка запроса получения каталога запчастей: ${e}`);
        }
    }

    // get units from laximo
    // laximo/units/
    public static async getUnits(params: IUnitsQueryParams) {
        try {
            const paramsString = new URLSearchParams({ ...params });
            const url = `${apiUrl}/sp/laximo/units/?${paramsString}`;
            const res = await fetch(url, {
                headers: { Authorization: `Token ${localStorage.getItem('token')}` }
            })
            const json = await res.json();

            if (!res.ok) {
                return Promise.reject('Ошибка запроса получения списка агрегатов автомобиля');
            }

            if (json.status === 'error') {
                return Promise.reject(json.message);
            }

            if (json.status === 'success') {
                return json.result;
            }
        } catch (e) {
            return Promise.reject(`Ошибка запроса получения списка агрегатов автомобиля: ${e}`);
        }
    }

    // get unit info from laximo
    // laximo/unit_info/
    public static async getUnitInfo(params: IUnitInfoQueryParams) {
        try {
            const paramsString = new URLSearchParams({ ...params });
            const url = `${apiUrl}/sp/laximo/unit_info/?${paramsString}`;
            const res = await fetch(url, {
                headers: { Authorization: `Token ${localStorage.getItem('token')}` }
            })
            const json = await res.json();

            if (!res.ok) {
                return Promise.reject('Ошибка запроса получения информации по агрегату');
            }

            if (json.status === 'error') {
                return Promise.reject(json.message);
            }

            if (json.status === 'success') {
                return json.result;
            }
        } catch (e) {
            return Promise.reject(`Ошибка запроса получения информации по агрегату: ${e}`);
        }
    }

    // get details by unit from laximo
    // laximo/details_by_unit/
    public static async getDetailsByUnit(params: IDetailsUnitQueryParams) {
        try {
            const paramsString = new URLSearchParams({ ...params });
            const url = `${apiUrl}/sp/laximo/details_by_unit/?${paramsString}`;
            const res = await fetch(url, {
                headers: { Authorization: `Token ${localStorage.getItem('token')}` }
            })
            const json = await res.json();

            if (!res.ok) {
                return Promise.reject('Ошибка запроса получения списка деталей узла');
            }

            if (json.status === 'error') {
                return Promise.reject(json.message);
            }

            if (json.status === 'success') {
                return json.result;
            }
        } catch (e) {
            return Promise.reject(`Ошибка запроса получения списка деталей узла: ${e}`);
        }
    }

    //NO VIN SEARCH BY CAR CATALOG
    // get cataloh cars from laximo
    // laximo/catalogs/
    public static async getCarCatalog() {
        try {
            const url = `${apiUrl}/sp/laximo/catalogs/`;
            const res = await fetch(url, {
                headers: { Authorization: `Token ${localStorage.getItem('token')}` }
            })
            const json = await res.json();

            if (!res.ok) {
                return Promise.reject('Ошибка запроса получения списка машин');
            }

            if (json.status === 'error') {
                return Promise.reject(json.message);
            }

            if (json.status === 'success') {
                return json.result;
            }
        } catch (e) {
            return Promise.reject(`Ошибка запроса получения списка машин: ${e}`);
        }
    }

    // get auto params from laximo
    // laximo/get_auto_params/
    public static async getAutoParams(params: IAutoQueryParams) {
        try {
            const paramsString = new URLSearchParams({ ...params });
            const url = `${apiUrl}/sp/laximo/get_auto_params/?${paramsString}`;
            const res = await fetch(url, {
                headers: { Authorization: `Token ${localStorage.getItem('token')}` }
            })
            const json = await res.json();

            if (!res.ok) {
                return Promise.reject('Ошибка запроса получения параметров авто');
            }

            if (json.status === 'error') {
                return Promise.reject(json.message);
            }

            if (json.status === 'success') {
                return json.result;
            }
        } catch (e) {
            return Promise.reject(`Ошибка запроса получения параметров авто: ${e}`);
        }
    }

    // get auto from laximo
    // laximo/get_auto/
    public static async getAuto(params: IGetAutoQueryParams) {
        try {
            const paramsString = new URLSearchParams({ ...params });
            const url = `${apiUrl}/sp/laximo/get_auto/?${paramsString}`;
            const res = await fetch(url, {
                headers: { Authorization: `Token ${localStorage.getItem('token')}` }
            })
            const json = await res.json();

            if (!res.ok) {
                return Promise.reject('Ошибка запроса получения авто');
            }

            if (json.status === 'error') {
                return Promise.reject(json.message);
            }

            if (json.status === 'success') {
                return json.result;
            }
        } catch (e) {
            return Promise.reject(`Ошибка запроса получения авто: ${e}`);
        }
    }
}