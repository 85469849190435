import React, { useEffect, useState } from "react";
import { Layout, Row } from "antd";
import SelectWithPagination from "../components/SelectWithPagination";
import Unloading from "../components/Unloading";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { CompaniesActionCreators } from "../store/reducers/companies/action-creators";
import { Companies } from "../api/companies";

const UnloadingPage = () => {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const dispatch = useDispatch();
    const { companies, total, page, options } = useTypedSelector(state => state.companies);

    useEffect(() => {
        companies?.length === 0 && dispatch(CompaniesActionCreators.getCompanies(1));
        options?.length === 0 && dispatch(CompaniesActionCreators.getOptions());
    }, []);

    return (
        <Layout>
            <Row>
                <SelectWithPagination
                    array={companies}
                    total={total}
                    handleChange={(value, data) => {
                        const company = JSON.parse(data['data-value']);
                        company?.id && Companies.getCompanyById(company.id).then(res => setSelectedCompany(res))
                        // setSelectedCompany(company);
                    }}
                    pageSize={5}
                    style={{ width: 250, marginBottom: 20 }}
                    pageC={page}
                    handleChangePage={(page) => dispatch(CompaniesActionCreators.getCompanies(page))}
                />

                <Unloading company={selectedCompany}
                    updateCompanyInfo={(data) => setSelectedCompany(data)} />
            </Row>
        </Layout>
    )
}

export default UnloadingPage;