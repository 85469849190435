import { Tag } from "antd"
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { LaximoActionCreators } from "../../store/reducers/laximo/action-creators";

const SelectedItems = () => {
    const dispatch = useDispatch();
    const {
        selectedFoundedCar,
        selectedCategory,
        selectedUnitInfo,
        selectedDetail
    } = useTypedSelector(state => state.laximo);

    const handleCloseCar = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(LaximoActionCreators.setSelectedFoundedCar(null));
        dispatch(LaximoActionCreators.setSelectedCategory(null));
        dispatch(LaximoActionCreators.setSelectedDetail(null));
        dispatch(LaximoActionCreators.setSelectedUnitInfo(null));
        dispatch(LaximoActionCreators.setActiveTab('1'));
    }

    const handleCloseCategory = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(LaximoActionCreators.setSelectedCategory(null));
        dispatch(LaximoActionCreators.setSelectedDetail(null));
        dispatch(LaximoActionCreators.setSelectedUnitInfo(null));
        dispatch(LaximoActionCreators.setActiveTab('2'));
    }

    const handleCloseUnit = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(LaximoActionCreators.setSelectedDetail(null));
        dispatch(LaximoActionCreators.setSelectedUnitInfo(null));
        dispatch(LaximoActionCreators.setActiveTab('3'));
    }

    const handleCloseDetail = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(LaximoActionCreators.setSelectedDetail(null));
        dispatch(LaximoActionCreators.setActiveTab('4'));
    }

    return (
        <div style={{margin: '10px 0'}}>
            {/* selected car */}
            {
                selectedFoundedCar && (
                    <Tag closable onClose={handleCloseCar}>
                        {selectedFoundedCar?.brand} {selectedFoundedCar?.name}
                    </Tag>
                )
            }

            {/* selected category  */}
            {
                selectedCategory && (
                    <Tag closable onClose={handleCloseCategory}>
                        {selectedCategory?.name}
                    </Tag>
                )
            }

            {/* selected unit  */}
            {
                selectedUnitInfo && (
                    <Tag closable onClose={handleCloseUnit}>
                        {selectedUnitInfo?.name}
                    </Tag>
                )
            }

            {/* selected detail  */}
            {
                selectedDetail && (
                    <Tag closable onClose={handleCloseDetail}>
                        {selectedDetail?.name}
                    </Tag>
                )
            }
        </div>
    )
}

export default SelectedItems;