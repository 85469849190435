import React, {FC} from 'react';
import {Button, Result} from "antd";

interface IComponentProps {
    addTab: () => void,
}

const StartWork: FC<IComponentProps> = ({addTab}) => {
    return (
        <Result
            style={{padding: 0}}
            status="404"
            title="Добро пожаловать в учетную систему Partobo!"
            subTitle="Для начала работы вы можете кликнуть по любой из вкладок в меню слева или на кнопку ниже."
            extra={<Button type="primary" onClick={addTab}>В раздел компании</Button>}
        />
    );
};

export default StartWork;