import { ILaximoState, LaximoAction, LaximoActionEnum } from "./types"

const initialState: ILaximoState = {
    //find_by_bin
    foundedCars: [],
    loadingFoundedCars: false,
    selectedFoundedCar: null,
    //categories
    categories: [],
    loadingCategories: false,
    selectedCategory: null,
    //units
    units: [],
    loadingUnits: false,
    selectedUnit: null,
    //unit info
    unitInfo: null,
    loadingUnitInfo: false,
    selectedUnitInfo: null,
    //details by unit
    details: [],
    loadingDetails: false,
    selectedDetail: null,
    //tabs
    tabs: [],
    activeTab: '1',
    loadingTab: false,
    //messages
    error: '',
    //cars catalog
    carsCatalog: [],
    loadingCarsCatalog: false,
    selectedCarsCatalog: null,
    autoParams: [],
    loadingAutoParams: false,
    selectedAutoParams: null,
}

export default function laximoReducer(state = initialState, action: LaximoAction): ILaximoState {
    switch (action.type) {
        //find_by_bin
        case LaximoActionEnum.SET_FOUNDED_CARS:
            return { ...state, foundedCars: action.payload };
        case LaximoActionEnum.SET_LOADING_FOUNDED_CARS:
            return { ...state, loadingFoundedCars: action.payload };
        case LaximoActionEnum.SET_SELECTED_FOUNDED_CAR:
            return { ...state, selectedFoundedCar: action.payload };
        //categories
        case LaximoActionEnum.SET_CATEGORIES:
            return { ...state, categories: action.payload };
        case LaximoActionEnum.SET_LOADING_CATEGORIES:
            return { ...state, loadingCategories: action.payload };
        case LaximoActionEnum.SET_SELECTED_CATEGORY:
            return { ...state, selectedCategory: action.payload };
        //units
        case LaximoActionEnum.SET_UNITS:
            return { ...state, units: action.payload };
        case LaximoActionEnum.SET_LOADING_UNITS:
            return { ...state, loadingUnits: action.payload };
        case LaximoActionEnum.SET_SELECTED_UNIT:
            return { ...state, selectedUnit: action.payload };
        case LaximoActionEnum.SET_UNIT_INFO:
            return { ...state, unitInfo: action.payload };
        case LaximoActionEnum.SET_LOADING_UNIT_INFO:
            return { ...state, loadingUnitInfo: action.payload };
        case LaximoActionEnum.SET_SELECTED_UNIT_INFO:
            return { ...state, selectedUnitInfo: action.payload };
        //details by unit
        case LaximoActionEnum.SET_DETAILS:
            return { ...state, details: action.payload };
        case LaximoActionEnum.SET_LOADING_DETAILS:
            return { ...state, loadingDetails: action.payload };
        case LaximoActionEnum.SET_SELECTED_DETAIL:
            return { ...state, selectedDetail: action.payload };
        //tabs
        case LaximoActionEnum.SET_TABS:
            return { ...state, tabs: action.payload };
        case LaximoActionEnum.SET_LOADING_TAB:
            return { ...state, loadingTab: action.payload };
        case LaximoActionEnum.SET_ACTIVE_TAB:
            return { ...state, activeTab: action.payload };
        //messages
        case LaximoActionEnum.SET_LAXIMO_ERROR:
            return { ...state, error: action.payload };
        //cars catalog
        case LaximoActionEnum.SET_CARS_CATALOG:
            return {...state, carsCatalog: action.payload};
        case LaximoActionEnum.SET_LOADING_CARS_CATALOG:
            return {...state, loadingCarsCatalog: action.payload};
        case LaximoActionEnum.SET_SELECTED_CARS_CATALOG:
            return {...state, selectedCarsCatalog: action.payload};
        case LaximoActionEnum.SET_AUTO_PARAMS:
            return {...state, autoParams: action.payload};
        case LaximoActionEnum.SET_LOADING_AUTO_PARAMS:
            return {...state, loadingAutoParams: action.payload};
        case LaximoActionEnum.SET_SELECTED_AUTO_PARAMS:
            return {...state, selectedAutoParams: action.payload};
        default:
            return state
    }
}