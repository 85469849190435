import { Input } from "antd";
import { useEffect, useState } from "react";
import CarsCatalogItem from "./CarsCatalogItem";

const CarsCatalog = ({ cars }) => {
    const [filteredCars, setFilteredCars] = useState([]);

    useEffect(() => {
        if (cars?.length > 0) {
            setFilteredCars(cars);
        }
    }, [cars]);

    const filterCars = (keyword) => {
        if (keyword.length > 0) {
            const newCars = cars.filter(item => item.brand.toLowerCase().includes(keyword.toLowerCase()));
            setFilteredCars(newCars);
        } else {
            setFilteredCars(cars);
        }
    }
    return (
        <>
            <Input style={{marginBottom: 10, maxWidth: 300}} placeholder="Найти марку" allowClear onChange={(e) => filterCars(e.target.value)} />
            <div className="cars-catalog">
                {filteredCars.map(item => <CarsCatalogItem car={item} key={item.code} />)}
            </div>
        </>

    );
}

export default CarsCatalog