import React, {FC, useEffect, useRef, useState} from 'react';
import {Button, Image, Row, Spin} from 'antd';
import {apiUrl} from "../../variables/appVariables";
import {ArrowLeftOutlined, CameraOutlined, SendOutlined} from "@ant-design/icons/lib";

const ChatMessages: FC<any> = ({
                                   testMessages,
                                   messages,
                                   handleChangeLimitMessages,
                                   handleBack,
                                   currentDialog,
                                   messagesLimit,
                                   messagesCount,
                                   ws,
                                   getMessages
                               }) => {
    const messagesEndRef = useRef<any>()
    const messagesRef = useRef<any>()
    const inputRef = useRef<any>()
    const anchor = useRef<any>(0)

    const [fetchingMessages, setFetchingMessages] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [uploadedImage, setUploadedImage] = useState('')

    useEffect(() => {
        setTimeout(() => {
            inputRef.current.focus()
            messagesEndRef?.current?.scrollIntoView({behavior: "smooth"})
        }, 10)
    }, [])

    useEffect(() => {
        messagesRef?.current?.addEventListener('scroll', handleScrollMessages)

        if (fetchingMessages) {
            setTimeout(() => {
                setFetchingMessages(false)
                messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight - anchor.current)
            }, 10)
        }

        if ((messagesRef?.current?.scrollHeight - messagesRef?.current?.scrollTop) == 270) {
            setTimeout(() => {
                messagesEndRef?.current?.scrollIntoView({behavior: "smooth"})
            }, 10)
        }


        return () => {
            messagesRef?.current?.removeEventListener("scroll", handleScrollMessages);
        };
    }, [messages])

    useEffect(() => {
        if (fetchingMessages) {
            anchor.current = messagesRef.current.scrollHeight
            setTimeout(() => {
                handleChangeLimitMessages(messagesLimit + 20)
                // setFetchingMessages(false)
            }, 1000)
        }
    }, [fetchingMessages])

    const renderMessages = (messages) => {
        return (
            <div className={'chat-group'}>
                <div className="chat-group-date">{messages.group}</div>
                <div className="chat-messages-wrapper">
                    {
                        messages.messages.map((item) => {
                            return (
                                <div key={item.id}
                                     className={`chat-messages_item ${item.user == 'company' || item.user == 'err' || item.user == 'partobo' ? 'customer' : 'shop'}`}>
                                    <div
                                        className={`chat-message ${item.user == 'customer' || item.user == 'err' ? 'shop' : item.user == 'partobo' ? 'support' : item.user == 'shop' ? 'customer' : ''}`}>
                                        <div
                                            className="chat-username">{item.user == 'partobo' ? `Поддержка Partobo - ${item.username}` : item.user == 'shop' ? `Магазин - ${item.username}` : item.username}</div>
                                        <div className="chat-text">
                                            <span>{item.text}</span>
                                            {
                                                item.photo ?
                                                    <Image
                                                        width={100}
                                                        height={50}
                                                        style={{objectFit: 'cover', borderRadius: '5px'}}
                                                        src={`https://partobo.ru:8000${item.photo}`}
                                                    />
                                                    : null
                                            }
                                        </div>
                                        <div className="chat-time">{item.time}</div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>
                <div ref={messagesEndRef}></div>
            </div>
        )
    }

    const handleScrollMessages = (e) => {
        const isFetch = e.target.scrollTop == 0
        if (isFetch && ((messagesCount > messages?.messages?.length) && (messagesCount !== messages?.messages?.length))) {
            setFetchingMessages(true)
        }
    }

    const sendPhoto = async (dialogId, text, photo) => {
        try {
            const formData = new FormData()
            formData.append('photo', photo, photo.name)
            formData.append('text', text)
            formData.append('dialog', dialogId)

            const url = `${apiUrl}marketplace/send_message_with_photo/`
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                },
                body: formData
            })
            const json = await res.json()
            if (json.status == 'ok') {
                await ws.send(JSON.stringify({'type': 'send_photo', 'message': json['message_id']}));
                await ws.send(JSON.stringify({'type': 'get', 'dialog': dialogId, 'limit': 20}))
            }
        } catch (e) {
            console.log('sendPhotoError', e)
        }
    }

    const sendMessage = async (dialogId: any, message: any, photo?: any) => {
        if (photo && uploadedImage) {
            await sendPhoto(dialogId, message, photo)
        } else {
            await ws.send(JSON.stringify({'type': 'send', "user": 'company','dialog': dialogId, 'text': message}));
            getMessages(dialogId, currentDialog)

        }
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleEnterInput = (e) => {
       if (e.key === 'Enter') {
           sendMessage(currentDialog.id, inputValue)
       }
    }

    return (
        <div className={'messages'}>
            <div className={'messages-header'}>
                <ArrowLeftOutlined onClick={handleBack}/>
                {
                    currentDialog?.detail_id ? (
                        <a href={`https://partobo.ru/detail/${currentDialog.detail_id}`} target={'_blank'}>
                            <img
                                onError={(e: any) => e.target.src = `https://i.pinimg.com/originals/8a/eb/d8/8aebd875fbddd22bf3971c3a7159bdc7.png`}
                                src={currentDialog?.photo || `https://i.pinimg.com/originals/8a/eb/d8/8aebd875fbddd22bf3971c3a7159bdc7.png`}
                                alt={currentDialog?.detail}
                            />
                            <div className="messages-header__info">
                                <b>{currentDialog?.company || 'Неизветсный'}</b>
                                <span>{currentDialog?.detail}</span>
                            </div>
                        </a>
                    ) : (
                        <>
                            <img
                                onError={(e: any) => e.target.src = `https://i.pinimg.com/originals/8a/eb/d8/8aebd875fbddd22bf3971c3a7159bdc7.png`}
                                src={currentDialog?.photo || `https://i.pinimg.com/originals/8a/eb/d8/8aebd875fbddd22bf3971c3a7159bdc7.png`}
                                alt={currentDialog?.detail}
                            />
                            <div className="messages-header__info">
                                <b>{currentDialog?.company || 'Неизветсный'}</b>
                                <span>{currentDialog?.detail}</span>
                            </div>
                        </>
                    )
                }

            </div>
            <div className="messages-content" ref={messagesRef}>
                {fetchingMessages && (<Row justify={'center'}><Spin tip="Загрузка..."/></Row>)}
                {/*<div className={fetchingMessages && 'loader mes'}></div>*/}
                {testMessages.length > 0 ? (
                    <>
                        {
                            testMessages.map((item) => {
                                return renderMessages(item)
                            })
                        }
                    </>
                ) : (
                    <>
                        <div className={'chat-group no-messages'}>
                            <div className="chat-messages-wrapper">
                                <div className={`chat-messages_item`}>
                                    Нет сообщений! Но вы можете начать диалог первым!
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="messages-form">
                <form onSubmit={(e: any) => {
                    e.preventDefault()
                    const message = e.target.message.value
                    if(message && message.length > 0) {
                        sendMessage(currentDialog.id, message)
                        e.target.message.value = ''
                        e.target.photo.value = ''
                        setUploadedImage('')
                        setInputValue('')
                    }
                }}>


                    <input defaultValue={''} onChange={(e) => {
                        if (e?.target?.files?.length > 0) {
                            sendMessage(currentDialog.id, '', e.target.files[0])
                            setUploadedImage(window.URL.createObjectURL(e.target.files[0]))
                        }
                    }} accept="image/*" id="icon-button-file" name={'photo'}
                           type="file"/>
                    <label htmlFor="icon-button-file">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<CameraOutlined />}
                            style={{
                                backgroundColor: 'transparent !important',
                                padding: '5px',
                                margin: '0 5px',
                            }}
                        />

                    </label>

                    <input
                        name={'message'}
                        ref={inputRef}
                        value={inputValue}
                        onChange={handleInputChange}
                        // onKeyDown={handleEnterInput}
                        placeholder={'Написать сообщение'}
                        type={'text'}
                    />
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<SendOutlined />}
                        htmlType="submit"
                        style={{
                            backgroundColor: 'transparent !important',
                            padding: '5px',
                            margin: '0 5px',
                        }}
                        disabled={inputValue.length == 0}
                    />
                </form>
            </div>
        </div>
    );
};

export default ChatMessages;