import React, { FC, useEffect, useState } from 'react';
import { Button, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons/lib";

const { Option } = Select;

const DetailBrandFilter = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    data,
    handleChange,
    reset
}) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (data?.length === 0) {
            clearFilters();
            setSelectedKeys([]);
            setValue(null);
        }
    }, [data])

    return (
        <div style={{padding: 8}}>
            <Select showSearch
                optionFilterProp="children"
                value={value}
                placeholder={'Выберите из списка'}
                style={{ marginBottom: 8, display: 'block' }}
                onChange={(value, data) => {
                    setValue(value);
                    setSelectedKeys(value);
                    handleChange(value, data)
                }}
            >
                {
                    data?.length > 0 && (
                        <>
                            {
                                data.map(item => (
                                    <Option key={item.brand} value={item.brand} data-value={JSON.stringify(item)}>
                                        {item.brand}
                                    </Option>
                                ))
                            }
                        </>
                    )
                }
            </Select>
            <Space>
                <Button
                    type="primary"
                    onClick={() => confirm({ closeDropdown: false })}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Найти
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys([]);
                        confirm({ closeDropdown: true });
                        setValue(null);
                        reset && reset();
                    }}
                    size="small"
                    style={{ width: 90 }}
                >
                    Сбросить
                </Button>
            </Space>
        </div>
    )
}

export default DetailBrandFilter;