import React from 'react';
import Navbar from "./Navbar";
import {Layout} from "antd";
import AppRouter from "./AppRouter";

const NoAuthLayout = () => {
    return (
        <>
            <Navbar/>
            <Layout.Content style={{ height: 'calc(100vh - 90px)', margin: '24px 16px 0' }}>
                <AppRouter/>
            </Layout.Content>
        </>
    );
};

export default NoAuthLayout;