import {
    IFoundedCarsAction, 
    LaximoActionEnum,
    ILoadingFoundedCarsAction, 
    ISelectedFoundedCarAction,
    ICategoriesAction,
    ICategoriesLoadingAction,
    ICategorySelectedAction,
    IUnitsAction,
    ILoadingUnitsAction,
    ISelectedUnitAction,
    IUnitInfoAction,
    ILoadingUnitInfoAction,
    ISelectedUnitInfoAction,
    ITabsAction,
    ILoadingTabAction,
    IActiveTabAction,
    IDetailsAction,
    ILoadingDetailsAction,
    ISelectedDetailAction,
    ICarsCatalogAction,
    ILoadingCarsCatalogAction,
    ISelectedCarsCatalogAction,
    IAutoParamsAction,
    ILoadingAutoParamsAction,
    ISelectedAutoParamsAction,
} from "./types";

export const LaximoActionCreators = {
    //find_by_bin
    setFoundedCars: (cars): IFoundedCarsAction => ({ type: LaximoActionEnum.SET_FOUNDED_CARS, payload: cars }),
    setLoadingFoundedCars: (loading): ILoadingFoundedCarsAction => ({ type: LaximoActionEnum.SET_LOADING_FOUNDED_CARS, payload: loading }),
    setSelectedFoundedCar: (car): ISelectedFoundedCarAction => ({ type: LaximoActionEnum.SET_SELECTED_FOUNDED_CAR, payload: car }),
    //categories
    setCategories: (categories): ICategoriesAction => ({type: LaximoActionEnum.SET_CATEGORIES, payload: categories}),
    setLoadingCategories: (loading): ICategoriesLoadingAction => ({type: LaximoActionEnum.SET_LOADING_CATEGORIES, payload: loading}),
    setSelectedCategory: (category): ICategorySelectedAction => ({type: LaximoActionEnum.SET_SELECTED_CATEGORY, payload: category}),
    //units
    setUnits: (units): IUnitsAction => ({type: LaximoActionEnum.SET_UNITS, payload: units}),
    setLoadingUnits: (loading): ILoadingUnitsAction => ({type: LaximoActionEnum.SET_LOADING_UNITS, payload: loading}),
    setSelectedUnit: (unit): ISelectedUnitAction => ({type: LaximoActionEnum.SET_SELECTED_UNIT, payload: unit}),
    //unit info
    setUnitInfo: (unitInfo): IUnitInfoAction => ({type: LaximoActionEnum.SET_UNIT_INFO, payload: unitInfo}),
    setLoadingUnitInfo: (loading): ILoadingUnitInfoAction => ({type: LaximoActionEnum.SET_LOADING_UNIT_INFO, payload: loading}),
    setSelectedUnitInfo: (unitInfo): ISelectedUnitInfoAction => ({type: LaximoActionEnum.SET_SELECTED_UNIT_INFO, payload: unitInfo}),
    //details
    setDetails: (details): IDetailsAction => ({type: LaximoActionEnum.SET_DETAILS, payload: details}),
    setLoadingDetails: (loading): ILoadingDetailsAction => ({type: LaximoActionEnum.SET_LOADING_DETAILS, payload: loading}),
    setSelectedDetail: (detail): ISelectedDetailAction => ({type: LaximoActionEnum.SET_SELECTED_DETAIL, payload: detail}),
    //tabs
    setTabs: (tabs): ITabsAction => ({type: LaximoActionEnum.SET_TABS, payload: tabs}),
    setLoadingTabs: (loading): ILoadingTabAction => ({type: LaximoActionEnum.SET_LOADING_TAB, payload: loading}),
    setActiveTab: (tab): IActiveTabAction => ({type: LaximoActionEnum.SET_ACTIVE_TAB, payload: tab}),
    //cars catalog
    setCarsCatalog: (catalog): ICarsCatalogAction => ({type: LaximoActionEnum.SET_CARS_CATALOG, payload: catalog}),
    setLoadingCarsCatalog: (loading): ILoadingCarsCatalogAction => ({type: LaximoActionEnum.SET_LOADING_CARS_CATALOG, payload: loading}),
    setSelectedCarsCatalog: (selected): ISelectedCarsCatalogAction => ({type: LaximoActionEnum.SET_SELECTED_CARS_CATALOG, payload: selected}),
    setAutoParams: (params): IAutoParamsAction => ({type: LaximoActionEnum.SET_AUTO_PARAMS, payload: params}),
    setLoadingAutoParams: (loading): ILoadingAutoParamsAction => ({type: LaximoActionEnum.SET_LOADING_AUTO_PARAMS, payload: loading}),
    setSelectedAutoParams: (selected): ISelectedAutoParamsAction => ({type: LaximoActionEnum.SET_SELECTED_AUTO_PARAMS, payload: selected}),
}