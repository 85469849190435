import React from "react";
import {Alert} from "antd";

export class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.children !== this.props.children)
            this.setState({error: false, errorInfo: null});
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({
            error,
            errorInfo
        })
    }

    render(): React.ReactNode {

        if (this.state.errorInfo) {
            return (
                <Alert
                    message="Ошибка"
                    description={this.state.error && this.state.error.toString()}
                    type="error"
                    showIcon
                />
            );
        }

        return this.props.children;
    }
}