import React, {Suspense, lazy} from 'react';
import {Layout, Row, Spin} from "antd";

const InvoicesTable = lazy(() => import("../components/Invoice/InvoicesTable"))

const InvoicesPage = () => {
    return (
        <Layout>
            <Row>
                <Suspense fallback={<Spin/>}>
                    <InvoicesTable/>
                </Suspense>
            </Row>
        </Layout>
    );
};

export default InvoicesPage;