import { ActionRegType, IRegState, RegActionEnum } from "./types";

const initialState: IRegState = {
  error: "",
  is_loading: false,
  success: false,
};

export default function regReducer(
  state = initialState,
  action: ActionRegType
): IRegState {
  switch (action.type) {
    case RegActionEnum.SET_IS_LOADING:
      return { ...state, is_loading: action.payload };
    case RegActionEnum.SET_ERROR:
      return { ...state, error: action.payload, is_loading: false };
    case RegActionEnum.SET_SUCCESS:
      return { ...state, success: action.payload };
    default:
      return state;
  }
}
