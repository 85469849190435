import { List, message, Pagination, Row, Select } from "antd";
import React, { FC, useEffect, useState } from "react";
import { apiUrl } from "../../variables/appVariables";

interface ComponentProps {
  user?: any;
}

const AnalyticsUserActions: FC<ComponentProps> = ({ user }) => {
  const [actions, setActions] = useState<any[]>([]);
  const [loadingActions, setLoadingActions] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentSort, setCurrentSort] = useState("desc");

  useEffect(() => {
    if (user) {
      getActions({
        page: 1,
        [user?.user_id ? "user" : "token"]: user?.user_id
          ? user?.user_id
          : user.token,
        sort: currentSort,
      });
    }
  }, [user]);

  const getActions = async (params: any) => {
    try {
      setLoadingActions(true);
      const filters = params
        ? `?${new URLSearchParams(params).toString()}`
        : "";
      const url = `${apiUrl}/analytics/actions_user/${filters}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (json.result) {
        setActions(json.result);
        setTotalPages(json.pages.total_pages);
      }
    } catch (e: any) {
      message.error(e.message);
    } finally {
      setLoadingActions(false);
    }
  };

  const handleChangePageActions = (page: number) => {
    getActions({
      page,
      [user?.user_id ? "user" : "token"]: user?.user_id
        ? user?.user_id
        : user.token,
      sort: currentSort,
    });
  };

  const handleChangeSort = (value: string) => {
    setCurrentSort(value);
    getActions({
      page: 1,
      [user?.user_id ? "user" : "token"]: user?.user_id
        ? user?.user_id
        : user.token,
      sort: value,
    });
  };

  return (
    <>
      <Row justify={"end"}>
        <Select
          disabled={actions.length === 0}
          defaultValue="desc"
          style={{ width: 170 }}
          onChange={handleChangeSort}
          options={[
            {
              value: "desc",
              label: "По убыванию",
            },
            {
              value: "asc",
              label: "По возрастанию",
            },
          ]}
        />
      </Row>

      <List
        itemLayout="horizontal"
        dataSource={actions}
        loading={loadingActions}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta title={item?.category} description={item?.event} />
            <div>
              {new Intl.DateTimeFormat("ru-RU", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              }).format(new Date(item?.date))}
            </div>
          </List.Item>
        )}
      />
      <Pagination
        disabled={actions.length === 0}
        style={{ marginTop: 10 }}
        defaultCurrent={1}
        onChange={handleChangePageActions}
        total={totalPages * 10}
        showSizeChanger={false}
      />
    </>
  );
};

export default AnalyticsUserActions;
