import {
  DetailsActionTypeEnum,
  ISetDetailsAction,
  ISetDetailsCatalogAction,
  ISetDetailsLoadingAction,
  ISetDetailsLoadingCatalogAction,
  ISetDetailsPageAction,
  ISetDetailsPageCatalogAction,
  ISetDetailsTotalAction,
  ISetDetailsTotalCatalogAction,
} from "./types";
import { AppDispatch } from "../../index";
// import {Invoices} from "../../../api/invoices";
import { Detail } from "../../../api/detail";

export const DetailsActionCreators = {
  setDetails: (details: any[]): ISetDetailsAction => ({
    type: DetailsActionTypeEnum.SET_DETAILS,
    payload: details,
  }),
  setPage: (page: number): ISetDetailsPageAction => ({
    type: DetailsActionTypeEnum.SET_PAGE,
    payload: page,
  }),
  setTotal: (total: number): ISetDetailsTotalAction => ({
    type: DetailsActionTypeEnum.SET_TOTAL,
    payload: total,
  }),
  setLoading: (loading: boolean): ISetDetailsLoadingAction => ({
    type: DetailsActionTypeEnum.SET_LOADING,
    payload: loading,
  }),
  setDetailsCatalog: (details: any[]): ISetDetailsCatalogAction => ({
    type: DetailsActionTypeEnum.SET_DETAILS_CATALOG,
    payload: details,
  }),
  setPageCatalog: (page: number): ISetDetailsPageCatalogAction => ({
    type: DetailsActionTypeEnum.SET_PAGE_CATALOG,
    payload: page,
  }),
  setTotalCatalog: (total: number): ISetDetailsTotalCatalogAction => ({
    type: DetailsActionTypeEnum.SET_TOTAL_CATALOG,
    payload: total,
  }),
  setLoadingCatalog: (loading: boolean): ISetDetailsLoadingCatalogAction => ({
    type: DetailsActionTypeEnum.SET_LOADING_CATALOG,
    payload: loading,
  }),
  getDetailsByInvoice: (params: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(DetailsActionCreators.setLoading(true));
      Detail.getCatalogDetails(params).then((res) => {
        dispatch(DetailsActionCreators.setPage(1));
        dispatch(DetailsActionCreators.setTotal(res.count));
        dispatch(DetailsActionCreators.setDetails(res.results));
      });
    } finally {
      dispatch(DetailsActionCreators.setLoading(false));
    }
  },
  getDetailsCatalog: (params: any) => async (dispatch: AppDispatch) => {
    try {
      console.log("start fetch");
      dispatch(DetailsActionCreators.setLoadingCatalog(true));
      Detail.getCatalogDetails(params)
        .then((res) => {
          if (res) {
            dispatch(DetailsActionCreators.setPageCatalog(1));
            dispatch(DetailsActionCreators.setTotalCatalog(res.count));
            dispatch(DetailsActionCreators.setDetailsCatalog(res.results));
            dispatch(DetailsActionCreators.setLoadingCatalog(false));
          } else {
            throw new Error("response not found");
          }
        })
        .finally(() => {
          dispatch(DetailsActionCreators.setLoadingCatalog(false));
        });
    } catch (e) {
      dispatch(DetailsActionCreators.setLoading(false));
    }
  },
};
