import { Empty, Spin } from "antd";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import FoundedCarsList from "./FoundedCarsList";

const FoundedCars = () => {
    const { foundedCars, loadingFoundedCars, loadingTab } = useTypedSelector(state => state.laximo);
    return(
        <Spin spinning={loadingFoundedCars || loadingTab}>
            {foundedCars?.length > 0 ? <FoundedCarsList /> : <Empty  description={<span>Машин не найдено</span>} />}
        </Spin>
    )
}

export default FoundedCars;