import { message } from "antd";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Laximo } from "../../../api/laximo";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { LaximoActionCreators } from "../../../store/reducers/laximo/action-creators";

const UnitItem = ({ item }) => {
    const dispatch = useDispatch();
    const { selectedFoundedCar, selectedUnit } = useTypedSelector(state => state.laximo);

    const isSelected = useMemo(() => {
        if (selectedUnit?.ssd === item?.ssd) {
            return true;
        } else {
            return false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUnit])

    const handleClick = () => {
        if (selectedUnit?.ssd === item?.ssd) {
            dispatch(LaximoActionCreators.setActiveTab('4'));
            return;
        }
        dispatch(LaximoActionCreators.setLoadingUnitInfo(true));
        dispatch(LaximoActionCreators.setLoadingTabs(true));
        Laximo.getUnitInfo({ code: item.ssd, catalog: selectedFoundedCar.catalog, unit_id: item.unit_id })
            .then(res1 => {
                dispatch(LaximoActionCreators.setSelectedDetail(null));
                Laximo.getDetailsByUnit({ code: res1.ssd, catalog: selectedFoundedCar.catalog, unit_id: res1.unit_id })
                    .then(res2 => {
                        dispatch(LaximoActionCreators.setSelectedUnit(item));
                        dispatch(LaximoActionCreators.setSelectedUnitInfo(res1));
                        dispatch(LaximoActionCreators.setUnitInfo(res1));
                        dispatch(LaximoActionCreators.setDetails(res2));
                        dispatch(LaximoActionCreators.setActiveTab('4'));
                    })
                    .catch(e => message.error)
                    .finally(() => {
                        dispatch(LaximoActionCreators.setLoadingUnitInfo(false));
                        dispatch(LaximoActionCreators.setLoadingTabs(false));
                    })
            }).catch(e => message.error(e))
    }

    return (
        <div className={`units-item${isSelected ? ' active' : ''}`} onClick={handleClick}>
            <img src={item.image_url} alt={item.name} />
            <p>{item.name}</p>
        </div>
    );
}

export default UnitItem;