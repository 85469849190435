import {Button, Input, Space} from 'antd';
import React, {FC, useEffect, useRef, useState} from 'react';
import {SearchOutlined} from "@ant-design/icons/lib";

const DetailFilterInput: FC<any> = ({
                                         setSelectedKeys,
                                         selectedKeys,
                                         confirm,
                                         clearFilters,
                                         visible,
                                         placeholder,
                                     }) => {
    const [value, setValue] = useState('');
    const inputRef = useRef<any>();

    useEffect(() => {
        if (visible) {
            inputRef.current.focus();
        }
    }, [visible])

    return (
        <div style={{ padding: 8 }}>
            <Input
                ref={inputRef}
                placeholder={placeholder ?? `Поиск по категории`}
                value={value}
                onChange={e => {
                    setSelectedKeys(e.target.value ? [e.target.value] : []);
                    setValue(e.target.value)
                }}
                onPressEnter={() =>  confirm({closeDropdown: false})}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() =>  confirm({closeDropdown: false})}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Найти
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys([]);
                        confirm({closeDropdown: true});
                        setValue('')

                    }}
                    size="small"
                    style={{ width: 90 }}
                >
                    Сбросить
                </Button>
            </Space>
        </div>
    );
};

export default DetailFilterInput;