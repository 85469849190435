import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Laximo } from "../../../api/laximo";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { LaximoActionCreators } from "../../../store/reducers/laximo/action-creators";
import Tree from "./Tree";

const TreeNode = ({ item }) => {
    const dispatch = useDispatch();
    const { selectedFoundedCar, selectedCategory } = useTypedSelector(state => state.laximo);
    const [showChildren, setShowChildren] = useState(false);

    const isSelected = useMemo(() => {
        if ((selectedCategory?.category_id === item?.category_id)) {
            return true;
        }

        if (selectedCategory?.parent_category_id && (selectedCategory?.parent_category_id === item.category_id)) {
            return true;
        }

        return false;
    }, [selectedCategory]);

    useEffect(() => {
        if(isSelected) {
            setShowChildren(true);
        }
    }, [isSelected])

    const handleClick = () => {
        if (!item.has_childrens) {
            if (selectedCategory?.category_id === item?.category_id) {
                dispatch(LaximoActionCreators.setActiveTab('3'))
                return;
            }
            dispatch(LaximoActionCreators.setLoadingUnits(true));
            dispatch(LaximoActionCreators.setLoadingTabs(true));
            Laximo.getUnits({
                code: item.ssd,
                catalog: selectedFoundedCar.catalog,
                vehicle_id: selectedFoundedCar.vehicleid,
                category_id: item.category_id,
                img_size: 'xxs',
            })
                .then(res => {
                    dispatch(LaximoActionCreators.setSelectedCategory(item));
                    dispatch(LaximoActionCreators.setUnits(res))
                    dispatch(LaximoActionCreators.setActiveTab('3'));
                    dispatch(LaximoActionCreators.setSelectedDetail(null));
                    dispatch(LaximoActionCreators.setSelectedUnitInfo(null));
                }
                )
                .catch((e) => message.error)
                .finally(() => {
                    dispatch(LaximoActionCreators.setLoadingUnits(false));
                    dispatch(LaximoActionCreators.setLoadingTabs(false));
                })
            return
        }
        setShowChildren(!showChildren);
    };
    return (
        <>
            <div onClick={handleClick}
                className={`tree-title${isSelected ? ' active' : ''}`}>
                {item?.has_childrens ? (
                    <>
                        {
                            showChildren ? (<MinusOutlined />) : (<PlusOutlined />)
                        }
                    </>
                ) : null}
                <span>{item.name}</span>
            </div>
            <ul className="tree-childrens">
                {showChildren && item.has_childrens && <Tree treeData={item.sub_categories} />}
            </ul>
        </>
    );
}

export default TreeNode;