import React, { useEffect, useState } from "react";
import { Alert, Card, Layout, Row } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../variables/appVariables";

const Verify = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (key) {
        verifyEmail(key);
    }
  }, [key]);

  const verifyEmail = async (key: string) => {
    try {
        const url = `${apiUrl}/sp/rest-auth/registration/verify-email/`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({key}),
        })
        if (!response.ok) {
            throw new Error('Ошибка запроса на верификацию');
        }
    } catch (e) {
        navigate('/login');
    } finally {
        setLoading(false);
    }
  };

  return (
    <Layout>
      <Row>
        <Card style={{ width: '100%', textAlign: 'center' }} loading={loading}>
          <Alert
            message="Электронный адрес подтвержден"
            description="Поздравляем, мы успешно подтвердили ваш адрес электронной почты"
            type="success"
            showIcon
          />

          <div style={{marginTop: 10}}>
            Теперь вы можете <Link to={"/login"}>войти в систему</Link>
          </div>
        </Card>
      </Row>
    </Layout>
  );
};

export default Verify;
