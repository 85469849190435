
export interface IRegState {
    error: string,
    is_loading: boolean,
    success: boolean,
}

export enum RegActionEnum {
    SET_ERROR = 'REG/SET_ERROR_REG',
    SET_IS_LOADING = 'REG/SET_IS_LOADING_REG',
    SET_SUCCESS = 'REG/SET_SUCCESS',
}

export interface ISetRegErrorAction {
    type: RegActionEnum.SET_ERROR,
    payload: string
}

export interface ISetRegIsLoadingAction {
    type: RegActionEnum.SET_IS_LOADING,
    payload: boolean
}

export interface ISetRegSuccessAction {
    type: RegActionEnum.SET_SUCCESS,
    payload: boolean
}


export type ActionRegType =
    ISetRegErrorAction |
    ISetRegIsLoadingAction |
    ISetRegSuccessAction