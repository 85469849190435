import {DetailsActionType, DetailsActionTypeEnum, IDetailsState} from "./types";

const initialState: IDetailsState = {
    details: [],
    loading: false,
    page: 1,
    total: 0,
    detailsCatalog: [],
    loadingCatalog: false,
    pageCatalog: 1,
    totalCatalog: 0,
}

export default function detailsReducer(state = initialState, action: DetailsActionType) {
    switch (action.type) {
        case DetailsActionTypeEnum.SET_DETAILS:
            return {...state, details: action.payload, loading: false};
        case DetailsActionTypeEnum.SET_LOADING:
            return {...state, loading: action.payload};
        case DetailsActionTypeEnum.SET_PAGE:
            return {...state, page: action.payload};
        case DetailsActionTypeEnum.SET_TOTAL:
            return {...state, total: action.payload};
        case DetailsActionTypeEnum.SET_DETAILS_CATALOG:
            return {...state, detailsCatalog: action.payload, loading: false};
        case DetailsActionTypeEnum.SET_LOADING_CATALOG:
            return {...state, loadingCatalog: action.payload};
        case DetailsActionTypeEnum.SET_PAGE_CATALOG:
            return {...state, pageCatalog: action.payload};
        case DetailsActionTypeEnum.SET_TOTAL_CATALOG:
            return {...state, totalCatalog: action.payload};
        default:
            return state;
    }
}