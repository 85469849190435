import React, {useEffect, useState} from 'react';
import {Layout, message, Row, Table, Tag, Tooltip, Input, Button, Space, Dropdown, Menu} from "antd";
import {getTableDimensions} from "../utils/getTableWidth";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../hooks/useTypedSelector";
import {CompaniesActionCreators} from "../store/reducers/companies/action-creators";
import {apiUrl} from "../variables/appVariables";
import {EditOutlined, EllipsisOutlined, PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import * as _ from 'lodash';
import {getFiltersTable} from '../utils/getFiltersTabe';
import DocModal from '../components/Documents/DocModal';
import SimpleTextFilter from '../components/TableFilters/SimpleTextFilter';
import DetailFilterInput from '../components/TableFilters/DetailFilterInput';
import DetailsInDocModal from '../components/Documents/DetailsInDocModal';
import DateFilter from '../components/Documents/DateFilter';

const {Search} = Input;

const Sells = () => {

    // table data
    const [documents, setDocuments] = useState([]);
    const [totalDocs, setTotalDocs] = useState(0);
    const [loadingDocs, setLoadingDocs] = useState(false);

    // table filters
    const [currentFilters, setCurrentFilters] = useState<any>({page: 1});

    // modal variables
    const [openModal, setOpenModal] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [options, setOptions] = useState({});

    const [openDetailsModal, setOpenDetailsModal] = useState(false);

    useEffect(() => {

        getDocuments();
        getDocsOptions();
    }, [])


    const getDocuments = async (params?: any) => {
        try {
            setLoadingDocs(true);
            const filters = params ? `?${new URLSearchParams(params).toString()}` : '';
            const url = `${apiUrl}/sp/document/${filters}`
            const res = await fetch(url, {
                headers: {
                    "Authorization": `Token ${localStorage.getItem('token')}`,
                    "Content-Type": 'application/json'
                },
            });
            const json = await res.json();
            setDocuments(json.results);
            setTotalDocs(json.count);
        } catch (e) {
            message.error(`Ошибка запроса: ${e.message}`);
        } finally {
            setLoadingDocs(false);
        }
    }

    const getDocsOptions = async () => {
        try {
            const url = `${apiUrl}/sp/document/`
            const res = await fetch(url, {
                method: 'OPTIONS',
                headers: {
                    "Authorization": `Token ${localStorage.getItem('token')}`,
                    "Content-Type": 'application/json'
                },
            });
            const json = await res.json();
            setOptions(json.actions.POST);
        } catch (e) {
            message.error(`Ошибка запроса: ${e.message}`);
        } finally {
            setLoadingDocs(false);
        }
    }

    const debouncedSearch = _.debounce(getDocuments, 500)

    const menu = (doc: any) => {
        return (
            <Menu style={{width: 'auto'}}>
                <Menu.Item
                    key={'edit'}
                    icon={<EditOutlined/>}
                    onClick={() => {
                        setSelectedDoc(doc);
                        setOpenModal(true);
                    }}
                >
                    Редактировать
                </Menu.Item>
                <Menu.Item
                    key={'details'}
                    icon={<PlusOutlined/>}
                    onClick={() => {
                        setSelectedDoc(doc);
                        setOpenDetailsModal(true);
                    }}
                >
                    Добавить детали
                </Menu.Item>
            </Menu>
        );
    };

    const columns: any = [
        {
            title: "",
            key: "action",
            width: 50,
            fixed: "left",
            render: (doc: any) => (
                <Space size="middle">
                    <Dropdown overlay={menu(doc)} trigger={["hover"]}>
                        <EllipsisOutlined style={{cursor: "pointer", fontSize: "24px"}}/>
                    </Dropdown>
                </Space>
            ),
        },
        {
            title: 'Операция',
            key: 'doc_type',
            dataIndex: 'doc_type',
            width: 200,
            ellipsis: {
                showTitle: false
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <SimpleTextFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={() => console.log("change filter")}
                        reset={() => console.log("reset")}
                        data={[
                            {value: "sale", title: "Продажа"},
                            {value: "cancellation", title: "Списание"},
                            {value: "disposal", title: "Утилизация"},
                        ]}
                    />
                );
            },
            filteredValue: currentFilters?.doc_type ? [currentFilters.doc_type] : null,
            filtered: currentFilters?.doc_type ? true : false,
            render: (doc_type: 'sale' | 'cancellation' | 'disposal') => (
                doc_type === 'sale' ? 'Продажа' : doc_type === 'cancellation' ? 'Списание' : 'Утилизация'
            )
        },
        {
            title: 'Продажа через',
            key: 'sales_method',
            width: 200,
            ellipsis: {
                showTitle: false
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <SimpleTextFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={() => console.log("change filter")}
                        reset={() => console.log("reset")}
                        data={[
                            {value: "partobo", title: "Partobo"},
                            {value: "drom", title: "Дром"},
                            {value: "avito", title: "Авито"},
                            {value: "other", title: "иное"},
                        ]}
                    />
                );
            },
            filteredValue: currentFilters?.sales_method ? [currentFilters.sales_method] : null,
            filtered: currentFilters?.sales_method ? true : false,
            render: (sell: any) => {
                return (
                    sell.sales_method === 'partobo' && sell.order ?
                        <Tooltip placement={'top'} title={`Заказ #${sell.order}`}>Partobo</Tooltip> : sell.sales_method === 'partob' && !sell.order ?
                        'Partobo' : sell.sales_method === 'drom' ?
                            'Дром' : sell.sales_method === 'avito' ?
                                'Авито' : 'иное'
                )
            }
        },
        {
            title: 'Продажа через (иное)',
            key: 'sales_other',
            dataIndex: 'sales_other',
            width: 250,
            ellipsis: {
                showTitle: false
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                                 visible,
                             }) => {
                return (
                    <DetailFilterInput
                        placeholder="Введите для поиска"
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        visible={visible}
                    />
                );
            },
            filteredValue: currentFilters?.sales_other ? [currentFilters.sales_other] : null,
            filtered: currentFilters?.sales_other ? true : false,
            filterIcon: (filtered) => (
                <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
            ),
        },
        {
            title: 'Статус проводки',
            key: 'transacted',
            dataIndex: 'transacted',
            width: 200,
            ellipsis: {
                showTitle: false
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <SimpleTextFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={() => console.log("change filter")}
                        reset={() => console.log("reset")}
                        data={[
                            {value: "true", title: "проведена"},
                            {value: "false", title: "не проведена"},
                        ]}
                    />
                );
            },
            filteredValue: currentFilters?.transacted ? [currentFilters.transacted] : null,
            filtered: currentFilters?.transacted ? true : false,
            render: (transacted: boolean) => transacted ? 'проведена' : 'не проведена'
        },
        {
            title: 'Номер документа',
            key: 'document_number',
            dataIndex: 'document_number',
            width: 150,
            ellipsis: {
                showTitle: false
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                                 visible,
                             }) => {
                return (
                    <DetailFilterInput
                        placeholder="Введите для поиска"
                        clearFilters={clearFilters}
                        confirm={confirm}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        visible={visible}
                    />
                );
            },
            filteredValue: currentFilters?.document_number ? [currentFilters.document_number] : null,
            filtered: currentFilters?.document_number ? true : false,
            filterIcon: (filtered) => (
                <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
            ),
        },
        {
            title: 'Дата документа',
            key: 'document_date',
            dataIndex: 'document_date',
            width: 150,
            ellipsis: {
                showTitle: false
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <DateFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={() => console.log("change filter")}
                        reset={() => console.log("reset")}
                    />
                );
            },
            filteredValue: currentFilters?.document_date ? [currentFilters.document_date] : null,
            filtered: currentFilters?.document_date ? true : false,
            render: (document_date: string) => (document_date ? Intl.DateTimeFormat('ru').format(new Date(document_date)) : '-')
        },
        {
            title: 'Компания',
            key: 'company',
            dataIndex: 'company',
            width: 150,
        },
        {
            title: 'Дата создания',
            key: 'date_add',
            dataIndex: 'date_add',
            width: 150,
            ellipsis: {
                showTitle: false
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <DateFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={() => console.log("change filter")}
                        reset={() => console.log("reset")}
                    />
                );
            },
            filteredValue: currentFilters?.date_add ? [currentFilters.date_add] : null,
            filtered: currentFilters?.date_add ? true : false,
            render: (date_add: string) => (date_add ? Intl.DateTimeFormat('ru').format(new Date(date_add)) : '-')
        },
        {
            title: 'Дата изменения',
            key: 'date_change',
            dataIndex: 'date_change',
            width: 150,
            ellipsis: {
                showTitle: false
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <DateFilter
                        clearFilters={clearFilters}
                        confirm={confirm}
                        setSelectedKeys={setSelectedKeys}
                        handleChange={() => console.log("change filter")}
                        reset={() => console.log("reset")}
                    />
                );
            },
            filteredValue: currentFilters?.date_change ? [currentFilters.date_change] : null,
            filtered: currentFilters?.date_change ? true : false,
            render: (date_change: string) => (date_change ? Intl.DateTimeFormat('ru').format(new Date(date_change)) : '-')
        },
    ];

    const handleTableChange = async (
        pagination: any,
        filtersTable: any,
    ) => {
        let customFilters = {...filtersTable};
        let filters = getFiltersTable(pagination, customFilters);
        setCurrentFilters(filters);
        getDocuments(filters);
    };

    return (
        <Layout>
            <Row>
                {/* documents table */}
                <Table
                    onChange={handleTableChange}
                    scroll={{x: getTableDimensions(columns), y: 500}}
                    columns={columns}
                    dataSource={documents}
                    loading={loadingDocs}
                    pagination={{
                        total: totalDocs,
                        pageSize: 5,
                        showSizeChanger: false,
                    }}
                    rowClassName={(record, index) => {
                        let className = [];
                        record?.transacted && className.push('table-row-transacted');
                        if (className.length > 0) {
                            return className.join(' ');
                        }
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: (event) => {
                                setOpenModal(true);
                                setSelectedDoc(record);
                            }
                        }
                    }}
                    title={() => (
                        <div style={{display: 'flex'}}>
                            <Search placeholder="Поиск документа"
                                    enterButton
                                    onChange={(e) => {
                                        debouncedSearch({search: e.target.value})
                                    }}/>

                            <Tooltip title={'Обновить'}>
                                <Button
                                    style={{margin: '0 10px'}}
                                    onClick={() => getDocuments(currentFilters)}
                                    type="text"
                                    icon={<ReloadOutlined/>}/>
                            </Tooltip>
                            <Button
                                type="ghost"
                                icon={<PlusOutlined/>}
                                onClick={() => setOpenModal(true)}
                            >
                                Создать
                            </Button>
                        </div>
                    )}
                />

                {/* document add modal */}
                <DocModal
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                        setSelectedDoc(null);
                        getDocuments(currentFilters);
                    }}
                    options={options}
                    data={selectedDoc}
                />

                {/* add details to document modal */}
                <DetailsInDocModal
                    open={openDetailsModal}
                    onClose={() => setOpenDetailsModal(false)}
                    document={selectedDoc}
                />
            </Row>
        </Layout>
    );
};

export default Sells;
