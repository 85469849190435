import React, {FC, ReactElement, useEffect} from 'react';
import {Layout, Menu} from "antd";
import {
    DollarCircleOutlined,
    FileDoneOutlined,
    FrownOutlined,
    RobotOutlined,
    SettingOutlined,
    ShopOutlined,
    UsergroupAddOutlined,
    CommentOutlined,
    FileAddOutlined,
} from "@ant-design/icons/lib";
import Navbar from "./Navbar";
import Company from "../pages/Company";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../hooks/useTypedSelector";
import {TabActionCreators} from "../store/reducers/tabs/action-creators";
import TabsComponent from "./TabsComponent";
import 'react-phone-input-2/lib/style.css';
import StartWork from "./StartWork";
import InvoicesPage from "../pages/InvoicesPage";
import TelegramBot from "../pages/TelegramBot";
import StaffUsers from "../pages/StaffUsers";
import Disputes from "../pages/Disputes";
import Sells from "../pages/Sells";
import Orders from "../pages/Orders";
import CatalogDetails from "../pages/CatalogDetails";
import Chat from "./Chat";
import Reviews from "../pages/Reviews";
import UnloadingPage from '../pages/UnloadingPage';
import Stocks from '../pages/Stocks';

export interface IMenuItems {
    icon?: ReactElement,
    title: string,
    navigate: () => void
}


const AuthLayout: FC = () => {
    const dispatch = useDispatch();
    const {tabs, activeKey} = useTypedSelector(state => state.tabs);

    useEffect(() => {
        if (tabs?.length === 0) {
            dispatch(TabActionCreators.setActiveKey(''))
        }
    }, [tabs])

    function addTab(title: string, content: ReactElement) {
        const tab = {title, content, key: title};
        const isExist = tabs.findIndex(value => value.title === tab.title);
        if (isExist < 0) {
            dispatch(TabActionCreators.setTabs([...tabs, tab]))
        }

        dispatch(TabActionCreators.setActiveKey(title))
    }

    const menuItems: IMenuItems[] = [
        {
            icon: <ShopOutlined/>,
            title: 'Компании',
            navigate: () => addTab('Компании', <Company/>)
        },
        {
            icon: <ShopOutlined />,
            title: 'Склады',
            navigate: () => addTab('Склады', <Stocks/>)
        },
        {
            icon: <FileAddOutlined />,
            title: 'Выгрузка',
            navigate: () => addTab('Выгрузка', <UnloadingPage/>)
        },
        {
            icon: <FileDoneOutlined/>,
            title: 'Накладные',
            navigate: () => addTab('Накладные', <InvoicesPage/>)
        },
        {
            icon: <SettingOutlined/>,
            title: 'Каталог запчастей',
            navigate: () => addTab('Каталог запчастей', <CatalogDetails/>)
        },

        {
            icon: <DollarCircleOutlined />,
            title: 'Заказы',
            navigate: () => addTab('Заказы', <Orders/>)
        },
        {
            icon: <DollarCircleOutlined />,
            title: 'Реализация',
            navigate: () => addTab('Реализация', <Sells/>)
        },
        {
            icon: <FrownOutlined />,
            title: 'Спорные заказы',
            navigate: () => addTab('Спорные заказы', <Disputes/>)
        },
        {
            icon: <CommentOutlined />,
            title: 'Отзывы',
            navigate: () => addTab('Отзывы', <Reviews/>)
        },
        {
            icon: <UsergroupAddOutlined />,
            title: 'Сотрудники',
            navigate: () => addTab('Сотрудники', <StaffUsers/>)
        },
        {
            icon: <RobotOutlined/>,
            title: 'Телеграм БОТ',
            navigate: () => addTab('Телеграм БОТ', <TelegramBot/>)
        },
    ]

    return (
        <>
            <Layout.Sider
                breakpoint="lg"
                collapsedWidth="0"
            >
                <div className={'logo'}></div>
                <Menu theme="dark" mode="inline" selectedKeys={[activeKey]}>
                    {menuItems.map((item, idx) => {
                        return (
                            <Menu.Item key={item.title} icon={item.icon} onClick={item.navigate}>
                                {item.title}
                            </Menu.Item>
                        )
                    })}
                </Menu>
            </Layout.Sider>
            <Layout.Content style={{minHeight: '100vh'}}>
                <Navbar/>
                <div style={{margin: '24px 16px 0'}}>
                    <div className="site-layout-background" style={{padding: 24}}>
                        {
                            tabs?.length > 0 ?
                                <TabsComponent/> :
                                <StartWork addTab={() => addTab('Компании', <Company/>)}/>
                        }
                    </div>
                    <Chat/>
                </div>
            </Layout.Content>
        </>
    );
};

export default AuthLayout;
