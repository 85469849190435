import { Button, Space } from "antd";
import { useState } from "react";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";

const DateFilter = ({
    setSelectedKeys,
    confirm,
    clearFilters,
    handleChange,
    reset
}) => {
    const [value, setValue] = useState(() => moment().format('YYYY-MM-DD'));

    return (
        <div style={{ padding: 8 }}>
            <div style={{ display: 'flex', marginBottom: 10 }}>
                <input
                    value={value}
                    className={'ant-input'}
                    type={'date'}
                    onChange={event => {
                        setValue(event.target.value);
                        setSelectedKeys(event.target ? [event.target.value] : []);
                    }}
                />
            </div>
            <Space>
                <Button
                    type="primary"
                    onClick={() => confirm({ closeDropdown: false })}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Найти
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys([]);
                        confirm({ closeDropdown: true });
                        setValue(null);
                        reset && reset();
                    }}
                    size="small"
                    style={{ width: 90 }}
                >
                    Сбросить
                </Button>
            </Space>
        </div>
    )
}

export default DateFilter;