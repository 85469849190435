import {AuthActionEnum} from "../auth/types";

export interface IChatState {
    selectedDialog: any
}

export enum ChatActionEnum {
    SET_DIALOG = 'CHAT/SET_DIALOG'
}

export interface ISetDialogAction {
    type: ChatActionEnum.SET_DIALOG,
    payload: any
}

export type ChatAction = ISetDialogAction