import { useTypedSelector } from "../../../hooks/useTypedSelector";
import FoundedCarsListItem from "./FoundedCarsListItem";

const FoundedCarsList = () => {
    const { foundedCars } = useTypedSelector(state => state.laximo);

    return(
        <div className="founded-cars-list">
            {foundedCars.map(item => <FoundedCarsListItem key={item.ssd} item={item} />)}
        </div>
    )
}

export default FoundedCarsList;