import {ReactElement} from "react";

export interface ITab {
    title: string,
    content: ReactElement,
    key: string,
}

export interface TabActionState {
    tabs: ITab[],
    activeKey: string
}

export enum TabActionEnum {
    SET_TABS = 'SET_TABS',
    SET_ACTIVE_KEY = 'SET_ACTIVE_KEY_TAB',
}

export interface ISetActionTab {
    type: TabActionEnum.SET_TABS,
    payload: ITab[]
}

export interface ISetActionActiveKey {
    type: TabActionEnum.SET_ACTIVE_KEY,
    payload: string
}

export type TabActionType =
    ISetActionTab |
    ISetActionActiveKey