import { message, Spin } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Laximo } from "../../../api/laximo";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { LaximoActionCreators } from "../../../store/reducers/laximo/action-creators";
import CarsCatalog from "./CarsCatalog";
import CarsParams from "./CarsParams";

const NoVinSearch = ({handleClose}) => {
    const dispatch = useDispatch();
    const { carsCatalog, selectedCarsCatalog, loadingCarsCatalog, loadingAutoParams, autoParams } = useTypedSelector(state => state.laximo);

    useEffect(() => {
        if (carsCatalog.length === 0) {
            dispatch(LaximoActionCreators.setLoadingCarsCatalog(true));
            Laximo.getCarCatalog()
                .then(res => {
                    dispatch(LaximoActionCreators.setCarsCatalog(res))
                })
                .catch(e => message.error(e))
                .finally(() => dispatch(LaximoActionCreators.setLoadingCarsCatalog(false)));
        }
    }, []);

    return (
        <Spin spinning={loadingCarsCatalog || loadingAutoParams}>
            {!selectedCarsCatalog && <CarsCatalog cars={carsCatalog} />}
            {selectedCarsCatalog && autoParams.length > 0 && <CarsParams params={autoParams} handleClose={handleClose} />}
        </Spin>
    );
}

export default NoVinSearch;