import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {IRoute, privateRoutes, publicRoutes, RouteNames} from "../router";
import {useTypedSelector} from "../hooks/useTypedSelector";

const AppRouter = () => {
   const {isAuth} = useTypedSelector(state => state.auth)

    return (
        isAuth ? (
            <div style={{margin: '24px 16px 0'}}>
                <div className="site-layout-background" style={{padding: 24}}>
                    <Routes>
                        {
                            privateRoutes.map((route: IRoute) => {
                                return (
                                    <Route
                                        key={route.path}
                                        {...route}
                                    />
                                )
                            })
                        }
                        <Route path="*" element={<Navigate to={'/'}/>}/>
                    </Routes>
                </div>
            </div>
        ) : (
            <div style={{margin: '24px 16px 0'}}>
                <div className="site-layout-background" style={{padding: 24}}>
                    <Routes>
                        {
                            publicRoutes.map((route: IRoute) => {
                                return (
                                    <Route
                                        key={route.path}
                                        {...route}
                                    />
                                )
                            })
                        }
                        <Route path="*" element={<Navigate to={RouteNames.LOGIN}/>}/>
                    </Routes>
                </div>
            </div>

        )

    );
};

export default AppRouter;