import React, {Suspense, lazy} from 'react';
import {Layout, Row, Spin} from "antd";
import {useTypedSelector} from "../hooks/useTypedSelector";

const DetailsTable = lazy(() => import("../components/DetailsTable"))

const CatalogDetails = () => {
    const {loadingCatalog, detailsCatalog, totalCatalog} = useTypedSelector(state => state.details);

    return (
        <Layout>
            <Row>
                <Suspense fallback={<Spin/>}>
                    <h1 style={{display: 'block', width: '100%', marginBottom: 8}}>
                        Каталог запчастей
                    </h1>
                    <DetailsTable
                        loading={loadingCatalog}
                        data={detailsCatalog}
                        total={totalCatalog}
                        company={true}
                    />
                </Suspense>
            </Row>
        </Layout>
    );
};

export default CatalogDetails;