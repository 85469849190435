import React, {useEffect, useState} from 'react';
import {Layout, Menu, Row} from "antd";
import {useLocation, useNavigate} from 'react-router-dom'
import {RouteNames} from "../router";
import {useTypedSelector} from "../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {AuthActionCreators} from "../store/reducers/auth/action-creators";

const Navbar = () => {
    const dispatch = useDispatch()
    const {isAuth, user} = useTypedSelector(state => state.auth)
    const navigate = useNavigate()
    const location = useLocation();
    const [selectedMenuItem, setSelectedMenuItem] = useState('1')

    useEffect(() => {
        if (!isAuth && location.pathname) {
            handleCheckPath(location.pathname)
        }
    }, [isAuth, location.pathname])

    const logout = () => {
        dispatch(AuthActionCreators.logout())
    }

    const handleCheckPath = (path: string): void => {
        switch (path) {
            case RouteNames.LOGIN:
                setSelectedMenuItem('1');
                return;
            case RouteNames.REGISTRATION:
                setSelectedMenuItem('2');
                return;
            default:
                setSelectedMenuItem('1')
                return;
        }
    }

    return (
        <Layout.Header>
            {
                isAuth ? (
                    <Row justify={'end'}>
                        <div style={{display: 'flex'}}>
                            <div style={{color: '#fff', marginRight: '10px'}}>
                                {user?.first_name || user?.username || 'error'}
                            </div>
                            <Menu theme="dark" mode="horizontal" style={{width: '100%', justifyContent: ' right'}}>
                                <Menu.Item key="1" onClick={logout}>
                                    Выйти
                                </Menu.Item>
                            </Menu>
                        </div>
                    </Row>
                ) : (
                    <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <div className="logo"></div>
                        <Menu
                            selectedKeys={[selectedMenuItem]}
                            theme="dark"
                            mode="horizontal"
                            style={{width: '100%', justifyContent: ' right'}}
                        >
                            <Menu.Item key="1" onClick={() => navigate(RouteNames.LOGIN)}>
                                Войти
                            </Menu.Item>
                            <Menu.Item key="2" onClick={() => navigate(RouteNames.REGISTRATION)}>
                                Регистрация
                            </Menu.Item>
                        </Menu>
                    </div>
                )
            }

        </Layout.Header>
    );
};

export default Navbar;