import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Button, Layout, message, Row, Spin } from "antd";
// import StaffTable from "../components/Company/StaffTable";
import { useDispatch } from "react-redux";
import { Companies } from "../api/companies";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { CompaniesActionCreators } from "../store/reducers/companies/action-creators";
import StaffModal from '../components/Company/StaffModal';
// import SelectWithPagination from "../components/SelectWithPagination";
// import StaffEditModal from "../components/Company/StaffEditModal";

const StaffEditModal = lazy(() => import("../components/Company/StaffEditModal"));
const SelectWithPagination = lazy(() => import("../components/SelectWithPagination"));
const StaffTable = lazy(() => import("../components/Company/StaffTable"));

const StaffUsers = () => {
    const dispatch = useDispatch();
    const { companies, total, page } = useTypedSelector(state => state.companies);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [staff, setStaff] = useState([]);
    const [visibleEditStaffModal, setVisibleEditStaffModal] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState(null);

    //add modal staff
    const [openStaffModal, setOpenStaffModal] = useState(false);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        if (companies.length === 0) {
            dispatch(CompaniesActionCreators.getCompanies(1));
        }

        Companies.getRolesStaffUser()
            .then(res => setRoles(res))
            .catch((e) => {
                message.error(e);
                setRoles([]);
            })

    }, [])

    useEffect(() => {
        if (selectedCompany) {
            Companies.getCompanyById(selectedCompany).then(res => {
                const staffUsers = res.staff_users;
                setStaff(staffUsers);
            }).catch(e => message.error(`Ошибка получения информации по компании`))
        } else {
            setStaff([])
        }
    }, [selectedCompany])

    return (
        <Layout>
            <Row>
                <Suspense fallback={<Spin />}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 10 }}>
                        <SelectWithPagination
                            array={companies}
                            total={total}
                            handleChange={value => {
                                setSelectedCompany(value);
                            }}
                            pageSize={5}
                            style={{ width: 250 }}
                            pageC={page}
                            handleChangePage={(page) => dispatch(CompaniesActionCreators.getCompanies(page))}
                        />
                        <Button
                            disabled={!selectedCompany}
                            type='primary'
                            onClick={() => setOpenStaffModal(true)}
                        >
                            + Добавить
                        </Button>
                    </div>

                    <StaffTable
                        staff={staff}
                        selectStaff={(staff) => {
                            Companies.getStaffUser(staff.id)
                                .then(res => {
                                    setSelectedStaff(res);
                                    setVisibleEditStaffModal(true);
                                })
                                .catch(e => {
                                    message.error(e)
                                })
                        }}
                    />
                    <StaffEditModal visible={visibleEditStaffModal}
                        company={selectedCompany}
                        data={selectedStaff}
                        handleCancel={() => {
                            Companies.getCompanyById(selectedCompany).then(res => {
                                const staffUsers = res.staff_users;
                                setStaff(staffUsers);
                            }).catch(e => message.error(`Ошибка получения информации по компании`))
                            setVisibleEditStaffModal(false)
                        }}
                        handleChangeStaff={(staffItem) => setStaff([...staff, staffItem])}
                    />
                </Suspense>
            </Row>

            {/* add staff modal */}
            <StaffModal
                visible={openStaffModal}
                company={selectedCompany}
                handleCancel={() => setOpenStaffModal(false)}
                updateCompanyInfo={() => {
                    Companies.getCompanyById(selectedCompany).then(res => {
                        const staffUsers = res.staff_users;
                        setStaff(staffUsers);
                    }).catch(e => message.error(`Ошибка получения информации по компании`))
                }}
                roles={roles}
            />
        </Layout>
    );
};

export default StaffUsers;