import React, {FC, useEffect, useState} from 'react';
import {message, Modal, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons/lib";
import {Detail} from "../api/detail";

const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

interface IComponentProps {
    handleChangeFileList: (fileList: any) => void,
    detailPhotos: any[],
    detail?: any
}

const PhotoUpload: FC<IComponentProps> = ({handleChangeFileList, detailPhotos, detail}) => {
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false);

    useEffect(() => {
        if (detailPhotos) {
            const prepareFileList: any = detailPhotos.map(photo => {
                return {
                    uid: photo.id,
                    name: getPhotoName(photo.photo),
                    status: 'done',
                    url: photo.photo,
                }
            })

            setFileList(prepareFileList)
        }
    }, [detailPhotos])

    const getPhotoName = (url: string) => {
        const urlArrItems = url.split('/');
        return urlArrItems[urlArrItems.length - 1];
    }

    const handlePreview = async (file: any) => {
        const preview: any = file.url ? file.url :
            await getBase64(file.originFileObj);
        setPreviewImage(preview);
        setPreviewVisible(true);
    }

    const handleChange = ({fileList}: any) => {
        setFileList(fileList);
        handleChangeFileList(fileList)
    }

    const handleRemoveFile = (file: any) => {
        const newFileList = fileList.filter((item: any) => item.uid !== file.uid)
        setFileList(newFileList);
        handleChangeFileList(newFileList);

        if (!file.originFileObj) {
            Detail.deletePhotoDetail(file.uid)
                .catch(e => message.error('Ошибка запроса на удаление картинки'))
        }
    }

    return (
        <>
            <Upload
                multiple={true}
                accept=".jpg,.jpeg,.png"
                beforeUpload={() => false}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={handleRemoveFile}
            >
                {fileList.length >= 8 ? null : <div>
                    <PlusOutlined/>
                    <div style={{marginTop: 8}}>Загрузить</div>
                </div>}
            </Upload>
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </>
    );
};

export default PhotoUpload;