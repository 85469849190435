import axios from "axios";
import { apiUrl } from "../variables/appVariables";

export class Companies {
  //companies crud
  public static async getCompanies(page = 1, search?: string) {
    try {
      const url = `${apiUrl}/sp/company/?page=${page}${
        search ? `&search=${search}` : ""
      }`;
      const res = await axios.get(url, {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      });

      if (res?.data?.detail) {
        return Promise.reject(res.data.detail);
      }

      return res.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async getCompanyById(id: number) {
    try {
      const url = `${apiUrl}/sp/company/${id}/`;
      const res = await axios.get(url, {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      });
      const json = await res.data;

      if (json.detail) {
        return Promise.reject(json.detail);
      }

      return json;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async addCompany(body: any) {
    try {
      const url = `${apiUrl}/sp/company/`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const json = await res.json();

      if (json.detail) {
        return Promise.reject(json.detail);
      }

      if (!res.ok) {
        return Promise.reject(JSON.stringify(json));
      }
      return json;
    } catch (e) {
      return Promise.reject(`Ошибка запроса на сервер, ${e}`);
    }
  }

  public static async editCompany(id: number, body: any) {
    try {
      const url = `${apiUrl}/sp/company/${id}/`;
      const res = await axios.patch(url, body, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      const json = await res.data;

      if (json.detail) {
        return Promise.reject(json.detail);
      }

      return json;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async deleteCompany(id: number) {
    try {
      const url = `${apiUrl}/sp/company/${id}/`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      const json = await res.json();

      if (json.detail) {
        return Promise.reject(json.detail);
      }

      return json;
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер!");
    }
  }

  public static async getCompaniesUploadType() {
    try {
      const url = `${apiUrl}/sp/company`;
      const res = await fetch(url, {
        method: "OPTIONS",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      const json = await res.json();

      if (json?.actions?.POST?.uploading_type?.choices) {
        return json.actions?.POST.uploading_type.choices;
      } else {
        return Promise.reject("Данные по типу загрузки файла отсутствуют");
      }
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер");
    }
  }

  //end companies crud

  //stocks crud
  public static async addStock(body: any) {
    try {
      const url = `${apiUrl}/sp/stock/`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const json = await res.json();

      if (Array.isArray(json?.company)) {
        return Promise.reject(json);
      }
      return json;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async editStock(id: number, body: any) {
    try {
      const url = `${apiUrl}/sp/stock/${id}/`;
      const res = await axios.patch(url, body, {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      });
      const json = await res.data;
      return json;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async deleteStock(id: number) {
    try {
      const url = `${apiUrl}/sp/stock/${id}/`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      const json = await res.json();

      if (json.detail) {
        return Promise.reject(json.detail);
      }

      return json;
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер!");
    }
  }

  //end stocks crud

  //staff_users crud
  public static async getRolesStaffUser() {
    try {
      const url = `${apiUrl}/sp/registration/`;
      const res = await fetch(url, {
        method: "OPTIONS",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();

      if (json.detail) {
        return Promise.reject(json.detail);
      }

      return json.role;
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер");
    }
  }

  public static async addStaffUser(body: any) {
    try {
      const url = `${apiUrl}/sp/registration/`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const json = await res.json();

      if (json.error) {
        return Promise.reject(json.error);
      }
      return json;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async editStaffUser(id: number, body: any) {
    try {
      const url = `${apiUrl}/sp/staff/${id}/`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const json = await res.json();

      if (json.error) {
        return Promise.reject(json.error);
      }
      return json;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async getStaffUser(id: number) {
    try {
      const url = `${apiUrl}/sp/staff/${id}/`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();

      if (json.error) {
        return Promise.reject(json.error);
      }
      return json;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  //end staff_users crud

  //change company price list
  public static async uploadFilePrice(id: number, data: any) {
    try {
      const res = await axios.patch(`${apiUrl}/sp/company/${id}/`, data, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": `multipart/form-data`,
        },
      });

      return res.data;
      // const formData = new FormData();
      // formData.append('upload_file', upload_file.originFileObj);
      // console.log(upload_file);
      // const url = `${apiUrl}/sp/company/${id}/`;
      // const res = await fetch(url, {
      //     method: 'PATCH',
      //     headers: {
      //         Authorization: `${localStorage.getItem('token')}`,
      //         // 'Content-Type': `multipart/form-data`,
      //     },
      //     body: formData,
      // });

      // const json = await res.json();

      // if (res.ok) {
      //     return Promise.resolve(json);
      // } else {
      //     const err = json.upload_file.join(', ');
      //     return Promise.reject(err);
      // }
    } catch (e) {
      return Promise.reject(e);
    }
  }
  //end change company price list

  //transport companies
  public static async getTransportCompanies() {
    try {
      const url = `${apiUrl}/sp/delivery_companies/`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        return Promise.resolve(json);
      } else {
        return Promise.reject("Ошибка запроса на получение списка ТК");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
