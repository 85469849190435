import React, {Suspense, lazy} from 'react';
import {Layout, Row, Spin} from "antd";

const CompanyTable = lazy(() => import('../components/Company/CompanyTable'));

const Company = () => {
    return (
        <Layout>
            <Row>
                <Suspense fallback={<Spin/>}>
                    <CompanyTable/>
                </Suspense>
            </Row>
        </Layout>
    );
};

export default Company;