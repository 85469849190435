import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  InputNumber,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { getTableDimensions } from "../utils/getTableWidth";
import { useDispatch } from "react-redux";
import { DetailsActionCreators } from "../store/reducers/details/action-creators";
import DetailModal from "./Invoice/DetailModal";
import { useTypedSelector } from "../hooks/useTypedSelector";
import {
  EditOutlined,
  EllipsisOutlined,
  DollarCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons/lib";
import { abortController, Detail } from "../api/detail";
import DetailFilterInput from "./TableFilters/DetailFilterInput";
import { getFiltersTable } from "../utils/getFiltersTabe";
import DetailFilterSelect from "./TableFilters/DetailFilterSelect";
import SelectWithPagination from "./SelectWithPagination";
import { CompaniesActionCreators } from "../store/reducers/companies/action-creators";
import { currDateLocalString } from "../pages/Disputes";
import DetailBrandFilter from "./TableFilters/DetailBrandFilter";
import DetailModelFilter from "./TableFilters/DetailModelFilter";
import PriceFilter from "./TableFilters/PriceFilter";
import SimpleTextFilter from "./TableFilters/SimpleTextFilter";

export const getRealizationType = (type) => {
  switch (type) {
    case "sell":
      return "Продажа";
    case "cancellation":
      return "Списание";
    case "disposal":
      return "Утиль";
    default:
      return type;
  }
};

export const getSellsType = (type) => {
  switch (type) {
    case "trade-12":
      return "ТОРГ-12";
    case "upd":
      return "УПД";
    case "pr_check":
      return "Товарный чек";
    default:
      return type;
  }
};

interface IComponentProps {
  loading: boolean;
  data: any[];
  total: number;
  invoice?: any;
  company?: any;
  detailCategories?: any[];
}

const DetailsTable: FC<IComponentProps> = ({
  loading,
  data,
  company,
  total,
  invoice,
}) => {
  const dispatch = useDispatch();

  const [visibleDetailModal, setVisibleDetailModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  const [details, setDetails] = useState([]);
  const [totalDetails, setTotalDetails] = useState(0);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);

  const [currentFilters, setCurrentFilters] = useState<any>({});

  const {
    companies,
    total: totalCompanies,
    page: pageCompanies,
  } = useTypedSelector((state) => state.companies);
  const [selectedCompany, setSelectedCompany] = useState(null);

  //percent increase price
  const [percentPrice, setPercentPrice] = useState(10);
  const [isGte, setIsGte] = useState(true);

  useEffect(() => {
    if (abortController?.signal && !abortController?.signal?.aborted) {
      dispatch(DetailsActionCreators.setLoadingCatalog(true));
    }
  }, [abortController]);

  useEffect(() => {
    Detail.getDetailsCategories()
      .then((res) => res?.results && setCategories(res.results))
      .catch((e) => {
        message.error(`${e}, не удалось получить список категорий запчастей`);
      });

    Detail.getBrandsModelsList()
      .then((res) => {
        setBrands(res);
      })
      .catch((e) => {
        message.error(`${e}, не удалось получить список категорий запчастей`);
      });

      dispatch(DetailsActionCreators.getDetailsCatalog({}));
  }, []);

  useEffect(() => {
    if (company && companies.length === 0) {
      dispatch(CompaniesActionCreators.getCompanies(1));
    }
  }, [company]);

  const menu = (detail: any) => {
    return (
      <Menu style={{ width: "auto" }}>
        <Menu.Item
          key={"edit"}
          icon={<EditOutlined />}
          onClick={() => {
            setSelectedDetail(detail);
            setVisibleDetailModal(true);
          }}
        >
          Редактировать
        </Menu.Item>

        <Menu.Item key={"sold"} icon={<DollarCircleOutlined />}>
          <Popconfirm
            title={`Вы действительно хотите ${
              detail?.sells
                ? "вернуть деталь в каталог"
                : "снять позицию с продажи"
            }?`}
            onConfirm={() => {
              const formData = new FormData();
              detail?.sells
                ? formData.append("sells", "false")
                : formData.append("sells", "true");
              Detail.editDetailInvoice(detail.id, formData)
                .then((res) => {
                  invoice &&
                    dispatch(
                      DetailsActionCreators.getDetailsByInvoice({
                        invoice: invoice?.id,
                        ...currentFilters,
                      })
                    );
                  detail?.sells
                    ? message.success("Деталь успешно возвращена в каталог!")
                    : message.success("Деталь успешно снята с продажи!");
                  dispatch(
                    DetailsActionCreators.getDetailsCatalog({
                      ...currentFilters,
                      company: selectedCompany,
                    })
                  );
                })
                .catch((e) => message.error(e));
            }}
            okText="Да"
            cancelText="Нет"
          >
            {detail?.sells ? "Вернуть в каталог" : "Снять с продажи"}
          </Popconfirm>
        </Menu.Item>
        <Menu.Item key={"delete"} icon={<DeleteOutlined />}>
          <Popconfirm
            title="Вы действительно хотите удалить деталь?"
            onConfirm={() => {
              Detail.deleteDetail(detail.id)
                .then((res) => {
                  invoice &&
                    dispatch(
                      DetailsActionCreators.getDetailsByInvoice({
                        invoice: invoice?.id,
                        ...currentFilters,
                      })
                    );
                  message.success("Деталь успешно удалена!");
                })
                .catch((e) => message.error(e));
            }}
            okText="Да"
            cancelText="Нет"
          >
            Удалить
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const columns: any = [
    {
      title: "",
      key: "action",
      width: 50,
      fixed: "left",
      render: (detail: any) => (
        <Space size="middle">
          <Dropdown overlay={menu(detail)} trigger={["click"]}>
            <EllipsisOutlined style={{ cursor: "pointer", fontSize: "24px" }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: "Статус",
      dataIndex: "sells",
      key: "sells",
      width: 100,
      render: (tags: boolean) => (
        <>
          {tags ? (
            <Tag color={"volcano"}>Продано</Tag>
          ) : (
            <Tag color={"green"}>В наличии</Tag>
          )}
        </>
      ),
    },
    {
      title: "Артикул",
      dataIndex: "shop_code_number",
      key: "shop_code_number",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => {
        return (
          <DetailFilterInput
            clearFilters={clearFilters}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            visible={visible}
          />
        );
      },
      filteredValue: currentFilters?.shop_code_number
        ? [currentFilters.shop_code_number]
        : null,
      filtered: currentFilters?.shop_code_number ? true : false,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "OEM",
      dataIndex: "oem",
      key: "oem",
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => {
        return (
          <DetailFilterInput
            clearFilters={clearFilters}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            visible={visible}
          />
        );
      },
      filteredValue: currentFilters?.oem ? [currentFilters.oem] : null,
      filtered: currentFilters?.oem ? true : false,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      render: (text: any) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Категория",
      dataIndex: "part",
      key: "category",
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => {
        return (
          <DetailFilterSelect
            clearFilters={clearFilters}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            data={categories}
            reset={() => {
              setDetails([]);
              setTotalDetails(0);
            }}
            handleChange={handleChangeCategory}
          />
        );
      },
      filteredValue: currentFilters?.category
        ? [currentFilters.category]
        : null,
      filtered: currentFilters?.category ? true : false,
      render: (part: any) =>
        part?.category ? (
          <Tooltip placement="topLeft" title={part.category}>
            {part.category}
          </Tooltip>
        ) : null,
    },
    {
      title: "Наименование",
      dataIndex: "part",
      key: "title",
      ellipsis: {
        showTitle: false,
      },
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => {
        return (
          <DetailFilterSelect
            clearFilters={clearFilters}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            pagination={true}
            data={details}
            total={totalDetails}
            handleChange={() => console.log("123")}
            // handleChangePage={handleChangePageDetails}
          />
        );
      },
      filteredValue: currentFilters?.title ? [currentFilters.title] : null,
      filtered: currentFilters?.title ? true : false,
      render: (part: any) =>
        part?.title ? (
          <Tooltip placement="topLeft" title={part.title}>
            {part.title}
          </Tooltip>
        ) : null,
    },
    {
      title: "Марка",
      dataIndex: "brand",
      key: "brand",
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => {
        return (
          <DetailBrandFilter
            clearFilters={clearFilters}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            data={brands}
            reset={() => {
              setModels([]);
            }}
            handleChange={handleChangeBrand}
          />
        );
        // <DetailFilterSelect
        //     clearFilters={clearFilters}
        //     confirm={confirm}
        //     selectedKeys={selectedKeys}
        //     setSelectedKeys={setSelectedKeys}
        //     pagination={true}
        //     data={brands}
        //     total={totalBrands}
        //     reset={() => {
        //         setModels([]);
        //     }
        //     }
        //     handleChange={handleChangeBrand}
        // // handleChangePage={handleChangePageBrands}
        // />
      },
      filteredValue: currentFilters?.brand ? [currentFilters.brand] : null,
      filtered: currentFilters?.brand ? true : false,
      render: (text: any) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Модель",
      dataIndex: "model",
      key: "model",
      ellipsis: {
        showTitle: false,
      },
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => {
        return (
          <DetailModelFilter
            clearFilters={clearFilters}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            data={models}
            handleChange={() => console.log("123")}
            reset={() => console.log("reset")}
          />
        );
      },
      filteredValue: currentFilters?.model ? [currentFilters.model] : null,
      filtered: currentFilters?.model ? true : false,
      render: (text: any) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        // selectedKeys,
        confirm,
        clearFilters,
        // visible
      }) => {
        return (
          <PriceFilter
            clearFilters={clearFilters}
            confirm={confirm}
            // selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            handleChange={(sort) => setIsGte(sort)}
            reset={() => console.log("reset")}
          />
        );
      },
      filteredValue: currentFilters?.price ? [currentFilters.price] : null,
      filtered: currentFilters?.price ? true : false,
    },
    {
      title: "Цена закуп",
      dataIndex: "price_in",
      key: "price_in",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        // selectedKeys,
        confirm,
        clearFilters,
        // visible
      }) => {
        return (
          <PriceFilter
            clearFilters={clearFilters}
            confirm={confirm}
            // selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            handleChange={(sort) => setIsGte(sort)}
            reset={() => console.log("reset")}
          />
        );
      },
      filteredValue: currentFilters?.price_in
        ? [currentFilters.price_in]
        : null,
      filtered: currentFilters?.price_in ? true : false,
    },
    {
      title: "Цена продажи",
      dataIndex: "price_out",
      key: "price_out",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        // selectedKeys,
        confirm,
        clearFilters,
        // visible
      }) => {
        return (
          <PriceFilter
            clearFilters={clearFilters}
            confirm={confirm}
            // selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            handleChange={(sort) => setIsGte(sort)}
            reset={() => console.log("reset")}
          />
        );
      },
      filteredValue: currentFilters?.price_out
        ? [currentFilters.price_out]
        : null,
      filtered: currentFilters?.price_out ? true : false,
    },
    {
      title: "Реализация",
      dataIndex: "realization_type",
      key: "realization_type",
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <SimpleTextFilter
            clearFilters={clearFilters}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            handleChange={() => console.log("123")}
            reset={() => console.log("reset")}
            data={[
              { value: "sell", title: "Продажа" },
              { value: "cancellation", title: "Списание" },
              { value: "disposal", title: "Утиль" },
            ]}
          />
        );
      },
      filteredValue: currentFilters?.realization_type
        ? [currentFilters.realization_type]
        : null,
      filtered: currentFilters?.realization_type ? true : false,
      render: (realization_type) => getRealizationType(realization_type),
    },
    {
      title: "Продажа",
      dataIndex: "sells_type",
      key: "sells_type",
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <SimpleTextFilter
            clearFilters={clearFilters}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            handleChange={() => console.log("123")}
            reset={() => console.log("reset")}
            data={[
              { value: "trade-12", title: "ТОРГ-12" },
              { value: "upd", title: "УПД" },
              { value: "pr_check", title: "товарный чек" },
            ]}
          />
        );
      },
      filteredValue: currentFilters?.sells_type
        ? [currentFilters.sells_type]
        : null,
      filtered: currentFilters?.sells_type ? true : false,
      render: (sells_type) => getSellsType(sells_type),
    },
    {
      title: "Изменение реализации",
      dataIndex: "date_change_rt",
      key: "date_change_rt",
      width: 200,
      render: (date: string) => currDateLocalString(date),
    },
  ];

  const handleChangeCategory = (id: number) => {
    setDetails([]);
    setTotalDetails(0);
    // setCategory(id);
    Detail.getDetailByCategory(id).then((res) => {
      res.results && setDetails(res.results);
      res.count && setTotalDetails(res.count);
    });
  };

  // const handleChangePageDetails = (page: number) => {
  //     if (category) {
  //         Detail.getDetailByCategory(category, page)
  //             .then(res => {
  //                 res.results && setDetails(res.results);
  //                 res.count && setTotalDetails(res.count);
  //             })
  //     }
  // }

  const handleChangeBrand = (value: string, data: any) => {
    const dataValue = JSON.parse(data["data-value"]);
    setModels([]);
    // setTotalModels(0);
    // setBrand(value);
    setModels(dataValue.models);
    // Detail.getModelsByBrand(id).then(res => {
    //     res.results && setModels(res.results);
    //     res.count && setTotalModels(res.count);
    // })
  };

  // const handleChangePageBrands = (page: number) => {
  //     Detail.getBrands(page)
  //         .then(res => {
  //             res.results && setBrands(res.results);
  //             res.count && setTotalBrands(res.count);
  //         })
  // }

  // const handleChangePageModels = (page: number) => {
  //     if (brand) {
  //         Detail.getModelsByBrand(brand, page)
  //             .then(res => {
  //                 res.results && setModels(res.results);
  //                 res.count && setTotalModels(res.count);
  //             })
  //     }
  // }

  const handleTableChange = async (
    pagination: any,
    filtersTable: any,
    sorter: any
  ) => {
    const { current } = pagination;
    let customFilters = { ...filtersTable };
    if (customFilters?.price) {
      customFilters = {
        ...customFilters,
        [isGte ? "price__gte" : "price__lte"]: customFilters.price,
      };
      delete customFilters.price;
    }
    let filters = getFiltersTable(pagination, customFilters);
    if (selectedCompany) {
      filters = { ...filters, company: selectedCompany };
    }
    // !filters?.category && setCategory(null);
    setCurrentFilters(filters);
    company && dispatch(DetailsActionCreators.setPageCatalog(current));
    company && dispatch(DetailsActionCreators.getDetailsCatalog(filters));
    invoice && dispatch(DetailsActionCreators.setPage(current));
    invoice &&
      dispatch(
        DetailsActionCreators.getDetailsByInvoice({
          invoice: invoice?.id,
          ...filters,
        })
      );
  };

  const handleCloseModal = () => {
    setVisibleDetailModal(false);
    setSelectedDetail(null);
    company &&
      dispatch(DetailsActionCreators.getDetailsCatalog(currentFilters));
    invoice &&
      dispatch(
        DetailsActionCreators.getDetailsByInvoice({
          invoice: invoice?.id,
          ...currentFilters,
        })
      );
  };

  const handleChangePrice = () => {};

  return (
    <>
      {invoice && (
        <Button
          style={{ marginBottom: 5 }}
          type={"primary"}
          onClick={() => setVisibleDetailModal(true)}
        >
          Добавить деталь в накладную
        </Button>
      )}
      {company && (
        <>
          <SelectWithPagination
            defaultValue={selectedCompany}
            array={companies}
            total={totalCompanies}
            handleChange={(value) => {
              setSelectedCompany(value);
              setCurrentFilters({ ...currentFilters, company: value });
              dispatch(
                DetailsActionCreators.getDetailsCatalog({
                  ...currentFilters,
                  company: value,
                })
              );
            }}
            pageSize={5}
            style={{ display: "block", marginBottom: 8, minWidth: 220 }}
            pageC={pageCompanies}
            handleChangePage={(page) =>
              dispatch(CompaniesActionCreators.getCompanies(page))
            }
          />
          <Button
            type={"primary"}
            disabled={!selectedCompany}
            onClick={() => {
              setSelectedCompany(null);
              const newFilters = { ...currentFilters };
              newFilters?.company && delete newFilters.company;
              setCurrentFilters(newFilters);
              dispatch(DetailsActionCreators.getDetailsCatalog(newFilters));
            }}
          >
            Сбросить
          </Button>
        </>
      )}

      {total > 0 && !invoice && (
        <div style={{ marginLeft: "auto" }}>
          <Popconfirm
            title={`Вы действительно хотите изменить цены на ${percentPrice}% у отфильтрованных запчастей?`}
            onConfirm={() => {
              dispatch(DetailsActionCreators.setLoadingCatalog(true));
              Detail.changePriceDetails(percentPrice, currentFilters)
                .then((res) => {
                  message.success("Операция прошла успешно!");
                  dispatch(DetailsActionCreators.setTotalCatalog(res.count));
                  dispatch(
                    DetailsActionCreators.setDetailsCatalog(res.results)
                  );
                })
                .catch((e) => message.error(e))
                .finally(() =>
                  dispatch(DetailsActionCreators.setLoadingCatalog(false))
                );
            }}
            okText="Да"
            cancelText="Нет"
          >
            <Button type={"primary"} disabled={!selectedCompany}>
              Изменить цену на
            </Button>
          </Popconfirm>
          <InputNumber
            formatter={(value) => `${value}%`}
            defaultValue={percentPrice}
            onChange={(value) => setPercentPrice(value)}
          />
        </div>
      )}

      <Table
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              setSelectedDetail(record);
              setVisibleDetailModal(true);
            },
          };
        }}
        scroll={{ x: getTableDimensions(columns), y: 500 }}
        loading={loading}
        dataSource={data}
        columns={
          invoice ? columns.filter((item) => item.key !== "sells") : columns
        }
        pagination={{
          total,
          pageSize: 5,
          showSizeChanger: false,
        }}
      />

      {/*add/edit detail modal*/}
      <DetailModal
        visible={visibleDetailModal}
        handleCancel={handleCloseModal}
        detail={selectedDetail}
        invoice={invoice}
      />
    </>
  );
};

export default DetailsTable;
