import React from 'react';
import {useTypedSelector} from "../hooks/useTypedSelector";
import {Layout, Row} from "antd";

const Profile = () => {
    const {user} = useTypedSelector(state => state.auth)

    return (
        <Layout>
            <Row>
                Profile
                {JSON.stringify(user)}
            </Row>

        </Layout>
    );
};

export default Profile;