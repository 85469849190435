import {
  AuthActionEnum,
  IAuthAction,
  IErrorAction,
  IIsLoadingAction,
  ISetLoadingForgotAction,
  ISetOkForgotMessageAction,
  ISetTokenAction,
  IUserAction,
} from "./types";
import { IProfileInfo } from "../../../interfaces/user";
import { AppDispatch } from "../../index";
import { apiUrl } from "../../../variables/appVariables";
import axios from "axios";
import { getProfileInfo } from "../../../api/user";

export const AuthActionCreators = {
  setUser: (user: IProfileInfo): IUserAction => ({
    type: AuthActionEnum.SET_USER,
    payload: user,
  }),
  setError: (error: string): IErrorAction => ({
    type: AuthActionEnum.SET_ERROR,
    payload: error,
  }),
  setLoading: (loading: boolean): IIsLoadingAction => ({
    type: AuthActionEnum.SET_IS_LOADING,
    payload: loading,
  }),
  setAuth: (auth: boolean): IAuthAction => ({
    type: AuthActionEnum.SET_AUTH,
    payload: auth,
  }),
  setToken: (token: string): ISetTokenAction => ({
    type: AuthActionEnum.SET_TOKEN,
    payload: token,
  }),
  setLoadingForgot: (loading: boolean): ISetLoadingForgotAction => ({
    type: AuthActionEnum.SET_LOADING_FORGOT,
    payload: loading,
  }),
  setOkForgotMessage: (message: string): ISetOkForgotMessageAction => ({
    type: AuthActionEnum.SET_OK_FORGOT_MESSAGE,
    payload: message,
  }),
  testForgotPassword: (email: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(AuthActionCreators.setLoadingForgot(true));
      const url = `${apiUrl}/sp/rest-auth/password/reset/`
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      const json = await res.json();
      if (!res.ok) {
        throw new Error(json);
      }

      dispatch(AuthActionCreators.setOkForgotMessage(json.detail));
    } catch (e) {
      dispatch(AuthActionCreators.setError(e.message));
    } finally {
      dispatch(AuthActionCreators.setLoadingForgot(false));
    }
  },
  forgotPassword: (email: string) => async (dispatch: AppDispatch) => {
    dispatch(AuthActionCreators.setLoadingForgot(true));
    try {
      const url = `${apiUrl}/accounting_rest/password-reset/?email=${email}`;
      const res = await axios.get(url);
      const data = res.data;

      if (data.error) {
        dispatch(AuthActionCreators.setError(data.error));
      }

      if (data.ok) {
        dispatch(AuthActionCreators.setOkForgotMessage(data.ok));
      }
      dispatch(AuthActionCreators.setLoadingForgot(false));
    } catch (e) {
      dispatch(AuthActionCreators.setError("Ошибка соединения с сервером"));
      dispatch(AuthActionCreators.setLoadingForgot(false));
    }
  },
  testLogin:
    (email: string, password: string) => async (dispatch: AppDispatch) => {
      try {
        dispatch(AuthActionCreators.setLoading(true));
        const url = `${apiUrl}/sp/rest-auth/login/`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });
        const json = await response.json();

        if (json.email) {
          throw new Error(json.email.join('\r\n'))
        }

        if (json.password1) {
          throw new Error(json.password1.join('\r\n'))
        }

        if (json.password2) {
          throw new Error(json.password2.join('\r\n'))
        }

        if (json.non_field_errors) {
          throw new Error(json.non_field_errors.join('\r\n'))
        }

        if (json.key) {
          dispatch(AuthActionCreators.setAuth(true));
          dispatch(AuthActionCreators.setToken(json.key));
          localStorage.setItem("token", json.key);
          getProfileInfo(json.key).then((res) => {
            dispatch(AuthActionCreators.setUser(res));
          });
          dispatch(AuthActionCreators.setLoading(false));
        }
      } catch (e) {
        dispatch(AuthActionCreators.setError(e.message));
      } finally {
        dispatch(AuthActionCreators.setLoading(false));
      }
    },
  login: (email: string, password: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(AuthActionCreators.setLoading(true));
      const url = `${apiUrl}/accounting_rest/login/`;
      const res = await axios.post(url, { email, password });
      const data = await res.data;
      switch (data.status) {
        case "no_such_email":
          dispatch(
            AuthActionCreators.setError(
              "Аккаунта с таким email адресом не существует"
            )
          );
          dispatch(AuthActionCreators.setLoading(false));
          break;
        case "invalid_password":
          dispatch(
            AuthActionCreators.setError(
              "Неверный пароль, попробуйте снова или воостановите пароль"
            )
          );
          dispatch(AuthActionCreators.setLoading(false));
          break;
        case "ok":
          dispatch(AuthActionCreators.setAuth(true));
          dispatch(AuthActionCreators.setToken(data.token));
          localStorage.setItem("token", data.token);
          getProfileInfo(data.token).then((res) => {
            dispatch(AuthActionCreators.setUser(res));
          });
          dispatch(AuthActionCreators.setLoading(false));
          break;
        default:
          break;
      }
    } catch (e) {
      dispatch(AuthActionCreators.setError("Ошибка авторизации!"));
      dispatch(AuthActionCreators.setLoading(false));
    }
  },
  logout: () => async (dispatch: AppDispatch) => {
    localStorage.removeItem("token");
    dispatch(AuthActionCreators.setUser({} as IProfileInfo));
    dispatch(AuthActionCreators.setAuth(false));
  },
};
