import React, { useState } from "react";
import { Button, Card, Form, Input, Layout, message, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import { rules } from "../utils/formRequired";
import { apiUrl } from "../variables/appVariables";

const ResetPassword = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const confirmPassword = async (body: any) => {
    try {
        setLoading(true);
        const url = `${apiUrl}/sp/rest-auth/password/reset/confirm/`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        const json = await response.json();
        if (!response.ok) {
            const err = json?.new_password1 || json?.new_password2 || ['Ошибка запроса на сервер'];
            throw new Error(err.join(', '))
        }

        message.success(json.detail);
        form.resetFields();
        navigate('/login');
    } catch(e) {
        message.error(e.message);
    } finally {
        setLoading(false);
    }
  }

  return (
    <Layout>
      <Row justify={"center"} align={"middle"} className={"h100"}>
        <Card title="Сброс пароля" style={{minWidth: 320}}>
          <Form form={form} layout={"vertical"} onFinish={(values) => confirmPassword({...values, uid, token})}>
            <Form.Item
              name="new_password1"
              label="Новый пароль"
              rules={[rules.required("Обязательное поле!")]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={"Введите новый пароль"}
              />
            </Form.Item>
            <Form.Item
              name="new_password2"
              label="Подтвердите пароль"
              rules={[rules.required("Обязательное поле!")]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={"Подтвердите новый пароль"}
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Изменить пароль
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    </Layout>
  );
};

export default ResetPassword;
