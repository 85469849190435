import {apiUrl} from "../variables/appVariables";
import axios from "axios";

export class Address {
    public static async getCountries() {
        try {
            const url = `${apiUrl}/accounting_rest/countries/`
            const res = await axios.get(url, {
                headers: {Authorization: `Token ${localStorage.getItem('token')}`}
            })

            return res.data
        }catch (e) {
            return Promise.reject(e)
        }
    }

    public static async getRegions(country: string) {
        try {
            const url = `${apiUrl}/accounting_rest/regions/?country=${country}`
            const res = await axios.get(url, {
                headers: {Authorization: `Token ${localStorage.getItem('token')}`}
            })

            return res.data
        }catch (e) {
            return Promise.reject(e)
        }
    }

    public static async getCities(region: string) {
        try {
            const url = `${apiUrl}/accounting_rest/cities/?region=${region}`
            const res = await axios.get(url, {
                headers: {Authorization: `Token ${localStorage.getItem('token')}`}
            })

            return res.data
        }catch (e) {
            return Promise.reject(e)
        }
    }
}