import React, {FC, useEffect, useState} from 'react';
import {Button, Col, Form, Input, message, Modal, Row, Select} from "antd";
import {fixAutocomplete} from "../../utils/fixAutocomlete";
import {Companies} from "../../api/companies";
import {Address} from "../../api/address";
import {renderSelectOptions} from "./MainCompanyInfoForm";
import {getOptionTitle} from "../../utils/getOptionTitle";
import { useTypedSelector } from '../../hooks/useTypedSelector';

interface IComponentProps {
    visible: boolean,
    handleCancel: () => void,
    stock?: any,
    company: any,
    handleChangeStocks: (stocks: any[]) => void,
    stocks: any[]
}

const StockModal: FC<IComponentProps> = ({visible, stock, stocks, handleChangeStocks, handleCancel, company}) => {
    const {selectedCompany} = useTypedSelector(state => state.companies);
    const [form] = Form.useForm();

    const [countries, setCountries] = useState([])
    const [regions, setRegions] = useState([])
    const [cities, setCities] = useState([])

    useEffect(() => {
        Address.getCountries().then(res => {
            setCountries(res.results)
        })
    }, [])

    useEffect(() => {
        if(selectedCompany && visible) {
            if (selectedCompany?.address) {
                setAddressFromParsedJSON(selectedCompany.address);
            }

            selectedCompany?.house && form.setFieldsValue({house: selectedCompany.house});
            selectedCompany?.street && form.setFieldsValue({street: selectedCompany.street});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany, visible])

    useEffect(() => {
        if (visible) {
            fixAutocomplete()
        }
    }, [visible])

    useEffect(() => {
        if (stock) {
            stock?.country && findAddress(countries, stock.country, handleChangeCountry);
            stock?.region && findAddress(regions, stock.region, handleChangeRegion);
            form.setFieldsValue({...stock})
        }
    }, [stock])

    const setAddressFromParsedJSON = (json: any) => {
        const address = json.includes('{') ? JSON.parse(json) : json;

        if (address.country) {
            form.setFieldsValue({country: getOptionTitle(address.country)});
            address?.country?.id && handleChangeCountry(address.country.id);
        }

        if (address.region) {
            form.setFieldsValue({region: getOptionTitle(address.region)});
            address?.region?.id && handleChangeRegion(address.region.id);
        }

        if (address.city) {
            form.setFieldsValue({city: getOptionTitle(address.city)});
        }

    }

    const findAddress = (arr: any[], address: string, func: any) => {
        if (arr?.length > 0) {
            const selectedItem: any = arr.filter(item => getOptionTitle(item) === address);
            if (selectedItem?.length === 1) {
                func(selectedItem[0]?.id);
            }
        }
    }

    const handleChangeCountry = (countryId: any) => {
        Address.getRegions(countryId).then(res => {
            setRegions(res.results);
        })
        form.setFieldsValue({region: null, city: null});
    }

    const handleChangeRegion = (regionId: any) => {
        Address.getCities(regionId).then(res => {
            setCities(res.results);
        })
        form.setFieldsValue({city: null});
    }


    const addStock = (body: any) => {
        Companies.addStock(body)
            .then(res => {
                message.success('Склад успешно создан!');
                handleChangeStocks([...stocks, res]);
                handleCancel?.();
                form.resetFields();
            })
            .catch(e => {
                if (e?.company) {
                    e.company.map((item: any) => {
                        return message.error(item)
                    })
                }else {
                    message.error('Ошибка запроса на создание склада!')
                }
            })
    }

    const editStock = (body: any) => {
        Companies.editStock(stock?.id, body)
            .then(res => {
                const objIndex = stocks.findIndex((obj => obj.id == res.id));
                const newStocks = [...stocks];
                newStocks[objIndex] = {...res};
                handleChangeStocks(newStocks);
                handleCancel?.();
                form.resetFields();
                message.success('Склад успешно отредактирован!')
            })
            .catch(e => message.error(e))
    }

    const submit = (values: any) => {
        const body = {...values, company: company?.id}
        stock ? editStock(body) : addStock(body)
    }

    return (
        <Modal
            title={`${stock ? 'Редактировать склад' : 'Добавить склад'}`}
            visible={visible}
            onCancel={() => {
                handleCancel?.();
                form.resetFields();
            }}
            footer={[]}
        >
            <Form
                layout={'vertical'}
                form={form}
                onFinish={submit}
            >
                <Row gutter={5}>
                    <Col lg={12} md={12} xs={24}>
                        <Form.Item
                            rules={[{required: true, message: 'Обязательное поле!'}]}
                            label={'Название'}
                            name={'title'}
                        >
                            <Input placeholder={'Введите название склада'}/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                        <Form.Item
                            name="country"
                            label="Страна"
                        >
                            <Select showSearch
                                    placeholder="Выберите страну"
                                    onChange={(_, data: any) => {
                                        const id = data['data-id'];
                                        handleChangeCountry(id);
                                    }}
                            >
                                {renderSelectOptions(countries)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                        <Form.Item
                            name="region"
                            label="Регион"
                        >
                            <Select showSearch
                                    placeholder="Выберите регион"
                                    onChange={(_, data: any) => {
                                        const id = data['data-id'];
                                        handleChangeRegion(id);
                                    }}>
                                {renderSelectOptions(regions)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                        <Form.Item
                            name="city"
                            label="Город"
                        >
                            <Select showSearch
                                    onChange={(value) => {}}

                                    placeholder="Выберите город">
                                {renderSelectOptions(cities)}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={12} md={12} xs={24}>
                        <Form.Item
                            name="street"
                            label="Улица"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                        <Form.Item
                            name="house"
                            label="Дом"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col lg={24} md={24} xs={24}>
                        <Form.Item name={'file_update'} label="Ссылка на прайс лист">
                            <Input placeholder='Введите ссылку на прайс лист' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'end'}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            {stock ? 'Сохранить изменения' : 'Добавить склад'}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
};

export default StockModal;