import React, {FC, useEffect, useState} from 'react';
import {Button, Select, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons/lib";
import SelectWithPagination from "../SelectWithPagination";

const {Option} = Select;

const DetailFilterSelect: FC<any> = ({
                                         setSelectedKeys,
                                         selectedKeys,
                                         confirm,
                                         clearFilters,
                                         data,
                                         total,
                                         handleChange,
                                         handleChangePage,
                                         pagination,
                                         reset
                                     }) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (data?.length === 0) {
            clearFilters();
            setSelectedKeys([]);
            setValue(null);
        }
    }, [data])

    return (
        <div style={{padding: 8}}>
            {
                pagination ? (
                    <SelectWithPagination
                        array={data}
                        total={total}
                        defaultValue={value}
                        style={{marginBottom: 8, display: 'block'}}
                        handleChange={(value, data) => {
                            setValue(value);
                            handleChange(JSON.parse(data['data-value'])?.id, JSON.parse(data['data-value']));
                            setSelectedKeys(JSON.parse(data['data-value'])?.title ? [JSON.parse(data['data-value'])?.title] : []);
                        }}
                        handleChangePage={handleChangePage}
                    />
                ) : <Select showSearch
                            optionFilterProp="children"
                            value={value}
                            placeholder={'Выберите из списка'}
                            style={{marginBottom: 8, display: 'block'}}
                            onChange={(value, data) => {
                                setValue(value);
                                setSelectedKeys(JSON.parse(data['data-value'])?.title ? [JSON.parse(data['data-value'])?.title] : []);
                                handleChange(value, data)
                            }}
                >
                    {
                        data?.length > 0 && (
                            <>
                                {
                                    data.map(item => (
                                        <Option key={item.id} value={item.id} data-value={JSON.stringify(item)}>
                                            {item.title}
                                        </Option>
                                    ))
                                }
                            </>
                        )
                    }
                </Select>
            }

            <Space>
                <Button
                    type="primary"
                    onClick={() => confirm({closeDropdown: false})}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{width: 90}}
                >
                    Найти
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys([]);
                        confirm({closeDropdown: true});
                        setValue(null);
                        reset && reset();
                    }}
                    size="small"
                    style={{width: 90}}
                >
                    Сбросить
                </Button>
            </Space>
        </div>
    );
};

export default DetailFilterSelect;