import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Space, Tooltip } from "antd";
import { SearchOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons/lib";


const PriceFilter = ({
    setSelectedKeys,
    confirm,
    clearFilters,
    handleChange,
    reset
}) => {
    const [value, setValue] = useState(0);
    const [isGte, setIsGte] = useState(true);


    return (
        <div style={{ padding: 8 }}>
            <div style={{ display: 'flex', marginBottom: 10 }}>
                <Tooltip title={isGte ? 'Больше чем' : 'Меньше чем'}>
                    <Button style={{ marginRight: 5 }}
                        onClick={() => { 
                            setIsGte(!isGte) 
                            handleChange(!isGte)
                        }}
                        icon={isGte ? <LeftOutlined /> : <RightOutlined />}
                    />
                </Tooltip>
                <InputNumber
                    min={0}
                    value={value}
                    style={{ width: '100%' }}
                    placeholder={'Введите цену'}
                    onChange={value => {
                        setValue(value);
                        setSelectedKeys(value ? [value] : []);

                    }}
                />
            </div>

            <Space>
                <Button
                    type="primary"
                    onClick={() => confirm({ closeDropdown: false })}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Найти
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys([]);
                        confirm({ closeDropdown: true });
                        setValue(null);
                        reset && reset();
                    }}
                    size="small"
                    style={{ width: 90 }}
                >
                    Сбросить
                </Button>
            </Space>
        </div>
    )
}

export default PriceFilter;