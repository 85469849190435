import React, {lazy, Suspense} from 'react';
import {Layout, Row, Spin} from "antd";
import {ErrorBoundary} from "../components/ErrorBoundary";

const ReviewsList = lazy(() => import("../components/Reviews/ReviewsList"))

const Reviews = () => {
    return (
        <Layout>
            <Row>
                <Suspense fallback={<Spin/>}>
                    <ErrorBoundary>
                        <ReviewsList/>
                    </ErrorBoundary>
                </Suspense>
            </Row>
        </Layout>
    );
};

export default Reviews;
