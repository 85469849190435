import { apiUrl } from "../variables/appVariables";
import axios from "axios";
// import { convertObjFilterToUrl } from "../utils/getFiltersTabe";

let abortController;
export { abortController };

export class Detail {
  public static async changePriceDetails(percent = 0, filters: any = {}) {
    try {
      const formattedFilters = { ...filters };
      formattedFilters?.page && delete formattedFilters.page;
      const url = `${apiUrl}/sp/change_price/?percent=${percent}`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedFilters),
      });
      if (res.ok) {
        const json = await res.json();
        if (json.status === "error") {
          return Promise.reject("Ошибка запроса на смену цены");
        }
        return json;
      } else {
        return Promise.reject("Ошибка запроса на смену цены");
      }
    } catch (e) {
      return Promise.reject("Ошибка запроса на смену цены");
    }
  }

  public static async getCatalogDetails(params?: any) {
    try {
      if (abortController) {
        abortController.abort();
      }
      abortController = new AbortController();
      const filters = params
        ? `?${new URLSearchParams(params).toString()}`
        : "";
      const url = `${apiUrl}/sp/detail/${filters}`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        signal: abortController.signal,
      });
      const json = await res.json();
      return json;
    } catch (e) {
      if (e.name === 'AbortError') {
        return;
      }
      return Promise.reject(`Ошибка запроса на сервер: ${e.message}`);
    }
  }

  public static async getDetailsCategories() {
    try {
      const url = `${apiUrl}/sp/category/`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });

      return res.data;
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер");
    }
  }

  public static async getDetailByCategory(id: number, page = 1, search = "") {
    try {
      const url = search
        ? `${apiUrl}/sp/part/?category=${id}&page=${page}&search=${search}`
        : `${apiUrl}/sp/part/?category=${id}&page=${page}`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      return res.data;
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер!");
    }
  }

  public static async getBrandsModelsList() {
    try {
      const url = `${apiUrl}/search/brands_models/`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      return res.data;
    } catch (e) {
      return Promise.reject(
        "Ошибка запроса на получение каталога марок-моделей"
      );
    }
  }

  public static async getBrands(page = 1, search = "") {
    try {
      const url = search
        ? `${apiUrl}/sp/brand/?page=${page}&search=${search}`
        : `${apiUrl}/sp/brand/?page=${page}`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });

      return res.data;
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер");
    }
  }

  public static async getModelsByBrand(id: number, page = 1, search = "") {
    try {
      const url = search
        ? `${apiUrl}/sp/model/?brand=${id}&page=${page}&search=${search}`
        : `${apiUrl}/sp/model/?brand=${id}&page=${page}`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      return res.data;
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер!");
    }
  }

  public static async addDetailToInvoice(data: any) {
    try {
      const url = `${apiUrl}/sp/detail/`;
      const res = await axios.post(url, data, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": `multipart/form-data`,
        },
      });

      return res.data;
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер!");
    }
  }

  public static async editDetailInvoice(id: number, data: any) {
    try {
      const url = `${apiUrl}/sp/detail/${id}/`;
      const res = await axios.patch(url, data, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": `multipart/form-data`,
        },
      });

      return res.data;
    } catch (e) {
      return Promise.reject("Ошибка запроса на сервер!");
    }
  }

  public static async deleteDetail(id: number) {
    try {
      const url = `${apiUrl}/sp/detail/${id}/`;
      const res = await axios.delete(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });



      if (res.status === 403 && res.data.detail) {
        throw new Error(res.data.detail);
      }

      console.log('response success', res);

      return res.data;
    } catch (e: any) {
      console.log('response error', e, e.message);
      return Promise.reject(`Ошибка запроса на серевер: ${e.message}`);
    }
  }

  public static async deletePhotoDetail(id: number) {
    try {
      const url = `${apiUrl}/sp/photo/${id}/`;
      await axios.delete(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
