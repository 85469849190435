export interface ILaximoState {
    //find_by_bin
    foundedCars: any[] | null,
    loadingFoundedCars: boolean,
    selectedFoundedCar: any,
    //categories
    categories: any[] | null,
    loadingCategories: boolean,
    selectedCategory: any,
    //units
    units: any[] | null,
    loadingUnits: boolean,
    selectedUnit: any,
    //unit info
    unitInfo: any,
    loadingUnitInfo: boolean,
    selectedUnitInfo: any,
    //details by unit
    details: any[] | null,
    loadingDetails: boolean,
    selectedDetail: any,
    //tabs
    tabs: string[],
    activeTab: string,
    loadingTab: boolean,
    //messages
    error: string,
    //cars catalog
    carsCatalog: any[],
    loadingCarsCatalog: boolean,
    selectedCarsCatalog: any,
    autoParams: any[],
    loadingAutoParams: boolean,
    selectedAutoParams: any,
    // auto: any[],
    // loadingAuto: any[],

}

export enum LaximoActionEnum {
    //find_by_bin
    SET_FOUNDED_CARS = 'LAXIMO/SET_FOUNDED_CARS',
    SET_LOADING_FOUNDED_CARS = 'LAXIMO/SET_LOADING_FOUNDED_CARS',
    SET_SELECTED_FOUNDED_CAR = 'LAXIMO/SET_SELECTED_FOUNDED_CAR',
    //categories
    SET_CATEGORIES = 'LAXIMO/SET_CATEGORIES',
    SET_LOADING_CATEGORIES = 'LAXIMO/SET_LOADING_CATEGORIES',
    SET_SELECTED_CATEGORY = 'LAXIMO/SET_SELECTED_CATEGORY',
    //units
    SET_UNITS = 'LAXIMO/SET_UNITS',
    SET_LOADING_UNITS = 'LAXIMO/SET_LOADING_UNITS',
    SET_SELECTED_UNIT = 'LAXIMO/SET_SELECTED_UNIT',
    //unit info
    SET_UNIT_INFO = 'LAXIMO/SET_UNIT_INFO',
    SET_LOADING_UNIT_INFO = 'LAXIMO/SET_LOADING_UNIT_INFO',
    SET_SELECTED_UNIT_INFO = 'LAXIMO/SET_SELECTED_UNIT_INFO',
    //details by unit
    SET_DETAILS = 'LAXIMO/SET_DETAILS',
    SET_LOADING_DETAILS = 'LAXIMO/SET_LOADING_DETAILS',
    SET_SELECTED_DETAIL = 'LAXIMO/SET_SELECTED_DETAIL',
    //tabs
    SET_TABS = 'LAXIMO/SET_TABS',
    SET_ACTIVE_TAB = 'LAXIMO/SET_ACTIVE_TAB',
    SET_LOADING_TAB = 'LAXIMO/SET_LOADING_TAB',
    //messages
    SET_LAXIMO_ERROR = 'LAXIMO/SET_LAXIMO_ERROR',
    //cars catalog
    SET_CARS_CATALOG = 'LAXIMO/SET_CARS_CATALOG',
    SET_LOADING_CARS_CATALOG = 'LAXIMO/SET_LOADING_CARS_CATALOG',
    SET_SELECTED_CARS_CATALOG = 'LAXIMO/SET_SELECTED_CARS_CATALOG',
    SET_AUTO_PARAMS = 'LAXIMO/SET_AUTO_PARAMS',
    SET_LOADING_AUTO_PARAMS = 'LAXIMO/SET_LOADING_AUTO_PARAMS',
    SET_SELECTED_AUTO_PARAMS = 'LAXIMO/SET_SELECTED_AUTO_PARAMS',
}

//find_by_bin
export interface IFoundedCarsAction {
    type: LaximoActionEnum.SET_FOUNDED_CARS,
    payload: any[] | null,
}

export interface ILoadingFoundedCarsAction {
    type: LaximoActionEnum.SET_LOADING_FOUNDED_CARS,
    payload: boolean
}

export interface ISelectedFoundedCarAction {
    type: LaximoActionEnum.SET_SELECTED_FOUNDED_CAR,
    payload: any,
}

//categories
export interface ICategoriesAction {
    type: LaximoActionEnum.SET_CATEGORIES,
    payload: any[] | null,
}

export interface ICategoriesLoadingAction {
    type: LaximoActionEnum.SET_LOADING_CATEGORIES,
    payload: boolean
}

export interface ICategorySelectedAction {
    type: LaximoActionEnum.SET_SELECTED_CATEGORY,
    payload: any,
}

//units
export interface IUnitsAction {
    type: LaximoActionEnum.SET_UNITS,
    payload: any[] | null,
}

export interface ILoadingUnitsAction {
    type: LaximoActionEnum.SET_LOADING_UNITS,
    payload: boolean,
}

export interface ISelectedUnitAction {
    type: LaximoActionEnum.SET_SELECTED_UNIT,
    payload: any,
}

//unit info
export interface IUnitInfoAction {
    type: LaximoActionEnum.SET_UNIT_INFO,
    payload: Object | null,
}

export interface ILoadingUnitInfoAction {
    type: LaximoActionEnum.SET_LOADING_UNIT_INFO,
    payload: boolean,
}

export interface ISelectedUnitInfoAction {
    type: LaximoActionEnum.SET_SELECTED_UNIT_INFO,
    payload: any,
}

//details by unit
export interface IDetailsAction {
    type: LaximoActionEnum.SET_DETAILS,
    payload: any[] | null,
}

export interface ILoadingDetailsAction {
    type: LaximoActionEnum.SET_LOADING_DETAILS,
    payload: boolean,
}

export interface ISelectedDetailAction {
    type: LaximoActionEnum.SET_SELECTED_DETAIL,
    payload: any,
}

//tabs
export interface ITabsAction {
    type: LaximoActionEnum.SET_TABS,
    payload: string[],
}

export interface ILoadingTabAction {
    type: LaximoActionEnum.SET_LOADING_TAB,
    payload: boolean,
}

export interface IActiveTabAction {
    type: LaximoActionEnum.SET_ACTIVE_TAB,
    payload: string,
}

//messages
export interface IMessageErrorAction {
    type: LaximoActionEnum.SET_LAXIMO_ERROR,
    payload: string,
}

//cars catalog
export interface ICarsCatalogAction {
    type: LaximoActionEnum.SET_CARS_CATALOG,
    payload: any[],
}

export interface ILoadingCarsCatalogAction {
    type: LaximoActionEnum.SET_LOADING_CARS_CATALOG,
    payload: boolean,
}

export interface ISelectedCarsCatalogAction {
    type: LaximoActionEnum.SET_SELECTED_CARS_CATALOG,
    payload: any,
}

export interface IAutoParamsAction {
    type: LaximoActionEnum.SET_AUTO_PARAMS,
    payload: any[],
}

export interface ILoadingAutoParamsAction {
    type: LaximoActionEnum.SET_LOADING_AUTO_PARAMS,
    payload: boolean,
}

export interface ISelectedAutoParamsAction {
    type: LaximoActionEnum.SET_SELECTED_AUTO_PARAMS,
    payload: any,
}

export type LaximoAction =
    IFoundedCarsAction |
    ILoadingFoundedCarsAction |
    ISelectedFoundedCarAction |
    ICategoriesAction |
    ICategoriesLoadingAction |
    ICategorySelectedAction |
    IUnitsAction |
    ILoadingUnitsAction |
    ISelectedUnitAction |
    IUnitInfoAction |
    ILoadingUnitInfoAction |
    ISelectedUnitInfoAction |
    IDetailsAction |
    ILoadingDetailsAction |
    ISelectedDetailAction |
    ITabsAction |
    IActiveTabAction |
    ILoadingTabAction |
    IMessageErrorAction |
    ICarsCatalogAction |
    ILoadingCarsCatalogAction |
    ISelectedCarsCatalogAction |
    IAutoParamsAction |
    ILoadingAutoParamsAction |
    ISelectedAutoParamsAction