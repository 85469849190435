
export interface IInvoicesState {
    invoices: any[],
    loading: boolean,
    total: number,
    page: number
}

export enum InvoicesActionTypeEnum {
    SET_INVOICES = "SET_INVOICES",
    SET_LOADING = "SET_LOADING_INVOICES",
    SET_TOTAL = "SET_TOTAL_INVOICES",
    SET_PAGE = "SET_PAGE_INVOICES"
}

export interface ISetInvoicesAction {
    type: InvoicesActionTypeEnum.SET_INVOICES,
    payload: any[]
}

export interface ISetInvoicesLoadingAction {
    type: InvoicesActionTypeEnum.SET_LOADING,
    payload: boolean
}

export interface ISetInvoicesTotalAction {
    type: InvoicesActionTypeEnum.SET_TOTAL,
    payload: number
}

export interface ISetInvoicesPageAction {
    type: InvoicesActionTypeEnum.SET_PAGE,
    payload: number
}

export type InvoicesActionType =
    ISetInvoicesAction |
    ISetInvoicesLoadingAction |
    ISetInvoicesPageAction |
    ISetInvoicesTotalAction