import React, {lazy, Suspense} from 'react';
import {Layout, Row, Spin} from "antd";
import {ErrorBoundary} from "../components/ErrorBoundary";

const OrdersTable = lazy(() => import('../components/Orders/OrdersTable'));

const Orders = () => {
    return (
        <Layout>
            <Row>
                <h1>Заказы</h1>
                <Suspense fallback={<Spin/>}>
                    <ErrorBoundary>
                        <OrdersTable/>
                    </ErrorBoundary>
                </Suspense>
            </Row>
        </Layout>
    );
};

export default Orders;
