import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Tabs,
} from "antd";
import { pickBy, identity } from "lodash";
import { Detail } from "../../api/detail";
// import SelectWithPagination from "../SelectWithPagination";
import PhotoUpload from "../PhotoUpload";
import { rules } from "../../utils/formRequired";
import { Companies } from "../../api/companies";
import LaximoActionPage from "../Laximo/LaximoActionPage";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const { Option } = Select;
const { TextArea } = Input;

interface IComponentProps {
  visible: boolean;
  handleCancel: () => void;
  detail: any;
  invoice: any;
}

const DetailModal: FC<IComponentProps> = ({
  visible,
  handleCancel,
  detail,
  invoice,
}) => {
  const { selectedDetail } = useTypedSelector((state) => state.laximo);
  const [form] = Form.useForm();
  const [details, setDetails] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [defect, setDefect] = useState(false);
  const [realization, setRealization] = useState("");
  const [isEngine, setIsEngine] = useState(false);
  const [isTransmission, setIsTransmission] = useState(false);
  const [visibleLaximoModal, setVisibleLaximoModal] = useState(false);

  useEffect(() => {
    Detail.getDetailsCategories()
      .then((res) => res?.results && setCategories(res.results))
      .catch((e) => {
        message.error(`${e}, не удалось получить список категорий запчастей`);
      });

    Detail.getBrandsModelsList()
      .then((res) => {
        setBrands(res);
      })
      .catch((e) => {
        message.error(`${e}, не удалось получить список категорий запчастей`);
      });
  }, []);

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({ ...detail });
      detail?.defectiveness && setDefect(detail.defectiveness);
      initialLoadData(detail);
      setIsEngine(isEngine);
      setIsTransmission(isTransmission);
      detail?.company &&
        Companies.getCompanyById(detail.company?.id || detail.company)
          .then((res) => {
            setStocks(res.stocks);
            res.stocks?.length > 0 &&
              form.setFieldsValue({ stock: res?.stocks[0]?.id });
          })
          .catch((e) =>
            message.error(`${e}, не удалось получить список складов!`)
          );
    } else {
      resetFormFields();
      setDefect(false);
    }
  }, [detail]);

  useEffect(() => {
    if (invoice?.company) {
      resetFormFields();
      invoice?.company &&
        Companies.getCompanyById(invoice.company?.id || invoice.company)
          .then((res) => {
            setStocks(res.stocks);
            res.stocks?.length > 0 &&
              form.setFieldsValue({ stock: res.stocks[0].id });
          })
          .catch((e) =>
            message.error(`${e}, не удалось получить список складов!`)
          );
    } else {
      form.setFieldsValue({ stock: null });
    }
  }, [invoice]);

  useEffect(() => {
    if (selectedDetail) {
      form.setFieldsValue({ oem: selectedDetail.oem });
    } else {
      form.setFieldsValue({ oem: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDetail]);

  // initital load data
  const initialLoadData = (detail: any) => {
    if (detail?.part?.category) {
      const idxCategory = categories.findIndex(
        (item) => item.title === detail.part.category
      );
      if (idxCategory >= 0) {
        Detail.getDetailByCategory(categories[idxCategory].id).then((res) => {
          form.setFieldsValue({ category: categories[idxCategory].id });
          res.results && setDetails(res.results);
          if (detail?.part?.title) {
            const idxDetail = res.results.findIndex(
              (item) => item.title === detail.part.title
            );
            if (idxDetail >= 0) {
              handleChangeDetail(res.results[idxDetail].id, {
                "data-value": JSON.stringify(res.results[idxDetail]),
              });
            }
          }
        });
      }
    }

    if (detail?.brand) {
      setTimeout(() => {
        const idxBrand = brands.findIndex(
          (item) => item.brand === detail.brand
        );
        if (idxBrand >= 0) {
          const foundedBrand = brands[idxBrand];
          // setSelectedBrand(foundedBrand);
          form.setFieldsValue({ brand: foundedBrand?.brand });
          setModels(foundedBrand.models);
          detail?.model && handleChangeModel(detail.model);
        }
      }, 1000);
    }
  };

  const resetFormFields = () => {
    form.setFieldsValue({
      category: null,
      part: null,
      engine: "",
      transmission: "",
      shop_code_number: "",
      oem: "",
      defectiveness: false,
      description_def: "",
      realization_type: null,
      sells_type: null,
      price_in: 0,
      price_out: 0,
      description: "",
      photos: [],
    });
  };

  // select actions
  const handleChangeBrand = (id: number, data: any) => {
    const dataValue = JSON.parse(data["data-value"]);
    // dataValue && setSelectedBrand(dataValue);
    form.setFieldsValue({ brand: dataValue?.brand });
    form.setFieldsValue({ model: null });
    // setSelectedModel(null);
    dataValue && setModels(dataValue.models);
  };

  const handleChangeModel = (value: string) => {
    // setSelectedModel(value);
    form.setFieldsValue({ model: value });
  };

  const handleChangeCategory = (id: number) => {
    form.setFieldsValue({ part: null });
    setDetails([]);
    setIsEngine(false);
    setIsTransmission(false);

    Detail.getDetailByCategory(id).then((res) => {
      res.results && setDetails(res.results);
    });
  };

  const handleChangeDetail = (id: number, data: any) => {
    console.log("change detail", data);
    form.setFieldsValue({ part: id });
    const dataValue = data["data-value"]
      ? JSON.parse(data["data-value"])
      : null;

    if (dataValue) {
      const isEngine =
        dataValue?.category === "Двигатель" && dataValue?.title === "Двигатель";
      const isTransmission =
        dataValue?.category === "Трансмиссия" &&
        (dataValue?.title === "АКПП, детали АКПП" ||
          dataValue?.title === "МКПП, детали МКПП");
      setIsEngine(isEngine);
      setIsTransmission(isTransmission);
    }
  };

  // form action
  const submit = (values: any) => {
    const cleanedObject = pickBy(values, identity);
    // console.log(values, cleanedObject);
    // return;
    const body: any = {
      ...cleanedObject,
      price: cleanedObject.price_out,
      invoice: invoice?.id,
    };

    const formData = new FormData();

    if (body?.photos?.length > 0) {
      body.photos.map((photo: any) => {
        if (photo.originFileObj) {
          return formData.append("photo", photo.originFileObj, photo.name);
        }
      });
      delete body.photos;
    }

    // detail && delete body.part;

    for (let key in body) {
      formData.append(key, body[key]);
    }

    if (detail?.id) {
      Detail.editDetailInvoice(detail.id, formData)
        .then((res) => {
          message.success("Деталь успешно отредактирована!");
          handleCancel();
          setDetails([]);
          resetFormFields();
        })
        .catch((e) => message.error(`Oшибка редактирования детали`));
    } else {
      Detail.addDetailToInvoice(formData)
        .then((res) => {
          message.success("Деталь успешно добавлена!");
          handleCancel();
          setDetails([]);
          resetFormFields();
        })
        .catch((e) => message.error(`Oшибка добавления детали`));
    }
  };

  const handleChangeFileList = (fileList: any) => {
    form.setFieldsValue({ photos: fileList });
  };

  return (
    <>
      <Modal
        title={`${
          detail
            ? `Редактировать ${detail?.part?.title} ${detail?.brand} ${detail?.model}`
            : "Добавить деталь"
        }`}
        width={1000}
        visible={visible}
        onCancel={() => {
          handleCancel();
          resetFormFields();
          // setModels([]);
          // setSelectedModel(null);
          // setSelectedBrand(null);
          setDetails([]);
          // form.resetFields();
          setIsEngine(false);
          setIsTransmission(false);
        }}
        footer={[]}
      >
        <Form form={form} onFinish={submit} layout={"vertical"}>
          <Row gutter={5}>
            <Divider>Основная информация</Divider>

            <Col lg={12} xs={24}>
              <Card>
                <Row gutter={5}>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      name={"stock"}
                      label={"Склад"}
                      rules={[rules.required("Обязательное поле")]}
                    >
                      <Select placeholder={"Выберите склад"}>
                        {stocks.map((stock: any) => {
                          return (
                            <Option key={stock.id} value={stock.id}>
                              {stock.title || `Без названия ID${stock.id}`}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label={"Марка"}
                      name={"brand"}
                      rules={[rules.required("Обязательное поле")]}
                    >
                      <Select
                        placeholder={"Выберите марку"}
                        showSearch
                        filterOption={false}
                        onChange={handleChangeBrand}
                      >
                        {brands.map((item) => (
                          <Option
                            data-value={JSON.stringify(item)}
                            value={item.brand}
                            key={item.brand}
                          >
                            {item.brand}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label={"Модель"}
                      name={"model"}
                      rules={[rules.required("Обязательное поле")]}
                    >
                      <Select
                        placeholder="Выберите модель"
                        showSearch
                        filterOption={false}
                        onChange={handleChangeModel}
                      >
                        {models.map((item) => (
                          <Option key={item.model} value={item.model}>
                            {item.model}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label={"Категория"}
                      name={"category"}
                      rules={[rules.required("Обязательное поле")]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={"Выберите категорию запчасти"}
                        onChange={handleChangeCategory}
                      >
                        {categories?.map((category: any) => {
                          return (
                            <Option key={category.id} value={category.id}>
                              {category.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      name={"part"}
                      label={"Наименование детали"}
                      rules={[rules.required("Обязательное поле")]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={"Выберите наименование детали"}
                        onChange={handleChangeDetail}
                      >
                        {details?.map((item) => {
                          return (
                            <Option
                              key={item.id}
                              value={item.id}
                              data-value={JSON.stringify(item)}
                            >
                              {item.title}
                            </Option>
                          );
                        })}
                      </Select>

                      {/* <SelectWithPagination
                                            onSearch={handleSearchDetails}
                                            array={details}
                                            total={totalDetails}
                                            handleChange={handleChangeDetail}
                                            handleChangePage={handleChangePageDetails}
                                        /> */}
                    </Form.Item>
                  </Col>
                  {isEngine && (
                    <Col lg={24} md={24} xs={24}>
                      <Form.Item name={"engine"} label={"Модель двигателя"}>
                        <Input placeholder={"Введите модель двигателя"} />
                      </Form.Item>
                    </Col>
                  )}
                  {isTransmission && (
                    <Col lg={24} md={24} xs={24}>
                      <Form.Item name={"transmission"} label={"Модель КПП"}>
                        <Input placeholder={"Введите модель КПП"} />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
            <Col lg={12} xs={24}>
              <Card>
                <Row gutter={5}>
                  <Col lg={12} xs={24}>
                    <Form.Item label={"Артикул"} name={"shop_code_number"}>
                      <Input placeholder={"Генерируется автоматически"} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item label={"OEM"} name={"oem"} style={{ margin: 0 }}>
                      <Input placeholder={"Введите OEM"} />
                    </Form.Item>
                    <div
                      style={{
                        marginBottom: 24,
                        cursor: "pointer",
                        color: "#4bd74b",
                        marginTop: 5,
                        fontSize: 13,
                      }}
                      onClick={() => setVisibleLaximoModal(true)}
                    >
                      Уточнить OEM
                    </div>
                  </Col>
                  {/* <Col lg={12} xs={24}>
                                    <Form.Item
                                        label={'Новая/Б.У'}
                                        name={'is_new'}
                                    >
                                        <Select placeholder={'Новая запчасть или Б.У.'}>
                                            <Option value={true}>Новая</Option>
                                            <Option value={false}>Б/У</Option>
                                        </Select>
                                    </Form.Item>
                                </Col> */}
                  <Col lg={12} xs={24}>
                    <Form.Item
                      initialValue={defect}
                      label={"Наличие дефекта"}
                      name={"defectiveness"}
                    >
                      <Select
                        onChange={(value) => setDefect(value)}
                        placeholder={"Уточните, есть ли дефекты"}
                      >
                        <Option value={true}>Есть дефекты</Option>
                        <Option value={false}>Без дефектов</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label={"Описание дефекта"}
                      name={"description_def"}
                      rules={
                        defect ? [rules.required("Обязательное поле")] : []
                      }
                    >
                      <TextArea rows={1} placeholder="Описание дефекта" />
                    </Form.Item>
                  </Col>
                  {!invoice && (
                    <>
                      <Col lg={realization === "sell" ? 12 : 24} xs={24}>
                        <Form.Item name="realization_type" label="Реализация">
                          <Select
                            onChange={(value) => setRealization(value)}
                            placeholder="Выберите из списка"
                          >
                            <Option value={"sell"}>Продажа</Option>
                            <Option value={"cancellation"}>Списание</Option>
                            <Option value={"disposal"}>Утиль</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {realization === "sell" && (
                        <Col lg={12} xs={24}>
                          <Form.Item
                            name="sells_type"
                            label="Продажа"
                            rules={[
                              { required: true, message: "Обязательное поле!" },
                            ]}
                          >
                            <Select>
                              <Option value="trade-12">ТОРГ-12</Option>
                              <Option value="UPD">УПД</Option>
                              <Option value="pr_check">Товарный чек</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )}

                  <Col lg={12} xs={24}>
                    <Form.Item
                      label={"Цена закуп(руб.)"}
                      name={"price_in"}
                      initialValue={0}
                    >
                      <InputNumber
                        type={"number"}
                        placeholder={"Введите стоимость"}
                        min={0}
                        addonAfter={<>₽</>}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label={"Цена продажи(руб.)"}
                      name={"price_out"}
                      initialValue={0}
                    >
                      <InputNumber
                        type={"number"}
                        placeholder={"Введите стоимость"}
                        min={0}
                        addonAfter={<>₽</>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Divider>Описание и фотографии</Divider>
            <Col lg={24} xs={24}>
              <Row gutter={5}>
                <Col lg={24} xs={24}>
                  <Form.Item label={"Описание"} name={"description"}>
                    <TextArea rows={2} placeholder="Краткое описание детали" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col lg={24} xs={24}>
              <Form.Item name={"photos"} label={"Фотографии"}>
                <PhotoUpload
                  handleChangeFileList={handleChangeFileList}
                  detailPhotos={detail?.photos || []}
                  detail={detail}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"end"}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {detail ? "Редактировать" : "Добавить"}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>

      {/* search oem by laximo */}
      <Modal
        title={"Найти OEM номер по каталогу"}
        width={1000}
        visible={visibleLaximoModal}
        onCancel={() => {
          setVisibleLaximoModal(false);
        }}
        footer={[]}
      >
        <LaximoActionPage
          invoice={invoice}
          detail={detail}
          handleCloseModal={() => setVisibleLaximoModal(false)}
        />
      </Modal>
    </>
  );
};

export default DetailModal;
