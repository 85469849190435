import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../hooks/useTypedSelector";
import {CompaniesActionCreators} from "../store/reducers/companies/action-creators";
import {Layout, message, Row, Tooltip, Image, Tag, Table} from "antd";
import {apiUrl} from "../variables/appVariables";
import {getTableDimensions} from "../utils/getTableWidth";
import SelectWithPagination from "../components/SelectWithPagination";

export const currDateLocalString = (date: string) => {
    const d = new Date(date)
    const months = 'января, февраля, марта, апреля, мая, июня, июля, августа, сентября, октрября, ноября, декабря'.split(',')
    const currDate = d.getDate()
    const currMonth = months[d.getMonth()]
    const currYear = d.getFullYear()
    const currTime = `${d.getHours()}:${d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()}`

    return `${currDate} ${currMonth} ${currYear} ${currTime}`
}

const Disputes = () => {
    const dispatch = useDispatch();
    const {companies, total, page} = useTypedSelector(state => state.companies);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const [loadingDisputes, setLoadingDisputes] = useState(false);

    const [disputes, setDisputes] = useState([]);

    useEffect(() => {
        if (companies.length === 0) {
            dispatch(CompaniesActionCreators.getCompanies(1));
        }
    }, [])

    useEffect(() => {
        if (selectedCompany) {
            getDisputes(selectedCompany)
        } else {
            setDisputes([])
        }
    }, [selectedCompany])

    const getDisputes = async (companyID: string | number) => {
        setLoadingDisputes(true)
        try {
            const url = `${apiUrl}/accounting_rest/disputs/?company=${companyID}`
            const res = await fetch(url, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
            const json = await res.json()
            setDisputes(json)
            setLoadingDisputes(false)
        } catch (e) {
            setLoadingDisputes(false)
            message.error('Ошибка загрузки спорных заказов')
        }
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
        },
        {
            title: 'Статус',
            dataIndex: 'is_active',
            key: 'is_active',
            width: 150,
            render: (active: boolean) => active ? <Tag color={'green'}>Активный</Tag> :
                <Tag color={'volcano'}>Закрыт</Tag>
        },
        {
            title: 'Дата спора',
            dataIndex: 'date',
            key: 'date',
            width: 200,
            render: (date: string) => currDateLocalString(date)
        },
        {
            title: 'ID Покупателя',
            dataIndex: 'customer_id',
            key: 'customer_id',
            width: 150,
        },
        {
            title: 'ID зазказа',
            dataIndex: 'order_id',
            key: 'order_id',
            width: 150,
        },
        {
            title: 'ID детали',
            dataIndex: 'detail_id',
            key: 'detail_id',
            width: 150,
        },

        {
            title: 'Причина спора',
            dataIndex: 'description',
            key: 'description',
            ellipsis: {
                showTitle: false
            },
            width: 200,
            render: (text: string) => text ? <Tooltip placement="topLeft" title={text}>{text}</Tooltip> : '-'

        },

        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            ellipsis: {
                showTitle: false
            },
            width: 150,
            render: (photo: string[]) => photo.length > 0 ?
                <Image width={100} height={70} src={`${apiUrl}/uploads/${photo[0]}`}/> : '-'
        }
    ];


    return (
        <Layout>
            <Row>
                <SelectWithPagination
                    array={companies}
                    total={total}
                    handleChange={value => {
                        setSelectedCompany(value);
                    }}
                    pageSize={5}
                    style={{width: 250}}
                    pageC={page}
                    handleChangePage={(page) =>  dispatch(CompaniesActionCreators.getCompanies(page))}
                />

                <Table
                    scroll={{x: getTableDimensions(columns), y: 500}}
                    columns={columns}
                    dataSource={disputes}
                    loading={loadingDisputes}
                />
            </Row>
        </Layout>
    );
};

export default Disputes;
