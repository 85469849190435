import {AppDispatch} from "../../index";
import {Companies} from "../../../api/companies";
import {
    CompaniesActionTypeEnum,
    ISetCompaniesAction,
    ISetCompaniesLoadingAction,
    ISetCompaniesOptions,
    ISetCompaniesPageAction,
    ISetCompaniesTotalAction,
    ISetSelectedCompanyAction,
    ISetTransportCompanies,
} from "./types";

export const CompaniesActionCreators = {
    setTotal: (number: number): ISetCompaniesTotalAction => ({
        type: CompaniesActionTypeEnum.SET_TOTAL,
        payload: number
    }),
    setCompanies: (companies: any[]): ISetCompaniesAction => ({
        type: CompaniesActionTypeEnum.SET_COMPANIES,
        payload: companies
    }),
    setSelectedCompany: (company): ISetSelectedCompanyAction => ({
        type: CompaniesActionTypeEnum.SET_SELECTED_COMPANY, 
        payload: company
    }),
    setLoading: (loading: boolean): ISetCompaniesLoadingAction => ({
        type: CompaniesActionTypeEnum.SET_LOADING,
        payload: loading
    }),
    setTransportCompanies: (companies: any[]): ISetTransportCompanies => ({
        type: CompaniesActionTypeEnum.SET_TRANSPORT_COMPANIES,
        payload: companies
    }),
    setPage: (page: number): ISetCompaniesPageAction => ({type: CompaniesActionTypeEnum.SET_PAGE, payload: page}),
    setOptions: (options: any): ISetCompaniesOptions => ({type: CompaniesActionTypeEnum.SET_OPTIONS, payload: options}),
    getCompanies: (page: number, search?: string) => async (dispatch: AppDispatch) => {
        dispatch(CompaniesActionCreators.setLoading(true))
        Companies.getCompanies(page, search)
            .then(res => {
                if (!res.detail) {
                    dispatch(CompaniesActionCreators.setPage(page))
                    dispatch(CompaniesActionCreators.setTotal(res.count))
                    dispatch(CompaniesActionCreators.setCompanies(res.results))
                }
                dispatch(CompaniesActionCreators.setLoading(false))

            })
            .catch(e => {
                dispatch(CompaniesActionCreators.setLoading(false))
            })
    },
    getOptions: () => async (dispatch: AppDispatch) => {
        Companies.getCompaniesUploadType()
            .then(res => dispatch(CompaniesActionCreators.setOptions(res)))
            .catch(e => console.log('get company options err'))
    },
    getTransportCompanies: () => async (dispatch: AppDispatch) => {
        Companies.getTransportCompanies()
            .then(res => dispatch(CompaniesActionCreators.setTransportCompanies(res.results)))
            .catch(e => console.log(`get transport companies err ${e}`))
    }
}