import auth from "./auth";
import registration from "./registration";
import tabs from "./tabs";
import companies from "./companies";
import invoices from "./invoices";
import details from "./details";
import chat from "./chat";
import laximo from "./laximo";

export default {
    auth,
    registration,
    tabs,
    companies,
    invoices,
    details,
    chat,
    laximo,
}