import {ISetActionActiveKey, ISetActionTab, ITab, TabActionEnum} from "./types";
import {AppDispatch} from "../../index";

export const TabActionCreators = {
    setTabs: (tabs: ITab[]): ISetActionTab => ({type: TabActionEnum.SET_TABS, payload: tabs}),
    setActiveKey: (key: string): ISetActionActiveKey => ({type: TabActionEnum.SET_ACTIVE_KEY, payload: key}),
    remove: (tabs: ITab[], targetKey: string, activeKey: string) => (dispatch: AppDispatch) => {
        let lastIndex = 0;

        tabs.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });

        const newTabs = tabs.filter(tab => tab.key !== targetKey);
        if (newTabs.length && activeKey === targetKey) {
            if (lastIndex >= 0) {
                activeKey = newTabs[lastIndex].key;
            } else {
                activeKey = newTabs[0].key;
            }
        }
        dispatch(TabActionCreators.setTabs(newTabs))
        dispatch(TabActionCreators.setActiveKey(activeKey))
    }
}