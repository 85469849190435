import {Modal, Form, Row, Col, Select, Input, Button, message} from "antd"
import {FC, useEffect, useState} from "react";
import moment from "moment";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {CompaniesActionCreators} from "../../store/reducers/companies/action-creators";
import {apiUrl} from "../../variables/appVariables";

const {Option} = Select;

interface ComponentProps {
    open: boolean,
    onClose: () => void,
    options: any,
    data?: any,
}

const DocModal: FC<ComponentProps> = ({open, onClose, options, data}) => {
    const dispatch = useDispatch();
    const {companies} = useTypedSelector(state => state.companies);

    const [form] = Form.useForm();

    const [other, setOther] = useState(false);
    const [isSale, setIsSale] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false);

    useEffect(() => {
        if (companies.length === 0) {
            dispatch(CompaniesActionCreators.getCompanies(1));
        }
    }, []);

    useEffect(() => {
        if (data) {
            data.sales_other && setOther(true);
            data.doc_type === 'sale' && setIsSale(true);
            form.setFieldsValue({...data})
        } else {
            setOther(false);
            setIsSale(false);
            form.resetFields();
        }
    }, [data]);

    const docAction = async (body: any, method: 'POST' | 'PATCH', id?: number) => {
        setLoadingAction(true);
        try {
            const url = method === 'PATCH' && id ? `${apiUrl}/sp/document/${id}/` : `${apiUrl}/sp/document/`;
            const response = await fetch(url, {
                method,
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
            if (response.ok) {
                message.success('Операция прошла успешно!');
                onClose?.();
            } else {
                throw new Error('Ошибка запроса на сервер');
            }
        } catch (e) {
            message.error(e.message);
        } finally {
            setLoadingAction(false);
        }
    }

    const submit = (values: any) => {
        if (data?.id) {
            docAction(values, 'PATCH', data.id)
        } else {
            docAction(values, 'POST');
        }

    }

    return (
        <Modal
            title={data ? 'Отредактировать документ' : 'Добавить документ'}
            visible={open}
            onCancel={onClose}
            footer={[]}
        >
            <Form form={form} layout='vertical' onFinish={submit}>
                <Row gutter={16}>
                    <Col lg={12} md={24} xs={24}>
                        <Form.Item
                            label={'Операция'}
                            name={'doc_type'}
                            rules={[{
                                required: true,
                                message: 'Обязательное поле',
                            }]}
                        >
                            <Select placeholder='Выберите из списка'
                                    onChange={value => value === 'sale' ? setIsSale(true) : setIsSale(false)}>
                                {options?.doc_type?.choices && options.doc_type.choices.map(option => {
                                    return (
                                        <Option key={option.value} value={option.value}>{option.display_name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    {
                        isSale && (
                            <Col lg={12} md={24} xs={24}>
                                <Form.Item
                                    label={'Продажа через'}
                                    name={'sales_method'}
                                    rules={[{
                                        required: true,
                                        message: 'Обязательное поле',
                                    }]}
                                >
                                    <Select placeholder='Выберите из списка'
                                            onChange={value => value === 'other' ? setOther(true) : setOther(false)}>
                                        {options?.sales_method?.choices && options.sales_method.choices.filter(item => item.value !== 'partobo').map(option => {
                                            return (
                                                <Option key={option.value}
                                                        value={option.value}>{option.display_name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )
                    }
                    {
                        other && (
                            <Col lg={12} md={24} xs={24}>
                                <Form.Item
                                    label={'Продажа через (иное)'}
                                    name={'sales_other'}
                                    rules={[{
                                        required: true,
                                        message: 'Обязательное поле',
                                    }]}
                                >
                                    <Input placeholder="Введите место продажи"/>
                                </Form.Item>
                            </Col>
                        )
                    }
                    <Col lg={12} md={24} xs={24}>
                        <Form.Item
                            label={'Номер документа'}
                            name={'document_number'}
                            rules={[{
                                required: true,
                                message: 'Обязательное поле',
                            }]}
                        >
                            <Input placeholder="Введите номер документа"/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={24} xs={24}>
                        <Form.Item
                            label={'Дата документа'}
                            name={'document_date'}
                        >
                            <input
                                className={'ant-input'}
                                type={'date'}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={24} xs={24}>
                        <Form.Item
                            label={'Компания'}
                            name={'company'}
                            rules={[{
                                required: true,
                                message: 'Обязательное поле',
                            }]}
                        >
                            <Select
                                placeholder='Выберите из списка или начните поиск'
                                showSearch
                                filterOption={false}
                                onSearch={value => value.length > 0 ? dispatch(CompaniesActionCreators.getCompanies(1, value)) : dispatch(CompaniesActionCreators.getCompanies(1))}
                            >
                                {companies.map(company => {
                                    return (
                                        <Option key={company.id} value={company.id}>{company.title}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'end'}>
                    {data?.id && !data.transacted && (
                        <Form.Item style={{marginRight: 10}}>
                            <Button type={'ghost'} loading={loadingAction}
                                    onClick={() => form.validateFields().then(values => docAction({
                                        ...values,
                                        transacted: true
                                    }, 'PATCH', data.id))}>
                                Провести документ
                            </Button>
                        </Form.Item>
                    )}
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingAction}
                        >
                            {data ? 'Сохранить изменения' : 'Добавить'}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    )
}

export default DocModal;
