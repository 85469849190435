import { message, Table, Tag, Tooltip } from "antd"
import { FC, useEffect, useState } from "react";
import { currDateLocalString } from "../../pages/Disputes";
import { getTableDimensions } from "../../utils/getTableWidth";
import { apiUrl } from "../../variables/appVariables";
import { getRealizationType, getSellsType } from "../DetailsTable";

interface ComponentProps {
    loading: boolean,
    details: any[],
    handleUpdateDocCatalog: () => void,
}

const DetailsInDocTable: FC<ComponentProps> = ({ loading, details, handleUpdateDocCatalog }) => {

    const removeDetailInDoc = async (id) => {
        try {
            const url = `${apiUrl}/sp/item_in_document/${id}/`
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            })
            if (response.ok) {
                handleUpdateDocCatalog?.();
                message.success('Деталь успешно удалена из документа');
            } else {
                const json = await response.json();
                throw new Error(json.item.join(', '));
            }
        } catch (e) {
            message.error(e.message);
        }
    }

    const columns = [
        {
            title: "Статус",
            dataIndex: "sells",
            key: "sells",
            width: 100,
            render: (tags: boolean) => (
                <>
                    {tags ? (
                        <Tag color={"volcano"}>Продано</Tag>
                    ) : (
                        <Tag color={"green"}>В наличии</Tag>
                    )}
                </>
            ),
        },
        {
            title: "Артикул",
            dataIndex: "shop_code_number",
            key: "shop_code_number",
            width: 150,
        },
        {
            title: "OEM",
            dataIndex: "oem",
            key: "oem",
            ellipsis: {
                showTitle: false,
            },
            width: 150,
            render: (text: any) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: "Категория",
            dataIndex: "part",
            key: "category",
            ellipsis: {
                showTitle: false,
            },
            width: 150,
            render: (part: any) =>
                part?.category ? (
                    <Tooltip placement="topLeft" title={part.category}>
                        {part.category}
                    </Tooltip>
                ) : null,
        },
        {
            title: "Наименование",
            dataIndex: "part",
            key: "title",
            ellipsis: {
                showTitle: false,
            },
            width: 200,
            render: (part: any) =>
                part?.title ? (
                    <Tooltip placement="topLeft" title={part.title}>
                        {part.title}
                    </Tooltip>
                ) : null,
        },
        {
            title: "Марка",
            dataIndex: "brand",
            key: "brand",
            ellipsis: {
                showTitle: false,
            },
            width: 150,
            render: (text: any) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: "Модель",
            dataIndex: "model",
            key: "model",
            ellipsis: {
                showTitle: false,
            },
            width: 200,
            render: (text: any) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "price",
            width: 150,
        },
        {
            title: "Цена закуп",
            dataIndex: "price_in",
            key: "price_in",
            width: 150,
        },
        {
            title: "Цена продажи",
            dataIndex: "price_out",
            key: "price_out",
            width: 150,
        },
        {
            title: "Реализация",
            dataIndex: "realization_type",
            key: "realization_type",
            width: 200,
            render: (realization_type) => getRealizationType(realization_type),
        },
        {
            title: "Продажа",
            dataIndex: "sells_type",
            key: "sells_type",
            width: 200,
            render: (sells_type) => getSellsType(sells_type),
        },
        {
            title: "Изменение реализации",
            dataIndex: "date_change_rt",
            key: "date_change_rt",
            width: 200,
            render: (date: string) => currDateLocalString(date),
        },
    ]

    return (
        <Table
            onRow={(record, rowIndex) => {
                return {
                    onDoubleClick: (event) => {
                        removeDetailInDoc(record.detail_doc_id)
                    },
                };
            }}
            scroll={{ x: getTableDimensions(columns), y: 500 }}
            loading={loading}
            dataSource={details}
            columns={columns}
            pagination={false}
        />
    )
}

export default DetailsInDocTable;