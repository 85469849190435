import { message } from "antd";
import { useDispatch } from "react-redux"
import { Laximo } from "../../../api/laximo";
import { LaximoActionCreators } from "../../../store/reducers/laximo/action-creators";

const CarsCatalogItem = ({car}) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(LaximoActionCreators.setLoadingAutoParams(true));
        dispatch(LaximoActionCreators.setSelectedCarsCatalog(car));
        Laximo.getAutoParams({code: car.code, ssd: ''})
            .then(res => dispatch(LaximoActionCreators.setAutoParams(res)))
            .catch(e => message.error(e))
            .finally(() => dispatch(LaximoActionCreators.setLoadingAutoParams(false)));
    }
    return(
        <div onClick={handleClick} className="cars-catalog__item">{car.name}</div>
    )
}

export default CarsCatalogItem;