import React from 'react';
import {Layout, Spin} from "antd";

const AppLoading = () => {
    return (
        <Layout.Content style={{height: '100vh'}}>
            <div className="app-loading">
                <Spin size={'large'} />
            </div>
        </Layout.Content>
    );
};

export default AppLoading;