import React from 'react';
import {useTypedSelector} from "../hooks/useTypedSelector";
import {Tabs} from "antd";
import {useDispatch} from "react-redux";
import {TabActionCreators} from "../store/reducers/tabs/action-creators";

const {TabPane} = Tabs;

const TabsComponent = () => {
    const dispatch = useDispatch();
    const {tabs, activeKey} = useTypedSelector(state => state.tabs);

    const onChange = (key: string) => {
        dispatch(TabActionCreators.setActiveKey(key));
    }

    const onEdit = (targetKey: any, action: any) => {
        if (action === 'remove') {
            remove(targetKey)
        }
    }

    const remove = (targetKey: string) => {
        dispatch(TabActionCreators.remove(tabs, targetKey, activeKey))
    }

    return (
        <Tabs
            hideAdd
            type="editable-card"
            activeKey={activeKey}
            onChange={onChange}
            onEdit={onEdit}
        >
            {tabs.map(pane => (
                <TabPane tab={pane.title} key={pane.key}>
                    {pane.content}
                </TabPane>
            ))}
        </Tabs>
    );
};

export default TabsComponent;