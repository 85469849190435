import { Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { LaximoActionCreators } from "../../../store/reducers/laximo/action-creators";

const DetailsItem = ({ item, onHover, onMouseLeave, handleCloseModal }) => {
    const dispatch = useDispatch();
    const { selectedDetail, selectedFoundedCar } = useTypedSelector(state => state.laximo);

    const handleClick = () => {
        onMouseLeave();
        dispatch(LaximoActionCreators.setSelectedDetail(item));
        onHover(item);
        handleCloseModal();
    }

    const handleMouseEnter = () => {
        if (selectedDetail) {
            return;
        } else {
            onHover(item)
        }
    }

    const handleMouseLeave = () => {
        if (selectedDetail) {
            return;
        } else {
            onMouseLeave()
        }
    }

    const confirm = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        handleClick();
      };
      
      const cancel = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(LaximoActionCreators.setSelectedDetail(null));
        onMouseLeave();
      };

    return (
        <Popconfirm
            title={`Принять оем-номер ${item?.oem}(${item?.name}) автомобиля ${selectedFoundedCar?.brand} ${selectedFoundedCar?.name}?`}
            onConfirm={confirm}
            onCancel={cancel}
            okText="Да"
            cancelText="Нет"
        >
            <div
                className={`details-list__item${selectedDetail?.ssd === item?.ssd ? ' active' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                    dispatch(LaximoActionCreators.setSelectedDetail(item));
                }}
            >
                {item.code_on_image} - {item.name}

            </div>
        </Popconfirm>
    );
}

export default DetailsItem;