
export interface ICompaniesState {
    companies: any[],
    selectedCompany: any,
    loading: boolean,
    total: number,
    page: number,
    options: any,
    transport_companies: any[],
}

export enum CompaniesActionTypeEnum {
    SET_COMPANIES = "SET_COMPANIES",
    SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY",
    SET_LOADING = "SET_LOADING_COMPANIES",
    SET_TOTAL = "SET_TOTAL_COMPANIES",
    SET_PAGE = "SET_PAGE_COMPANIES",
    SET_OPTIONS = "SET_OPTIONS_COMPANIES",
    SET_TRANSPORT_COMPANIES = "SET_TRANSPORT_COMPANIES",
}

export interface ISetCompaniesAction {
    type: CompaniesActionTypeEnum.SET_COMPANIES,
    payload: any[]
}

export interface ISetSelectedCompanyAction {
    type: CompaniesActionTypeEnum.SET_SELECTED_COMPANY,
    payload: any,
}

export interface ISetCompaniesLoadingAction {
    type: CompaniesActionTypeEnum.SET_LOADING,
    payload: boolean
}

export interface ISetCompaniesTotalAction {
    type: CompaniesActionTypeEnum.SET_TOTAL,
    payload: number
}

export interface ISetCompaniesPageAction {
    type: CompaniesActionTypeEnum.SET_PAGE,
    payload: number
}

export interface ISetCompaniesOptions {
    type: CompaniesActionTypeEnum.SET_OPTIONS,
    payload: any
}

export interface ISetTransportCompanies {
    type: CompaniesActionTypeEnum.SET_TRANSPORT_COMPANIES,
    payload: any[]
}

export type CompaniesActionType =
    ISetCompaniesAction |
    ISetSelectedCompanyAction |
    ISetCompaniesLoadingAction |
    ISetCompaniesPageAction |
    ISetCompaniesTotalAction |
    ISetCompaniesOptions |
    ISetTransportCompanies