import { Button, Input, message, Modal, Spin, Tabs } from "antd";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Laximo } from "../../api/laximo";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { LaximoActionCreators } from "../../store/reducers/laximo/action-creators";
// import AddDetailForm from "./AddDetailForm";
import DetailsPage from "./Details/DetailsPage";
import FoundedCars from "./FoundedCars/FoundedCars";
import NoVinSearch from "./NoVinSearch/NoVinSearch";
import SelectedItems from "./SelectedItems";
import Tree from "./Tree/Tree";
import Units from "./Units/Units";

interface ComponentProps {
    invoice?: any,
    detail?: any,
    handleCloseModal?: () => void,
}

const LaximoActionPage: FC<ComponentProps> = ({ handleCloseModal }) => {
    const dispatch = useDispatch();
    const {
        loadingFoundedCars,
        selectedFoundedCar,
        activeTab,
        categories,
        loadingCategories,
        loadingTab,
        loadingUnits,
        selectedCategory,
        selectedUnitInfo,
        loadingDetails,
    } = useTypedSelector(state => state.laximo);
    const [vin, setVin] = useState('');
    const [visibleNoVinSearchModal, setVisibleNoVinSearchModal] = useState(false);

    const handleSearchCarByVin = vinValue => {
        if (vinValue.length > 0) {
            dispatch(LaximoActionCreators.setSelectedFoundedCar(null));
            dispatch(LaximoActionCreators.setSelectedCategory(null));
            dispatch(LaximoActionCreators.setSelectedDetail(null));
            dispatch(LaximoActionCreators.setSelectedUnitInfo(null));
            dispatch(LaximoActionCreators.setLoadingTabs(true));
            dispatch(LaximoActionCreators.setActiveTab('1'));
            dispatch(LaximoActionCreators.setTabs([vinValue]));
            dispatch(LaximoActionCreators.setLoadingFoundedCars(true));
            Laximo.getCarByVin(vinValue)
                .then(res => dispatch(LaximoActionCreators.setFoundedCars(res)))
                .catch(e => message.error(e))
                .finally(() => {
                    dispatch(LaximoActionCreators.setLoadingTabs(false));
                    dispatch(LaximoActionCreators.setLoadingFoundedCars(false));
                });
        } else {
            return;
        }
    }

    return (
        <div>
            <label htmlFor="vinCode" style={{ marginBottom: 5 }}>* введите 17-ти знаный код:</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                    id="vinCode"
                    allowClear
                    style={{ maxWidth: 300, marginRight: 10 }}
                    placeholder="Введите VIN код"
                    onChange={e => setVin(e.target.value)} />
                <Button
                    loading={loadingFoundedCars}
                    disabled={vin.length < 17 || vin.length > 17}
                    type="primary"
                    onClick={() => handleSearchCarByVin(vin)}>
                    Найти по VIN
                </Button>
            </div>
            <div
                style={{ cursor: 'pointer', color: '#4bd74b', marginTop: 5, fontSize: 13 }}
                onClick={() => setVisibleNoVinSearchModal(true)}
            >
                Не знаю VIN
            </div>
            <SelectedItems />
            <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onChange={key => dispatch(LaximoActionCreators.setActiveTab(key))}>
                <Tabs.TabPane tab="Список машин" key="1">
                    <FoundedCars />
                    {/* show response */}
                    {/* <div style={{ marginTop: 10 }}>
                        <b>Response:</b> <br></br>
                        <pre>
                            {foundedCars && JSON.stringify(foundedCars, undefined, 2)}
                        </pre>
                    </div> */}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Категории" key="2" disabled={!selectedFoundedCar}>
                    <Spin spinning={loadingCategories || loadingTab}>
                        <Tree treeData={categories} />
                    </Spin>
                    {/* show response */}
                    {/* <div style={{ marginTop: 10 }}>
                        <b>Response:</b> <br></br>
                        <pre>
                            {categories && JSON.stringify(categories, undefined, 2)}
                        </pre>
                    </div> */}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Список агрегатов" key="3" disabled={!selectedCategory}>
                    <Spin spinning={loadingUnits || loadingTab}>
                        <Units />
                    </Spin>
                    {/* show response */}
                    {/* <div style={{ marginTop: 10 }}>
                        <b>Response:</b> <br></br>
                        <pre>
                            {units && JSON.stringify(units, undefined, 2)}
                        </pre>
                    </div> */}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Список деталей" key="4" disabled={!selectedUnitInfo}>
                    <Spin spinning={loadingDetails || loadingTab}>
                        <DetailsPage handleCloseModal={handleCloseModal} />
                    </Spin>
                    {/* show response */}
                    {/* <div style={{ marginTop: 10 }}>
                        <b>Response:</b> <br></br>
                        <pre>
                            {details && JSON.stringify(details, undefined, 2)}
                        </pre>
                    </div> */}
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab={detail? 'Редактирование детали' : 'Добавление детали'} key='5' disabled={!selectedDetail}>
                    <Spin spinning={loadingTab}>
                        <AddDetailForm invoice={invoice} detail={detail} />
                    </Spin>
                </Tabs.TabPane> */}
            </Tabs>



            {/* no vin cars search modal */}
            <Modal
                title={'Найти авто по каталогу'}
                width={1000}
                visible={visibleNoVinSearchModal}
                onCancel={() => {
                    setVisibleNoVinSearchModal(false);
                }}
                footer={[]}
            >
                <NoVinSearch handleClose={() => setVisibleNoVinSearchModal(false)} />
            </Modal>
        </div>
    );
}

export default LaximoActionPage;